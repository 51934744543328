<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'invite_name', label: 'Name', filtered: false },
          { name: 'invite_email', label: 'Email' },
          { name: 'invite_status', label: 'Status' },
          //   { name: 'quantity', label: 'Quantity Assigned', filtered: false },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="growers"
        @update:derived-collection="derivedGrowers = $event"
        :defaultSort="defaultSort"
        :defaultFilter="defaultFilter"
        :limit="50"
      >
        <template #toolbarButtons>
          <div class="flex">
            <table-toolbar-button
              buttonText="Create New Invite"
              :usePlusIcon="true"
              @buttonClick="inviteGrowers"
            />
          </div>
        </template>
      </table-head>
    </template>
    <tbody>
      <tr v-for="g in derivedGrowers" :key="g.id">
        <table-data td-label="Name">
          {{ g.invite_name || g?.member_details?.name }}
        </table-data>
        <table-data td-label="Email"> {{ g.invite_email }} </table-data>
        <table-data td-label="Status">
          {{ capitalize(g.invite_status) }}
        </table-data>
        <table-data v-if="g.invite_status == 'sent'" class="text-center">
          <button
            @click="deleteInvite(g)"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            Cancel Invite
          </button>
        </table-data>
        <table-data
          v-else-if="g.invite_status == 'canceled'"
          class="text-center"
        >
        </table-data>
        <table-data
          v-else-if="g.invite_status == 'accepted'"
          class="text-center"
        >
          <router-link
            :to="{
              name: 'buyer-bp-affiliate-grower-report',
              params: { growerId: g.member_details.org },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            View
          </router-link>
        </table-data>
        <table-data v-else></table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableData from "@/components/table/TableData.vue";
import { capitalize } from "@/components/composables/formatStringUtils";
import { useToast } from "vue-toastification";

export default {
  components: {
    TableToolbarButton,
    TableHead,
    TableCard,
    TableData,
  },
  data() {
    return {
      toast: useToast(),
      derivedGrowers: [],
      defaultFilter: {},
      defaultSort: { status: "desc" },
      showInviteModal: false,
      inviteEmail: "",
    };
  },
  computed: {
    growers() {
      return this.$store.state.invites.invites;
    },
  },
  methods: {
    capitalize,
    deleteInvite(invite) {
      this.$store.dispatch("deleteInvite", invite);
      this.toast.success("Invite deleted");
    },
    inviteGrowers() {
      this.$router.push({ name: "buyer-bp-affiliate-growers-invite-new" });
    },
    async impersonateGrower(orgId, page) {
      await this.$store.dispatch("loginSwitchContext", { shared_org: orgId });
      if (page) {
        this.$router.push({ name: page });
      }
    },
  },
};
</script>

<style></style>
