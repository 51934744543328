<template>
  <form
    @submit.prevent="submitNewGrowerForm"
    :id="formId"
    class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
  >
    <text-input
      :wideItem="true"
      inputTitle="Grower Org Name"
      inputHtmlType="text"
      :required="true"
      v-model="currentGrowerOrg.name"
    />
    <div class="sm:col-span-3">&nbsp;</div>
    <text-input
      :wideItem="true"
      inputTitle="Address"
      :required="false"
      inputHtmlType="text"
      v-model="addressObj.street"
    />
    <text-input
      :wideItem="false"
      inputTitle="City"
      :required="false"
      inputHtmlType="text"
      v-model="addressObj.city"
    />
    <text-input
      :wideItem="false"
      inputTitle="State"
      :required="false"
      inputHtmlType="text"
      v-model="addressObj.state"
    />
    <text-input
      :wideItem="true"
      inputTitle="Email"
      :required="true"
      inputHtmlType="email"
      v-model="currentGrowerOrg.email"
    />
    <text-input
      :wideItem="true"
      inputTitle="Phone"
      inputHtmlType="text"
      v-model="currentGrowerOrg.phone"
    />
    <div class="flex sm:col-span-6">
      <checkbox-input
        inputTitle="Grower FMS"
        v-model="enableGpFms"
        class="mr-4"
      />
      <checkbox-input
        inputTitle="Grower Crop Sales"
        v-model="enableGpSell"
        class="mr-4"
      />
      <checkbox-input
        inputTitle="Grower Input Purchases"
        v-model="enableGpBuy"
      />
    </div>
  </form>
</template>

<script>
import TextInput from "@/components/form/TextInput.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";
import { useToast } from "vue-toastification";

export default {
  props: {
    formId: {
      type: String,
      required: true,
    },
  },
  components: {
    TextInput,
    CheckboxInput,
  },
  data() {
    return {
      toast: useToast(),
      currentGrowerOrg: {
        name: "",
        address: "",
        phone: null,
        email: null,
        services: [],
        feature_flags: [],
        referrer: null,
      },
      addressObj: {
        street: "",
        city: "",
        state: "",
      },
      enableGpFms: true,
      enableGpSell: true,
      enableGpBuy: false,
    };
  },
  computed: {},
  methods: {
    submitNewGrowerForm() {
      this.createNewGrower();
    },
    async createNewGrower() {
      console.log("test submit new grower org", this.currentGrowerOrg);
      // if the flag is set and the grower org does not have the flag, add it
      this.currentGrowerOrg.address = JSON.stringify(this.addressObj);
      if (
        this.enableGpFms &&
        !this.currentGrowerOrg.feature_flags.includes("gp-fms")
      ) {
        this.currentGrowerOrg.feature_flags.push("gp-fms");
      }
      if (
        this.enableGpSell &&
        !this.currentGrowerOrg.feature_flags.includes("gp-sell")
      ) {
        this.currentGrowerOrg.feature_flags.push("gp-sell");
      }
      if (
        this.enableGpBuy &&
        !this.currentGrowerOrg.feature_flags.includes("gp-buy")
      ) {
        this.currentGrowerOrg.feature_flags.push("gp-buy");
      }
      if (this.currentGrowerOrg.feature_flags.length === 0) {
        this.currentGrowerOrg.feature_flags = ["gp-sell"];
      }
      console.log("test submit new grower org", this.currentGrowerOrg);
      let newGrower = await this.$store.dispatch(
        "createGrowerOrgAsAffiliate",
        this.currentGrowerOrg,
      );
      console.log("new grower", newGrower);
      this.toast.success("Grower Org created");
      this.$router.push({
        name: "buyer-bp-affiliate-grower-report",
        params: { growerId: newGrower.id },
      });
    },
  },
  watch: {},
};
</script>
