<template>
  <delete-modal
    :open="deleteModalIsActive"
    modalTitle="Close Quote"
    modalDescription="Are you sure you want to reject this quote?"
    @clickCancel="cancelDelete"
    @clickConfirmDelete="closeOrder"
    deleteButtonText="Cancel"
    closeButtonText="Back"
  />
  <confirm-modal
    :open="confirmModalIsActive"
    modalTitle="Confirm Quote"
    modalDescription="Accepting this quote will place this order. An invoice will be sent shortly. Payment is required to secure your order for the scheduled pickup or delivery date. Are you ready to proceed?"
    @clickCancel="cancelConfirm"
    @clickConfirm="acceptOrder"
    modalAction="Place Order"
    closeButtonText="Back"
  />
  <page-card headingTitle="Review Quote">
    <template #buttonArea>
      <base-button
        buttonText="Cancel Order"
        :redButton="true"
        :marginLeft="false"
        @buttonClick="intializeDeleteModal"
        v-if="currentOrder.status !== 'seller_responded'"
        :disabled="!isFirstParty"
      />
      <base-button
        buttonText="Reject"
        @buttonClick="rejectOrder"
        :redButton="true"
        :marginLeft="true"
        v-if="currentOrder.status === 'seller_responded'"
        :disabled="!isFirstParty"
      />
      <base-button
        buttonText="Accept Quote"
        @buttonClick="intializeConfirmModal"
        :marginLeft="true"
        v-if="currentOrder.status === 'seller_responded'"
        :disabled="!isFirstParty"
      />
      <div v-if="!isFirstParty" class="w-full text-center text-xs text-red-500">
        You must be a member of this account to update a quote.
      </div>
    </template>
    <template #cardDetails>
      <div
        v-if="!currentAgreement || !currentOrder"
        class="text-red-500 sm:col-span-6"
      >
        Loading...
      </div>
      <div class="sm:col-span-3">
        <label class="block text-sm font-medium text-gray-700"> Vendor </label>
        <variable-string-type-input
          :displayOnly="true"
          :displayOnlyValue="currentAgreement?.seller_details.name"
          :wideItem="true"
        />
      </div>
      <variable-string-type-input
        :displayOnly="true"
        :displayOnlyValue="new Date(currentOrder.updated).toLocaleDateString()"
        :wideItem="true"
        inputTitle="Updated"
      />
      <div
        v-if="currentOrder.shipping_company !== 'Pick Up'"
        class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
      >
        <div class="sm:col-span-3">
          <label class="block text-sm font-medium text-gray-700">
            Recipient Name
          </label>
          <variable-string-type-input
            :displayOnly="true"
            :displayOnlyValue="currentOrder.shipping_name"
            :wideItem="true"
          />
        </div>
        <div class="sm:col-span-3">
          <label class="block text-sm font-medium text-gray-700">
            Recipient Company
          </label>
          <variable-string-type-input
            :displayOnly="true"
            :displayOnlyValue="currentOrder.shipping_company"
            :wideItem="true"
          />
        </div>
        <div class="sm:col-span-3">
          <label class="block text-sm font-medium text-gray-700">
            Shipping Address
          </label>
          <variable-string-type-input
            :displayOnly="true"
            :displayOnlyValue="currentOrder.shipping_address_1"
            :wideItem="true"
          />
        </div>
        <div class="sm:col-span-3">
          <label class="block text-sm font-medium text-gray-700">
            Shipping Address Line 2
          </label>
          <variable-string-type-input
            :displayOnly="true"
            :displayOnlyValue="currentOrder.shipping_address_2"
            :wideItem="true"
          />
        </div>
        <div class="sm:col-span-3">
          <label class="block text-sm font-medium text-gray-700"> City </label>
          <variable-string-type-input
            :displayOnly="true"
            :displayOnlyValue="currentOrder.shipping_city"
            :wideItem="true"
          />
        </div>
        <div class="sm:col-span-3">
          <label class="block text-sm font-medium text-gray-700"> State </label>
          <variable-string-type-input
            :displayOnly="true"
            :displayOnlyValue="currentOrder.shipping_state"
            :wideItem="true"
          />
        </div>
        <div class="sm:col-span-3">
          <label class="block text-sm font-medium text-gray-700">
            Zipcode
          </label>
          <variable-string-type-input
            :displayOnly="true"
            :displayOnlyValue="currentOrder.shipping_zipcode"
            :wideItem="true"
          />
        </div>
        <div class="sm:col-span-3">
          <label class="block text-sm font-medium text-gray-700">
            Shipping Memo
          </label>
          <TextArea
            :displayOnly="true"
            :displayOnlyValue="currentOrder.shipping_memo"
            :wideItem="true"
          />
        </div>
      </div>
      <div v-else class="col-span-6 text-base">
        <variable-string-type-input
          input-title="Pick Up From"
          :displayOnly="true"
          :displayOnlyValue="currentOrder.shipping_name"
          :wideItem="true"
        />
      </div>
      <div class="sm:col-span-3">
        <label class="block text-sm font-medium text-gray-700">
          Requested Delivery Date
        </label>
        <date-input
          :displayOnly="true"
          :displayOnlyValue="currentOrder.requested_delivery_date"
          :wideItem="true"
        />
      </div>
      <div class="sm:col-span-3">
        <label class="block text-sm font-medium text-gray-700">
          Expected Delivery Date
        </label>
        <date-input
          :displayOnly="true"
          :displayOnlyValue="currentOrder.expected_delivery_date"
          :wideItem="true"
        />
      </div>
      <div class="sm:col-span-3">
        <label class="block text-sm font-medium text-gray-700">
          Purchase Order #
        </label>
        <variable-string-type-input
          v-model="currentOrder.purchase_order_number"
          :wideItem="true"
        />
      </div>
      <div v-if="orderClosed" class="sm:col-span-6">
        <horizontal-panel-steps
          :steps="[{ name: 'Order Closed', status: 'complete' }]"
        />
      </div>
      <div v-else-if="orderCanceled" class="sm:col-span-6">
        <horizontal-panel-steps
          :steps="[{ name: 'Order Canceled', status: 'complete' }]"
        />
      </div>
      <div v-else-if="orderCompleted" class="sm:col-span-6">
        <horizontal-panel-steps
          :steps="[{ name: 'Order Completed', status: 'complete' }]"
        />
      </div>
      <div v-else class="sm:col-span-6">
        <horizontal-panel-steps :steps="orderSteps" />
      </div>
    </template>
    <template #componentSection>
      <TabGroup as="div">
        <div class="border-b border-gray-200">
          <TabList class="-mb-px flex space-x-8">
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Order Details
              </button>
            </Tab>
          </TabList>
        </div>
        <TabPanels as="template">
          <TabPanel class="text-sm text-gray-500">
            <template v-for="item in lineItems" :key="item.id">
              <template v-if="item.input_unit">
                <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
                  <div class="sm:col-span-3">
                    <label class="block text-sm font-medium text-gray-700">
                      Item
                    </label>
                    <variable-string-type-input
                      :displayOnly="true"
                      :displayOnlyValue="getInputUnitFullName(item.input_unit)"
                      :wideItem="true"
                    />
                  </div>
                  <div class="sm:col-span-1">
                    <label class="block text-sm font-medium text-gray-700">
                      Quantity
                    </label>
                    <NumberInput
                      :displayOnly="true"
                      :displayOnlyValue="item.quantity"
                      :wideItem="true"
                      :step="1"
                    />
                  </div>
                  <div class="sm:col-span-1">
                    <label class="block text-sm font-medium text-gray-700">
                      Unit Price
                    </label>
                    <CurrencyInput
                      :displayOnly="true"
                      :displayOnlyValue="item.unit_price"
                      :wideItem="true"
                    />
                  </div>
                  <div class="sm:col-span-1">
                    <label class="block text-sm font-medium text-gray-700">
                      Total Price
                    </label>
                    <CurrencyInput
                      :displayOnly="true"
                      :displayOnlyValue="item.quantity * item.unit_price"
                      :wideItem="true"
                    />
                  </div>
                </div>
              </template>
            </template>
            <template v-for="item in lineItems" :key="item.id">
              <template v-if="item.input_request">
                <div
                  class="mt-6 grid grid-cols-1 gap-x-4 gap-y-1 sm:grid-cols-6"
                >
                  <div class="sm:col-span-3">
                    <label class="block text-sm font-medium text-gray-700">
                      Item Request
                    </label>
                    <variable-string-type-input
                      :displayOnly="true"
                      :displayOnlyValue="
                        getInputRequest(item.input_request)?.product_name
                      "
                      :wideItem="true"
                    />
                  </div>
                  <div class="sm:col-span-1">
                    <label class="block text-sm font-medium text-gray-700">
                      Quantity
                    </label>
                    <NumberInput
                      :displayOnly="true"
                      :displayOnlyValue="item.quantity"
                      :wideItem="true"
                      :step="1"
                    />
                  </div>
                  <div class="sm:col-span-1">
                    <label class="block text-sm font-medium text-gray-700">
                      Unit Price
                    </label>
                    <CurrencyInput
                      :displayOnly="true"
                      :displayOnlyValue="item.unit_price"
                      :wideItem="true"
                    />
                  </div>
                  <div class="sm:col-span-1">
                    <label class="block text-sm font-medium text-gray-700">
                      Total Price
                    </label>
                    <CurrencyInput
                      :displayOnly="true"
                      :displayOnlyValue="item.quantity * item.unit_price"
                      :wideItem="true"
                    />
                  </div>
                  <div class="sm:col-span-3">
                    <label class="block text-sm font-medium text-gray-700">
                      Requested Package Size
                    </label>
                    <variable-string-type-input
                      :displayOnly="true"
                      :displayOnlyValue="item.package_request"
                      :wideItem="true"
                    />
                  </div>
                  <div class="sm:col-span-1">
                    <label class="block text-sm font-medium text-gray-700">
                      Available?
                    </label>
                    <radio-input
                      :wideItem="true"
                      :display-only="true"
                      v-model="item.is_available"
                      :selectOptions="[
                        { label: 'Yes', value: 'true' },
                        { label: 'No', value: 'false' },
                      ]"
                    />
                  </div>
                </div>
              </template>
            </template>
            <div
              class="mt-8 grid grid-cols-1 gap-x-4 gap-y-8 border-t border-gray-200 sm:grid-cols-6"
            >
              <div class="sm:col-span-3">
                <label class="block text-sm font-medium text-gray-700">
                  Memo
                </label>
                <TextArea
                  :display-only="true"
                  :display-only-value="currentOrder.memo"
                  :wideItem="true"
                />
              </div>
              <div class="sm:col-span-3">
                <label class="block text-sm font-medium text-gray-700">
                  Shipping
                </label>
                <CurrencyInput
                  :displayOnly="true"
                  :displayOnlyValue="currentOrder.shipping_cost"
                  :wideItem="true"
                />
              </div>
              <div class="sm:col-span-3"></div>
              <div class="sm:col-span-3">
                <label class="block text-sm font-medium text-gray-700">
                  Discount
                </label>
                <CurrencyInput
                  :displayOnly="true"
                  :displayOnlyValue="currentOrder.discount"
                  :wideItem="true"
                />
              </div>
              <div class="sm:col-span-3"></div>
              <div class="sm:col-span-3">
                <label class="block text-sm font-medium text-gray-700">
                  Total
                </label>
                <CurrencyInput
                  :displayOnly="true"
                  :displayOnlyValue="currentOrder.total_cost"
                  :wideItem="true"
                />
                <span class="text-xs text-gray-500">
                  * Does not include sales tax
                </span>
              </div>
            </div>
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import VariableStringTypeInput from "@/components/form/VariableStringTypeInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import CurrencyInput from "@/components/form/CurrencyInput.vue";
import DeleteModal from "@/components/modals/DeleteModal.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import HorizontalPanelSteps from "@/components/progress/HorizontalPanelSteps.vue";
import TextArea from "@/components/form/TextArea.vue";
import RadioInput from "@/components/form/RadioInput.vue";
import DateInput from "@/components/form/DateInput.vue";
import { Tab, TabGroup, TabList, TabPanels, TabPanel } from "@headlessui/vue";
import { useToast } from "vue-toastification";

export default {
  components: {
    PageCard,
    BaseButton,
    VariableStringTypeInput,
    DeleteModal,
    ConfirmModal,
    Tab,
    TabGroup,
    TabList,
    TabPanels,
    TabPanel,
    HorizontalPanelSteps,
    NumberInput,
    CurrencyInput,
    TextArea,
    RadioInput,
    DateInput,
  },
  data() {
    const toast = useToast();
    return {
      deleteModalIsActive: false,
      confirmModalIsActive: false,
      toast,
      currentOrder: {
        line_items: [],
        total_cost: 0,
        shipping_cost: 0,
        discount: 0,
      },
      lineItems: [],
    };
  },
  computed: {
    orderId() {
      return parseInt(this.$route.params.quoteId);
    },
    order() {
      return this.$store.getters.getGrowerInputOrderById(this.orderId);
    },
    orders() {
      const orders = this.$store.state.growerInputOrders.orders;
      return orders;
    },
    currentAgreement() {
      const result = this.inputAgreements?.find(
        (a) => a.id === this.currentOrder?.input_agreement,
      );
      return result;
    },
    orderClosed() {
      if (
        this.currentOrder.status === "seller_closed" ||
        this.currentOrder.status === "buyer_closed"
      ) {
        return true;
      } else {
        return false;
      }
    },
    orderCanceled() {
      if (this.currentOrder.status === "seller_canceled") {
        return true;
      } else {
        return false;
      }
    },
    orderCompleted() {
      if (this.currentOrder.status === "seller_completed") {
        return true;
      } else {
        return false;
      }
    },
    orderSteps() {
      let steps = [
        { name: "Order Submitted", status: "complete" },
        {
          name: "Order Completed",
          status:
            this.currentOrder.status === "seller_completed"
              ? "complete"
              : "upcoming",
        },
      ];
      return steps;
    },
    inputAgreements() {
      return this.$store.state.growerInputAgreements.agreements;
    },
    inputListings() {
      return this.$store.state.growerInputListings.listings;
    },
    inputUnits() {
      return this.$store.state.growerInputListings.units;
    },
    inputRequests() {
      return this.$store.state.growerInputListings.requests;
    },
    isFirstParty() {
      return this.$store.getters.currentOrgIsFirstParty;
    },
  },
  methods: {
    getInputAgreement(id) {
      return this.inputAgreements.find((a) => a.id == id);
    },
    getInputListing(id) {
      return this.inputListings.find((a) => a.id == id);
    },
    getInputUnit(id) {
      return this.inputUnits.find((a) => a.id == id);
    },
    getInputUnitFullName(id) {
      const unit = this.getInputUnit(id);
      if (!unit) return "";
      const listing = this.getInputListing(unit.input_listing);
      return listing?.title + " - " + unit?.title;
    },
    getInputRequest(id) {
      return this.inputRequests.find((a) => a.id == id);
    },
    async acceptOrder() {
      await this.$store.dispatch("updateGrowerInputOrder", {
        ...this.currentOrder,
        status: "buyer_ordered",
      });
      if (!this.$store.getters.getApiError) {
        this.toast.success("Order Accepted!", {
          timeout: 1500,
          hideProgressBar: true,
        });
      }
      this.$router.push({ name: "grower-portal-buy-orders-all" });
    },
    async rejectOrder() {
      await this.$store.dispatch("updateGrowerInputOrder", {
        ...this.currentOrder,
        status: "buyer_rejected",
      });
      if (!this.$store.getters.getApiError) {
        this.toast.success("Order Rejected", {
          timeout: 1500,
          hideProgressBar: true,
        });
      }
      this.$router.push({ name: "grower-portal-buy-orders-all" });
    },
    async closeOrder() {
      await this.$store.dispatch("updateGrowerInputOrder", {
        ...this.currentOrder,
        status: "buyer_closed",
      });
      if (!this.$store.getters.getApiError) {
        this.toast.success("Order Canceled", {
          timeout: 1500,
          hideProgressBar: true,
        });
      }
      this.$router.push({ name: "grower-portal-buy-orders-all" });
    },
    cancelDelete() {
      this.deleteModalIsActive = false;
    },
    intializeDeleteModal() {
      this.deleteModalIsActive = true;
    },
    cancelConfirm() {
      this.confirmModalIsActive = false;
    },
    intializeConfirmModal() {
      this.confirmModalIsActive = true;
    },
  },
  watch: {
    order(order) {
      this.currentOrder = order;
      this.lineItems = order?.line_items || [];
    },
    // orderId: {
    //   async handler(orderId) {
    //     await this.$store.dispatch("getGrowerInputOrders");
    //     this.currentOrder = this.orders.find((o) => o.id === orderId) || {};
    //     this.lineItems = this.currentOrder?.line_items || [];
    //   },
    //   immediate: true,
    // },
  },
};
</script>
