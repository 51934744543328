<template>
  <div>
    <span class="text-2xl">{{ message }}</span>
  </div>
</template>
<script>
import api from "@/api/parcel/api";

export default {
  data() {
    return {
      message: "",
    };
  },
  async mounted() {
    const { token } = this.$route.params;
    const data = await api.auth.readInvite(token);
    if (data.invite_valid) {
      this.message = "Accepting invitation...";
      if (this.$store.getters.getUserData /* loggedIn */) {
        if (this.$store.getters.getUserData.org) {
          await api.auth.respondInvite(token, { accepted: true });
          this.$router.push({ name: "landing-page" });
        } else {
          this.$router.push({
            name: "create-org-branded",
            params: { slug: data.sender_details.slug },
            query: { token },
          });
        }
      } else {
        this.$router.push({ path: `/login/invite/${token}` });
      }
    } else {
      this.message = data.error_message;
    }
  },
};
</script>
