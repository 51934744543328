<template>
  <delete-modal
    :open="modalIsActive"
    modalTitle="Remove Assignment"
    modalDescription="Are you sure you want to permanently remove this assignment?"
    @clickCancel="cancelDelete"
    @clickConfirmDelete="delistAssignment"
  />
  <page-card headingTitle="Edit Assignment">
    <template #buttonArea>
      <submit-button
        buttonText="Save"
        :marginLeft="false"
        :formId="editAssignmentFormId"
      />
      <base-button
        buttonText="Remove"
        :marginLeft="true"
        @buttonClick="modalIsActive = true"
        :redButton="true"
      />
    </template>
    <template #componentSection>
      <TabGroup as="div">
        <div class="border-b border-gray-200">
          <TabList class="-mb-px flex space-x-8">
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Summary
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Details
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Premiums
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Territory
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Attachments
              </button>
            </Tab>
          </TabList>
        </div>
        <TabPanels as="template">
          <TabPanel as="dl" class="pt-10">
            <assignment-form
              :assignmentId="assignmentId"
              :formId="editAssignmentFormId"
            />
            <p class="my-4">Inquiries</p>
            <controller-inquiries-table :offerId="assignmentId" />
            <p class="my-4">Orders</p>
            <controller-orders-table :offerId="assignmentId" />
          </TabPanel>
          <TabPanel as="dl" class="pt-10">
            <contract-form-details
              v-if="readyToReadContract"
              v-model="currentContractDetails"
              :readOnly="true"
            />
          </TabPanel>
          <TabPanel as="dl" class="pt-10">
            <contract-form-premiums
              v-if="readyToReadContract"
              v-model="currentContractPremiums"
              :cropType="currentContractDetails.crop"
              :readOnly="true"
            />
          </TabPanel>
          <TabPanel as="dl" class="pt-10">
            <contract-form-territory
              v-if="readyToReadContract"
              v-model="currentContractTerritory"
              :readOnly="true"
            />
          </TabPanel>
          <TabPanel as="dl" class="pt-10">
            <p class="mb-8">
              Attachments are editable on the contract details page.
            </p>
            <view-attachments-table
              :attachments="currentContractDetails.attachments"
            />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import AssignmentForm from "@/layouts/buyer-portal/bp-controller/assignments/AssignmentForm.vue";
import DeleteModal from "@/components/modals/DeleteModal.vue";
import ControllerOrdersTable from "@/pages/buyer-portal/bp-controller/orders/ControllerOrdersTable.vue";
import ControllerInquiriesTable from "@/pages/buyer-portal/bp-controller/orders/ControllerInquiriesTable.vue";
import { Tab, TabList, TabPanel, TabPanels, TabGroup } from "@headlessui/vue";
import ContractFormDetails from "@/layouts/common/contracts/ContractFormDetails.vue";
import ContractFormPremiums from "@/layouts/common/contracts/ContractFormPremiums.vue";
import ContractFormTerritory from "@/layouts/common/contracts/ContractFormTerritory.vue";
import ViewAttachmentsTable from "@/layouts/common/attachments/ViewAttachmentsTable.vue";

export default {
  components: {
    PageCard,
    SubmitButton,
    BaseButton,
    AssignmentForm,
    DeleteModal,
    ControllerInquiriesTable,
    ControllerOrdersTable,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    TabGroup,
    ContractFormDetails,
    ContractFormPremiums,
    ContractFormTerritory,
    ViewAttachmentsTable,
  },
  data() {
    return {
      editAssignmentFormId: "new-offer-form",
      modalIsActive: false,
      currentContractDetails: null,
      currentContractPremiums: null,
      currentContractTerritory: null,
    };
  },
  computed: {
    assignmentId() {
      return this.$route.params.assignmentId;
    },
    assignment() {
      return this.$store.getters.getControllerMarketOfferById(
        this.assignmentId,
      );
    },
    contractId() {
      return this.assignment?.buyer_contract;
    },
    readyToReadContract() {
      if (
        this.contractId &&
        this.$store.getters.getContractById(this.contractId)
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    delistAssignment() {
      this.$store.dispatch("deleteControllerMarketOffer", {
        id: this.assignmentId,
      });
      this.$router.push({ name: "buyer-assignments" });
    },
    cancelDelete() {
      this.modalIsActive = false;
    },
    readContract() {
      if (!this.readyToReadContract) {
        return null;
      }
      // set current contract details, premiums, and territory
      let currentContract = this.$store.getters.getContractById(
        this.contractId,
      );
      this.currentContractDetails = { ...currentContract };
      this.currentContractPremiums = currentContract.premiums;
      this.currentContractTerritory = currentContract.territory;
      delete this.currentContractDetails.premiums;
      delete this.currentContractDetails.territory;
    },
  },
  watch: {
    contractId: {
      // handler(value) or handler(newValue, oldValue)
      handler() {
        this.readContract();
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
    readyToReadContract: {
      handler(value) {
        if (value && this.contractId) {
          this.readContract();
        }
      },
      immediate: true,
    },
  },
};
</script>
