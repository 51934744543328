<template>
  <div class="sm:col-span-6">
    <!-- Step 1: Upload CSV -->
    <div class="flex items-center space-x-2">
      <file-select
        buttonText="Step 1: Select file (.csv)"
        @input="setFile"
        accept=".csv"
      />
      <check-circle-icon v-if="csv" class="h-8 w-8 fill-green-500" />
    </div>

    <!-- Step 2: If file uploaded, show column mapping dropdowns -->
    <div v-if="uploaded" class="my-6 flex flex-col sm:col-span-6">
      <p class="mb-4 text-sm font-bold text-twilight-700">
        Step 2: Map the columns (select which CSV columns should become "Name"
        and "Email")
      </p>
      <div class="grid grid-cols-1 gap-4 sm:grid-cols-6">
        <!-- Name Column Select -->
        <select-dropdown
          inputTitle="Name Column"
          :selectOptions="headerOptions"
          v-model="selectedNameColumn"
          :wideItem="true"
          placeholder="-- Select a Column --"
        />

        <!-- Email Column Select -->
        <select-dropdown
          inputTitle="Email Column"
          :selectOptions="headerOptions"
          v-model="selectedEmailColumn"
          :wideItem="true"
          placeholder="-- Select a Column --"
        />
      </div>
    </div>

    <!-- Step 3: Display the resulting data in a table -->
    <table-card v-if="uploaded && csvMappedData.length" class="sm:col-span-6">
      <template #header>
        <table-head
          :columns="[
            { name: 'name', label: 'Grower', filtered: false },
            { name: 'email', label: 'Email', filtered: false },
            { name: 'actions', label: '', filtered: false },
          ]"
          :collection="csvMappedData"
          :defaultSort="defaultSort"
          :defaultFilter="defaultFilter"
          :limit="50"
        >
          <template #toolbarButtons>
            <div class="flex">
              <table-toolbar-button
                buttonText="Invite Growers"
                :usePlusIcon="false"
                @buttonClick="submitInvites"
              />
            </div>
          </template>
        </table-head>
      </template>
      <tbody>
        <tr v-for="(invite, index) in csvMappedData" :key="index">
          <table-data td-label="Name">{{ invite.name }}</table-data>
          <table-data td-label="Email">{{ invite.email }}</table-data>
          <table-data class="text-center">
            <button
              @click="removeInvite(index)"
              class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
            >
              Remove Invite
            </button>
          </table-data>
        </tr>
      </tbody>
    </table-card>
  </div>
</template>

<script>
import FileSelect from "@/components/form/FileSelect.vue";
import Papa from "papaparse";
import { CheckCircleIcon } from "@heroicons/vue/solid";
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
import TableData from "@/components/table/TableData.vue";
import SelectDropdown from "@/components/form/SelectDropdown.vue";

export default {
  components: {
    FileSelect,
    CheckCircleIcon,
    TableCard,
    TableHead,
    TableToolbarButton,
    TableData,
    SelectDropdown,
  },
  emits: ["submitInvites"],
  data() {
    return {
      // CSV parsing state
      csv: null,
      uploaded: false,
      features: [],
      headerOptions: [],

      // Column mapping fields
      selectedNameColumn: "",
      selectedEmailColumn: "",

      // Final mapped data for table display
      csvMappedData: [],

      // For TableHead
      defaultSort: {},
      defaultFilter: {},
    };
  },
  watch: {
    // Whenever user changes the name or email column,
    // re-generate the mapped data array.
    selectedNameColumn() {
      this.updateMapping();
    },
    selectedEmailColumn() {
      this.updateMapping();
    },
  },
  methods: {
    setFile(file) {
      this.csv = file;
      // Reset state for new file selection
      this.uploaded = false;
      this.features = [];
      this.headerOptions = [];
      this.csvMappedData = [];
      this.selectedNameColumn = "";
      this.selectedEmailColumn = "";

      // Parse the CSV
      this.uploadCsvfile();
    },
    uploadCsvfile() {
      if (!this.csv) return;
      Papa.parse(this.csv, {
        complete: (results) => {
          const [header, ...rows] = results.data;

          // Build header options for mapping
          this.headerOptions = header.map((col) => ({
            label: col,
            value: col,
          }));

          // Convert rows to objects using header
          this.features = rows.map((row) => {
            const obj = {};
            header.forEach((col, index) => {
              obj[col] = row[index];
            });
            return obj;
          });
          this.uploaded = true;
        },
        error: (error) => {
          console.error("CSV Parsing Error:", error);
        },
      });
    },
    updateMapping() {
      // Only map if both columns are chosen
      if (!this.selectedNameColumn || !this.selectedEmailColumn) {
        this.csvMappedData = [];
        return;
      }
      // Generate array of objects: { name, email }
      const finalData = this.features.map((feature) => ({
        name: feature[this.selectedNameColumn],
        email: feature[this.selectedEmailColumn],
      }));
      this.csvMappedData = finalData;
      console.log("Mapped CSV Data:", finalData);
    },
    removeInvite(index) {
      this.csvMappedData.splice(index, 1);
      // If you want to notify a parent component, you could emit again here.
      // this.$emit("submitInvites", this.csvMappedData);
    },
    submitInvites() {
      // This can trigger any further processing or API calls with the mapped data.
      console.log("Final invites to be processed:", this.csvMappedData);
      this.$emit("submitInvites", this.csvMappedData);
    },
  },
};
</script>
