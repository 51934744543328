<template>
  <div class="flex min-h-screen bg-white">
    <div
      class="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:px-20 xl:px-24"
    >
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div>
          <img :class="logo_class" :src="logo" alt="Parcel Logo" />
          <h2 class="mt-6 text-3xl font-extrabold text-gray-900">
            Confirm your email to be used to connect with {{ senderName }}:
          </h2>
        </div>
        <div class="mt-8">
          <div class="mt-6">
            <form
              id="login-form"
              action="#"
              method="POST"
              class="space-y-6"
              v-on:submit.prevent
            >
              <email-input
                v-model="userEmail"
                inputTitle="Email"
                @valid="emailValidate"
              />
              <div>
                <submit-button
                  :button-text="buttonText"
                  :disabled="submitDisabled"
                  :errorArray="errorArray"
                  formId="login-form"
                  @submitButtonClick="validateLoginForm"
                />
              </div>
            </form>
            <modal :show="showLoading" title="Confirming your email...">
              <div class="pt-8">
                <div class="pl-32"><loading-spinner /></div>
              </div>
            </modal>
          </div>
        </div>
      </div>
      <a
        :href="homeURL"
        class="mt-6 self-center pt-4 text-sm lowercase text-twilight-600"
        >Go to homepage
      </a>
    </div>
  </div>
</template>

<script>
import EmailInput from "@/components/form/EmailInput.vue";
import SubmitButton from "@/components/buttons/SubmitButton";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import Modal from "@/components/modals/PopupModal.vue";
import api from "@/api/parcel/api";

export default {
  components: { EmailInput, SubmitButton, LoadingSpinner, Modal },
  props: {},
  data() {
    let homeURL = "https://parcel.ag";
    if (process.env.VUE_APP_ENV === "development") {
      homeURL = "https://dev.parcel.ag";
    }
    if (process.env.VUE_APP_ENV === "sandbox") {
      homeURL = "https://sandbox.parcel.ag";
    }
    const { token } = this.$route.params;
    return {
      token,
      senderName: "____",
      senderLogo: "",
      senderSlug: null,
      userEmail: "",
      userEmailIsValid: true,
      submitIsCorrect: true,
      buttonText: "Login",
      errorArray: [],
      loginRedirectName: "grower-portal-home",
      loggingIn: false,
      homeURL: homeURL,
    };
  },
  computed: {
    logo() {
      let logo = require("../../assets/parcel-logo-name.svg");
      if (this.senderLogo) {
        return this.senderLogo;
      }
      return logo;
    },
    logo_class() {
      let logo_class = "h-12 w-auto";
      return logo_class;
    },
    submitDisabled() {
      if (this.loggingIn) return true;
      return false;
    },
    showLoading() {
      if (this.loggingIn) return true;
      return false;
    },
    currentUser() {
      return this.$store.getters.getCurrentUser;
    },
  },
  methods: {
    async loginMagicEmail() {
      try {
        await this.$store.dispatch("loginViaEmail", { email: this.userEmail });
        this.finalizeLogin();
      } catch (err) {
        if (api.exceptionHandler) {
          api.exceptionHandler(err);
        }
        this.loggingIn = false;
      }
    },
    async finalizeLogin() {
      if (this.$store.getters.getUserData.org) {
        await api.auth.respondInvite(this.token, { accepted: true });
        this.$router.push({ name: "landing-page" });
      } else {
        this.$router.push({
          name: "create-org-branded",
          params: { slug: this.senderSlug },
          query: { token: this.token },
        });
      }
    },
    validateLoginForm() {
      this.errorArray = [];
      if (!this.userEmailIsValid) {
        this.submitIsCorrect = false;
        this.errorArray.push({
          id: "InvalidEmailAddress",
          errorDescription: "Please enter a valid email address",
        });
        return;
      }
      // Log in using the user's email
      else if (this.userEmail != "" && this.userEmailIsValid) {
        this.loggingIn = true;
        this.loginMagicEmail();
      }
      // Catch unforseen permutation
      else {
        //this.submitIsCorrect = false;
        this.errorArray.push({
          id: "UncaughtError",
          errorDescription: "Something went wrong",
        });
      }
    },
    emailValidate(valid) {
      this.userEmailIsValid = valid;
    },
  },
  async mounted() {
    const data = await api.auth.readInvite(this.token);
    this.userEmail = data.invite_email;
    this.senderName = data.sender_details.name;
    this.senderLogo = data.sender_details.logo_url;
    this.senderSlug = data.sender_details.slug;
  },
};
</script>
