<template>
  <delete-modal
    :open="modalIsActive"
    modalTitle="Cancel Invoice"
    modalDescription="Are you sure you want to cancel this invoice?"
    @clickCancel="cancelDelete"
    @clickConfirmDelete="cancelInvoice"
    deleteButtonText="Cancel"
    closeButtonText="Back"
  />
  <page-card :headingTitle="invoiceId ? 'Edit Invoice' : 'New Invoice'">
    <template #buttonArea>
      <base-button
        v-if="invoiceId"
        buttonText="Cancel"
        :redButton="true"
        @buttonClick="intializeDeleteModal"
        :disabled="invoice.status === 'Paid'"
      />
      <base-button
        v-else
        buttonText="Save"
        :marginLeft="false"
        @buttonClick="saveInvoice"
      />
    </template>
    <template #cardDetails>
      <div class="sm:col-span-3">
        <label class="block text-sm font-medium text-gray-700">Customer</label>
        <div class="mt-1">
          <select
            v-model="invoice.buyer_id"
            id="customer"
            name="customer"
            class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-twilight-500 focus:outline-none focus:ring-twilight-500 sm:text-sm"
            :disabled="invoiceId ? true : false"
          >
            <option v-for="c in customers" :key="c.id" :value="c.id">
              {{ c.name }}
            </option>
          </select>
        </div>
      </div>
      <text-area
        :displayOnly="invoiceId ? true : false"
        :displayOnlyValue="invoice.name"
        :wideItem="true"
        inputTitle="Memo"
        inputHtmlType="text"
        v-model="invoice.name"
      />
      <currency-input
        :displayOnly="invoiceId ? true : false"
        :displayOnlyValue="invoice.amount"
        :wideItem="true"
        inputTitle="Amount"
        v-model="invoice.amount"
      />
      <variable-string-type-input
        v-if="invoiceId"
        :displayOnly="true"
        :displayOnlyValue="invoice.status"
        :wideItem="true"
        inputTitle="Status"
      />
      <date-time-input
        v-if="invoiceId"
        :display-only="true"
        :display-only-value="invoice.created.substring(0, 16)"
        :wideItem="true"
        inputTitle="Created"
      />
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import VariableStringTypeInput from "@/components/form/VariableStringTypeInput.vue";
import TextArea from "@/components/form/TextArea.vue";
import DeleteModal from "@/components/modals/DeleteModal.vue";
import DateTimeInput from "@/components/form/DateTimeInput.vue";
import CurrencyInput from "@/components/form/CurrencyInput.vue";

export default {
  components: {
    PageCard,
    BaseButton,
    VariableStringTypeInput,
    DeleteModal,
    DateTimeInput,
    TextArea,
    CurrencyInput,
  },
  data() {
    const { invoiceId } = this.$route.params;
    let invoice;
    if (invoiceId !== undefined) {
      invoice = this.$store.getters.getVendorInvoiceById(invoiceId);
      // console.log("INVOICE", invoice);
    } else {
      invoice = { name: "", amount: "" };
    }
    return {
      invoiceId,
      invoice,
      modalIsActive: false,
    };
  },
  computed: {
    customers() {
      let customers = this.$store.state.customers.customers;
      let quotes = this.$store.state.vendorQuotes.vendorQuotes;
      if (quotes.length > 0) {
        for (let quote of quotes) {
          if (
            !customers.find((customer) => customer.id == quote.buyer_details.id)
          ) {
            customers.push({
              id: quote.buyer_details?.id,
              name: quote.buyer_details?.name,
            });
          }
        }
      }
      let orders = this.$store.state.vendorInputOrders.orders;
      let agreements = this.$store.state.vendorInputAgreements.agreements;
      if (orders.length > 0) {
        for (let order of orders) {
          let agreement = agreements.find(
            (agreement) => agreement.id == order.input_agreement,
          );
          // console.log("ORDER", order, agreement);
          if (
            !customers.find(
              (customer) => customer.id == agreement.buyer_details.id,
            )
          ) {
            customers.push({
              id: agreement.buyer_details?.id,
              name: agreement.buyer_details?.name,
            });
          }
        }
      }
      return customers;
    },
  },
  methods: {
    saveInvoice() {
      this.$store.dispatch("createVendorInvoice", this.invoice);
      this.$router.push({ name: "vendor-invoices" });
    },
    cancelInvoice() {
      this.$store.dispatch("deleteVendorInvoice", this.invoiceId);
      this.$router.push({ name: "vendor-invoices" });
    },
    cancelDelete() {
      this.modalIsActive = false;
    },
    intializeDeleteModal() {
      this.modalIsActive = true;
    },
  },
};
</script>
