<template>
  <AlertModal
    :show="showSubmissionModal"
    modalTitle="Inquiry Received"
    :modalDescription="`Your inquiry has been submitted to ${currentOffer?.affiliate_name}. They will review, and if your offer is approved, this inquiry will be converted to an order. You will now be taken to the new order and may begin entering practice documentation now.`"
    @clickDismiss="routeAfterModal"
  />
  <slot-modal :open="showTermsModal" @closeModal="showTermsModal = false">
    <template #content>
      <div
        class="mt-2 grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
      >
        <view-offer-terms :offerId="inquiryOffer" />
      </div>
    </template>
    <template #buttons>
      <base-button
        buttonText="Cancel"
        :redButton="true"
        @buttonClick="cancelAgreeToTerms"
      />
      <base-button
        buttonText="Agree"
        :marginLeft="true"
        @buttonClick="agreeToTerms"
      />
    </template>
  </slot-modal>
  <page-card headingTitle="">
    <template #buttonArea>
      <base-button
        helpText="In 'Application' enter a quantity and confirm the terms to submit an inquiry"
        buttonText="Apply"
        @buttonClick="openOfferInquiry"
        :disabled="!inquiryCapacity || !termsConfirmed"
      />
      <base-button
        buttonText="Back"
        @buttonClick="exitOffer"
        :redButton="true"
      />
    </template>
    <template #cardDetails>
      <!-- <text-input
        v-if="currentOffer"
        inputTitle="Buyer"
        :display-only="true"
        :display-only-value="currentOffer?.affiliate_name"
        :wideItem="true"
      />
      <text-input
        v-if="currentOfferDetails?.contract_name"
        inputTitle="Contract Name"
        :display-only="true"
        :display-only-value="currentOfferDetails?.contract_name"
        :wideItem="true"
      /> -->
      <!-- <div class="flex sm:col-span-6"> -->
      <div class="col-span-2 h-full">
        <detail-card
          :title="'Buyer'"
          :titleStat="currentOffer?.affiliate_name"
          :primaryStat="{
            text: 'Purchasing: ' + currentOffer?.crop,
            icon: 'ClipboardIcon',
          }"
          :secondaryStats="[
            {
              text: 'Incentives: ' + summarizedPremiums,
              icon: 'CurrencyDollarIcon',
            },
            {
              text: 'Deliver by ' + currentOffer?.deliver_by,
              icon: 'CalendarIcon',
            },
          ]"
        />
      </div>
      <div class="h-full sm:col-span-4">
        <detail-card
          :title="'Contract'"
          :titleStat="currentOfferDetails?.contract_name"
          :primaryStat="
            currentOfferDetails?.contract_description
              ? {
                  text:
                    'Description: ' + currentOfferDetails.contract_description,
                  icon: 'ClipboardIcon',
                }
              : { text: '' }
          "
          :secondaryStats="contractStats"
        />
      </div>
      <!-- </div> -->
    </template>
    <template #componentSection>
      <TabGroup as="div" :selectedIndex="selectedTab" @change="tabChanged">
        <div class="border-b border-gray-200">
          <TabList class="-mb-px flex space-x-8">
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Application
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Details
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Premiums
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Territory
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Attachments
              </button>
            </Tab>
          </TabList>
        </div>
        <TabPanels as="template">
          <!-- <TabPanel as="dl" class="pt-10">
            <div class="-ml-6 sm:col-span-6">
              <offer-details :offerId="inquiryOffer" />
            </div>
            <p class="my-4">Inquiries</p>
            <inquiry-table :offerId="inquiryOffer" />
            <p class="my-4">Orders</p>
            <orders-table :offerId="inquiryOffer" />
          </TabPanel> -->
          <TabPanel as="dl" class="pt-10">
            <div
              class="my-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
            >
              Complete and submit this form to apply to the program
            </div>
            <div
              class="mt-2 grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
            >
              <number-formatted-input
                :wideItem="false"
                inputTitle="Quantity You'd Like to Sell'"
                inputHtmlType="text"
                :required="true"
                v-model="inquiryCapacity"
              />
              <template v-if="otherOffers.length > 1">
                <select-dropdown
                  :wideItem="false"
                  inputTitle="Buyer"
                  :required="false"
                  :selectOptions="otherOffers"
                  v-model="inquiryOffer"
                />
              </template>
              <div class="hidden sm:col-span-4" />
              <text-input
                :wideItem="false"
                inputTitle="Maximum Sale Quantity"
                inputHtmlType="text"
                :required="false"
                :displayOnly="true"
                :displayOnlyValue="
                  currentOffer?.quantity?.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 1,
                  }) +
                  ' ' +
                  currentOffer?.quantity_units
                "
              />

              <text-input
                :wideItem="false"
                inputTitle="Remaining Quantity Available"
                inputHtmlType="text"
                :required="false"
                :displayOnly="true"
                :displayOnlyValue="
                  currentOffer?.quantity_remaining?.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 1,
                  }) +
                  ' ' +
                  currentOffer?.quantity_units
                "
              />

              <select-dropdown
                :wideItem="true"
                inputTitle="Premium"
                :required="true"
                :selectOptions="premiumOptions"
                v-model="inquiryPremium"
              />

              <text-input
                :wideItem="true"
                :displayOnly="true"
                :displayOnlyValue="selectedFieldNames"
                inputTitle="Selected Fields"
                help-text="Selecting a field will allocate it to this order. You can select multiple fields."
              />
              <div
                v-if="inquiryPremium"
                class="col-span-6 text-xs text-gray-500"
              >
                {{ selectedPremium?.terms }}
              </div>
              <div class="flex sm:col-span-6">
                <BaseButton buttonText="View Terms" @buttonClick="viewTerms" />
                <span class="ml-2 mt-2 text-xs text-red-500" v-if="!termsViewed"
                  >You must view the terms to submit your application</span
                >
              </div>

              <div class="sm:col-span-6">
                <CheckboxInput
                  help-title="Terms of Acceptance"
                  help-text="Please review the terms of acceptance before confirming"
                  :wideItem="true"
                  inputTitle="By checking this box and submitting my application, I agree to the terms of acceptance as presented above."
                  v-model="termsConfirmed"
                  :displayOnly="!termsViewed"
                  :displayOnlyValue="termsConfirmed"
                  :required="true"
                />
              </div>
              <div class="sm:col-span-6">
                <checkbox-input
                  inputTitle="Select fields that will be allocated to this order (Optional)"
                  v-model="showFieldSelect"
                />
              </div>
              <div class="sm:col-span-6" v-if="showFieldSelect">
                <table-card>
                  <template #header>
                    <table-head
                      :columns="[
                        { name: 'properties.farm', label: 'Farm' },
                        {
                          name: 'properties.name',
                          label: 'Field',
                          filtered: false,
                        },
                        { name: 'properties.crop', label: 'Crop' },
                        {
                          name: 'properties.details.variety',
                          label: 'Variety',
                        },
                        {
                          name: 'properties.acres',
                          label: 'Acres',
                          filtered: false,
                        },
                      ]"
                      :collection="fields"
                      @update:derived-collection="derivedFields = $event"
                      selectionMode="Multiple"
                      @update:selectedCollection="selectedFields = $event"
                      ref="fieldsTable"
                    >
                      <template #toolbarButtons>
                        <div class="hidden sm:flex">
                          <table-toolbar-button
                            buttonText="Clear Selected Fields"
                            :usePlusIcon="true"
                            @buttonClick="clearSelectedFields"
                          />
                        </div>
                      </template>
                    </table-head>
                  </template>
                  <tbody>
                    <tr v-for="field in derivedFields" :key="field">
                      <table-data td-label="Selected">
                        <input
                          type="checkbox"
                          v-model="field.selected"
                          class="cursor-pointer rounded-md border-gray-400 text-indigo-600"
                          @change="toggleFieldInSelectedFields(field)"
                        />
                      </table-data>
                      <table-data td-label="Farm">
                        {{ field.properties.farm }}
                      </table-data>
                      <table-data td-label="Field">
                        <div class="flex">
                          <field-thumbnail
                            :title="field.properties.name"
                            :geojson="field"
                            class="mr-6"
                          ></field-thumbnail>
                          {{ field.properties.name }}
                        </div>
                      </table-data>
                      <table-data td-label="Crop">
                        {{ field.properties.crop }}
                      </table-data>
                      <table-data td-label="Variety">
                        {{
                          field.properties?.details?.variety
                            ? field.properties?.details?.variety
                            : ""
                        }}
                      </table-data>
                      <table-data td-label="Acres" class="text-right">
                        {{
                          field.properties.acres?.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 1,
                          })
                        }}
                      </table-data>
                    </tr>
                  </tbody>
                </table-card>
              </div>
            </div>
          </TabPanel>
          <TabPanel as="dl" class="pt-10">
            <contract-form-details
              v-if="readyToReadOffer"
              v-model="currentOfferDetails"
              :readOnly="true"
            />
          </TabPanel>
          <TabPanel as="dl" class="pt-10">
            <contract-form-premiums
              v-if="readyToReadOffer"
              v-model="currentOfferPremiums"
              :cropType="currentOfferDetails.crop"
              :readOnly="true"
            />
          </TabPanel>
          <TabPanel as="dl" class="pt-10">
            <contract-form-territory
              v-if="readyToReadOffer"
              v-model="currentOfferTerritory"
              :readOnly="true"
            />
          </TabPanel>
          <TabPanel as="dl" class="pt-10">
            <view-attachments-table
              v-if="readyToReadOffer"
              :attachments="currentOffer.attachments"
            />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import SlotModal from "@/components/modals/SlotModal.vue";
import AlertModal from "@/components/modals/AlertModal.vue";
// import OfferForm from "@/layouts/grower-portal/sell/offers/OfferForm.vue";
import TextInput from "@/components/form/TextInput.vue";
import NumberFormattedInput from "@/components/form/NumberFormattedInput.vue";
// import { PaperAirplaneIcon } from "@heroicons/vue/outline";
import { useToast } from "vue-toastification";
import CheckboxInput from "@/components/form/CheckboxInput.vue";
import { Tab, TabList, TabPanel, TabPanels, TabGroup } from "@headlessui/vue";
import ContractFormDetails from "@/layouts/common/contracts/ContractFormDetails.vue";
import ContractFormPremiums from "@/layouts/common/contracts/ContractFormPremiums.vue";
import ContractFormTerritory from "@/layouts/common/contracts/ContractFormTerritory.vue";
import FieldThumbnail from "@/components/map/FieldThumbnail.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableData from "@/components/table/TableData.vue";
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
import DetailCard from "@/components/cards/DetailCard.vue";
import { summarizePremium } from "@/components/composables/scriptUtils";
import ViewAttachmentsTable from "@/layouts/common/attachments/ViewAttachmentsTable.vue";
import ViewOfferTerms from "@/layouts/buyer-grower/crop-inquiries/ViewOfferTerms.vue";
import SelectDropdown from "@/components/form/SelectDropdown.vue";

export default {
  components: {
    PageCard,
    BaseButton,
    SlotModal,
    AlertModal,
    // OfferForm,
    TextInput,
    NumberFormattedInput,
    // PaperAirplaneIcon,
    CheckboxInput,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    TabGroup,
    ContractFormDetails,
    ContractFormPremiums,
    ContractFormTerritory,
    FieldThumbnail,
    TableHead,
    TableCard,
    TableData,
    TableToolbarButton,
    DetailCard,
    ViewAttachmentsTable,
    ViewOfferTerms,
    SelectDropdown,
  },
  data() {
    let selectedTab = 0;
    if (this.$route.query.tab) {
      selectedTab = Number(this.$route.query.tab);
    }
    return {
      inquiryCapacity: null,
      inquiryPremium: null,
      inquiryOffer: this.$route.params.offerId,
      toast: useToast(),
      // Should we only allow them to submit an inquiry if the terms are confirmed?
      showTermsModal: false,
      showSubmissionModal: false,
      termsViewed: false,
      termsConfirmed: false,
      currentOfferDetails: {},
      currentOfferPremiums: [],
      currentOfferTerritory: {},
      order: null,
      derivedFields: [],
      selectedFields: [],
      showFieldSelect: false,
      selectedTab,
    };
  },
  computed: {
    // offerId() {
    //   return this.$route.params.offerId;
    // },
    currentOffer() {
      return this.$store.getters.getGrowerMarketOfferById(this.inquiryOffer);
    },
    otherOffers() {
      return this.$store.getters
        .getGrowerMarketOffers()
        .filter(
          (offer) => offer.contract_name == this.currentOffer.contract_name,
        )
        ?.map((offer) => {
          return {
            label: offer.affiliate_name,
            value: offer.id,
          };
        });
    },
    readyToReadOffer() {
      if (this.offerId && this.currentOffer) {
        return true;
      } else {
        return false;
      }
    },
    fields() {
      return this.$store.getters.getAllFields;
    },
    selectedFieldNames() {
      return this.selectedFields
        .map((field) => field.properties.name)
        .join(", ");
    },
    summarizedPremiums() {
      if (!this.readyToReadOffer) {
        return "";
      }
      return this.summarizePremiums(this.currentOffer.premiums).join(", ");
    },
    premiumOptions() {
      let count = 0;
      return this.currentOffer?.premiums?.map((p) => {
        count++;
        return {
          label:
            "Premium " +
            count +
            ":" +
            summarizePremium(p) +
            " - " +
            p.validation_records.length +
            " requirement(s)",
          value: p.id,
        };
      });
    },
    selectedPremium() {
      if (!this.inquiryPremium) {
        return null;
      }
      return this.currentOfferPremiums.find(
        (p) => p.id === this.inquiryPremium,
      );
    },
    contractStats() {
      let stats = [];
      let count = 0;
      for (let premium of this.currentOfferPremiums) {
        if (premium.terms) {
          count++;
          stats.push({
            text: "Premium " + count + " terms: " + premium.terms,
            icon: "CurrencyDollarIcon",
          });
        }
        let summary = this.dataSummary(premium);
        if (this.dataSummary.length > 0) {
          stats.push({
            text: "Data Required: " + summary,
            icon: "BookOpenIcon",
          });
        }
      }
      return stats;
    },
  },
  methods: {
    summarizePremiums(premiums) {
      return premiums.map((p) => {
        return summarizePremium(p);
      });
    },
    dataSummary(premium) {
      if (!this.readyToReadOffer) {
        return "";
      }
      let summary = "";

      for (let record of premium.validation_records) {
        if (summary.length > 0) {
          summary += ", ";
        }
        summary += record.category;
      }

      if (this.currentOffer.additional_prior_year_records > 0) {
        summary +=
          " records for this year and the last " +
          this.currentOffer.additional_prior_year_records +
          "  years. ";
      } else {
        summary += " records for this year. ";
      }
      return summary;
    },
    async openOfferInquiry() {
      if (!this.currentOffer) {
        await this.$store.dispatch("getGrowerMarketOffers");
      }
      // console.log("openOfferInquiry", this.currentOffer);
      if (this.inquiryCapacity === null) {
        this.toast.error("Please enter a quantity");
        return;
      }
      if (!this.termsConfirmed) {
        this.toast.error("Please confirm the terms");
        return;
      }
      if (!this.inquiryPremium) {
        this.toast.error("Please select a premium");
        return;
      }
      let newThreadId = await this.$store.dispatch("createThread", {
        partner: this.currentOffer.affiliate_org,
        subject: "Order Discussion ",
      });
      // console.log("newThreadId", newThreadId);
      let order = await this.$store.dispatch("createGrowerCropOrder", {
        offer: parseInt(this.inquiryOffer),
        units: this.currentOffer.quantity_units,
        terms:
          // this.inquiryCapacity +
          // " " +
          // this.currentOffer.quantity_units +
          // " of " +
          // this.currentOffer.crop +
          // ". Additional Details: " +
          this.currentOffer.contract_description,
        premium: this.inquiryPremium,
        capacity: this.inquiryCapacity,
        details: {
          threadId: newThreadId,
          crop: this.currentOffer.crop,
        },
      });
      this.order = order;
      if (this.termsConfirmed) {
        await this.$store.dispatch("updateGrowerCropOrder", {
          id: order.id,
          grower_signature: true,
        });
      }
      // Create crop allocations against the selected fields for the order
      for (let field of this.selectedFields) {
        this.$store.dispatch("createGrowerAllocation", {
          crop_order: order.id,
          crop: field.properties.current_crop.id,
          acres: 0,
          notes: "Allocation created on the offer's inquiry submission",
        });
      }
      let messageContent =
        " We are interested in providing " +
        this.inquiryCapacity.toString() +
        " " +
        this.currentOffer.quantity_units +
        " of " +
        this.currentOffer.crop;
      let message = {
        content: messageContent,
      };
      this.$store.dispatch("createMessage", {
        threadId: newThreadId,
        message,
      });
      // Route to orders
      this.showSubmissionModal = true;
    },
    routeAfterModal() {
      this.showSubmissionModal = false;
      this.$router.push({
        name: "grower-portal-offer-order",
        params: { orderId: this.order.id },
      });
    },
    exitOffer() {
      this.$router.push({ name: "grower-portal-market-offers" });
    },
    readOffer() {
      if (!this.readyToReadOffer) {
        return null;
      }
      // set current contract details, premiums, and territory
      let currentOffer = this.$store.getters.getGrowerMarketOfferById(
        this.inquiryOffer,
      );
      this.currentOfferDetails = { ...currentOffer };
      this.currentOfferPremiums = currentOffer.premiums;
      this.currentOfferTerritory = currentOffer.territory;
      delete this.currentOfferDetails.premiums;
      delete this.currentOfferDetails.territory;
      this.selectedFields = [];
    },
    tabChanged(index) {
      // console.log("tabChanged", index);
      this.selectedTab = index;
      this.$router.push({
        query: { tab: index },
      });
    },
    // this method should set the termsViewed to true and open a new tab with the terms
    viewTerms() {
      this.termsViewed = true;
      // open a new tab with the terms
      this.showTermsModal = true;
    },
    agreeToTerms() {
      this.termsConfirmed = true;
      this.showTermsModal = false;
    },
    cancelAgreeToTerms() {
      this.termsConfirmed = false;
      this.showTermsModal = false;
    },
    clearSelectedFields() {
      this.selectedFields = [];
      this.$refs.fieldsTable.clearSelected();
    },
    toggleFieldInSelectedFields(field) {
      let index = this.selectedFields.findIndex((f) => f.id === field.id);
      if (index === -1 && field.selected) {
        this.selectedFields.push(field);
      } else if (index !== -1 && !field.selected) {
        this.selectedFields.splice(index, 1);
      }
    },
  },
  watch: {
    inquiryOffer: {
      // handler(value) or handler(newValue, oldValue)
      handler() {
        this.readOffer();
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
    readyToReadOffer: {
      handler(value) {
        if (value && this.inquiryOffer) {
          this.readOffer();
        }
      },
      immediate: true,
    },
  },
};
</script>
