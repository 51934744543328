<template>
  <invite-login-form />
</template>
<script>
import InviteLoginForm from "@/layouts/auth/InviteLoginForm.vue";

export default {
  components: { InviteLoginForm },
};
</script>
