<template>
  <slot-modal
    :open="shippingModalIsActive"
    modalTitle="Shipping Address"
    modalDescription="Set the delivery address for this order."
    @close-modal="shippingModalIsActive = false"
  >
    <template #content>
      <!-- Shipping Address Section-->
      <div>
        <div>
          <h2 class="text-lg font-medium text-gray-900">Contact Information</h2>

          <div class="mb-4 mt-4">
            <EmailInput
              inputTitle="Email address"
              :wideItem="true"
              :required="true"
              :displayOnly="false"
              v-model="deliveryForm.email"
            />
          </div>
          <PhoneInput2
            v-model="deliveryForm.phone"
            inputTitle="Phone number"
            :wideItem="true"
            :required="true"
            :displayOnly="false"
          />
        </div>
        <div class="mt-10 border-t border-gray-200 pt-10"></div>

        <!-- <div>
          <h2 class="text-lg font-medium text-gray-900">Payment Preference</h2>
          <div class="mb-4 mt-4">
            <SelectDropdown
              inputTitle="Payment Preference"
              :wideItem="true"
              :required="true"
              :displayOnly="false"
              v-model="deliveryForm.preferred_payment_method"
              :selectOptions="[
                { value: 'invoice', label: 'Invoice' },
                { value: 'etransfer', label: 'eTransfer' },
              ]"
            />
          </div>
        </div> -->

        <div class="mt-10 border-t border-gray-200 pt-10">
          <h2 class="text-lg font-medium text-gray-900">
            Shipping information
          </h2>

          <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
            <div class="sm:col-span-2">
              <date-input
                inputTitle="Requested Delivery Date"
                :wideItem="true"
                :required="false"
                :displayOnly="false"
                v-model="requested_delivery_date"
              />
            </div>
            <div>
              <TextInput
                inputTitle="Name"
                :wideItem="true"
                :required="true"
                :displayOnly="false"
                v-model="deliveryForm.shipping_name"
              />
            </div>
            <div class="sm:col-span-2">
              <TextInput
                inputTitle="Company"
                :wideItem="true"
                :required="false"
                :displayOnly="false"
                v-model="deliveryForm.shipping_company"
              />
            </div>
            <div class="sm:col-span-2">
              <TextInput
                inputTitle="Street Address 1"
                :required="true"
                v-model="deliveryForm.shipping_address_1"
                id="address"
                name="address"
                class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
            <div class="sm:col-span-2">
              <TextInput
                inputTitle="Street Address 2"
                v-model="deliveryForm.shipping_address_2"
                id="address"
                name="address"
                class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
            <div>
              <TextInput
                inputTitle="City"
                :wideItem="true"
                :required="true"
                :displayOnly="false"
                v-model="deliveryForm.shipping_city"
              />
            </div>

            <div>
              <SelectDropdown
                inputTitle="Country"
                :wideItem="true"
                :required="true"
                :displayOnly="true"
                :display-only-value="deliveryForm.shipping_country"
              />
            </div>

            <div>
              <TextInput
                inputTitle="State"
                :wideItem="true"
                :required="true"
                :displayOnly="false"
                v-model="deliveryForm.shipping_state"
              />
            </div>

            <div>
              <TextInput
                inputTitle="Postal code"
                :wideItem="true"
                :required="true"
                :displayOnly="false"
                v-model="deliveryForm.shipping_zipcode"
              />
            </div>

            <div class="sm:col-span-2">
              <TextArea
                inputTitle="Memo, Directions, Instructions for Driver, Date needed, etc."
                :wideItem="true"
                :required="false"
                :displayOnly="false"
                v-model="deliveryForm.shipping_delivery_memo"
              />
            </div>
          </div>
        </div>
        <!-- Delivery Method -- >
        <div class="mt-10 border-t border-gray-200 pt-10">
          <RadioGroup v-model="selectedDeliveryMethod">
            <RadioGroupLabel class="text-lg font-medium text-gray-900"
              >Delivery method</RadioGroupLabel
            >

            <div
              class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4"
            >
              <RadioGroupOption
                as="template"
                v-for="deliveryMethod in deliveryMethods"
                :key="deliveryMethod.id"
                :value="deliveryMethod"
                v-slot="{ checked, active }"
              >
                <div
                  :class="[
                    checked ? 'border-transparent' : 'border-gray-300',
                    active ? 'ring-2 ring-indigo-500' : '',
                    'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none',
                  ]"
                >
                  <span class="flex flex-1">
                    <span class="flex flex-col">
                      <RadioGroupLabel
                        as="span"
                        class="block text-sm font-medium text-gray-900"
                        >{{ deliveryMethod.title }}</RadioGroupLabel
                      >
                      <RadioGroupDescription
                        as="span"
                        class="mt-1 flex items-center text-sm text-gray-500"
                        >{{ deliveryMethod.turnaround }}</RadioGroupDescription
                      >
                      <RadioGroupDescription
                        as="span"
                        class="mt-6 text-sm font-medium text-gray-900"
                        >{{ deliveryMethod.price }}</RadioGroupDescription
                      >
                    </span>
                  </span>
                  <CheckCircleIcon
                    v-if="checked"
                    class="h-5 w-5 text-indigo-600"
                    aria-hidden="true"
                  />
                  <span
                    :class="[
                      active ? 'border' : 'border-2',
                      checked ? 'border-indigo-500' : 'border-transparent',
                      'pointer-events-none absolute -inset-px rounded-lg',
                    ]"
                    aria-hidden="true"
                  />
                </div>
              </RadioGroupOption>
            </div>
          </RadioGroup>
        </div>
      -->
        <!-- Payment -- >
            <div class="mt-10 border-t border-gray-200 pt-10">
              <h2 class="text-lg font-medium text-gray-900">Payment</h2>

              <fieldset class="mt-4">
                <legend class="sr-only">Payment type</legend>
                <div
                  class="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0"
                >
                  <div
                    v-for="(paymentMethod, paymentMethodIdx) in paymentMethods"
                    :key="paymentMethod.id"
                    class="flex items-center"
                  >
                    <input
                      v-if="paymentMethodIdx === 0"
                      :id="paymentMethod.id"
                      name="payment-type"
                      type="radio"
                      checked=""
                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                    <input
                      v-else
                      :id="paymentMethod.id"
                      name="payment-type"
                      type="radio"
                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                    <label
                      :for="paymentMethod.id"
                      class="ml-3 block text-sm font-medium text-gray-700"
                      >{{ paymentMethod.title }}</label
                    >
                  </div>
                </div>
              </fieldset>

              <div class="mt-6 grid grid-cols-4 gap-x-4 gap-y-6">
                <div class="col-span-4">
                  <label
                    for="card-number"
                    class="block text-sm font-medium text-gray-700"
                    >Card number</label
                  >
                  <div class="mt-1">
                    <input
                      type="text"
                      id="card-number"
                      name="card-number"
                      autocomplete="cc-number"
                      class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div class="col-span-4">
                  <label
                    for="name-on-card"
                    class="block text-sm font-medium text-gray-700"
                    >Name on card</label
                  >
                  <div class="mt-1">
                    <input
                      type="text"
                      id="name-on-card"
                      name="name-on-card"
                      autocomplete="cc-name"
                      class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div class="col-span-3">
                  <label
                    for="expiration-date"
                    class="block text-sm font-medium text-gray-700"
                    >Expiration date (MM/YY)</label
                  >
                  <div class="mt-1">
                    <input
                      type="text"
                      name="expiration-date"
                      id="expiration-date"
                      autocomplete="cc-exp"
                      class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <label
                    for="cvc"
                    class="block text-sm font-medium text-gray-700"
                    >CVC</label
                  >
                  <div class="mt-1">
                    <input
                      type="text"
                      name="cvc"
                      id="cvc"
                      autocomplete="csc"
                      class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
              </div>
            </div>
        -->
      </div>
    </template>
    <template #buttons>
      <div class="flex justify-end">
        <base-button
          buttonText="Cancel"
          :marginLeft="false"
          @buttonClick="shippingModalIsActive = false"
        />
        <base-button
          buttonText="Save"
          :marginLeft="true"
          @buttonClick="saveDeliveryForm"
        />
      </div>
    </template>
  </slot-modal>
  <div class="bg-gray-50">
    <main class="mx-auto max-w-7xl px-4 pb-24 pt-16 sm:px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:max-w-none">
        <h1 class="sr-only">Checkout</h1>

        <form
          class="flex flex-col space-y-10 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:space-y-0"
          @submit.prevent="checkOut"
        >
          <!-- Line Items -->
          <div class="w-full lg:col-span-2">
            <h2 class="text-lg font-medium text-gray-900">Cart</h2>

            <div
              class="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm"
            >
              <h3 class="sr-only">Items in your cart</h3>
              <ul role="list" class="divide-y divide-gray-200">
                <li
                  v-for="vendor in vendors"
                  :key="vendor.id"
                  class="flex flex-col px-4 py-6 sm:px-6"
                >
                  {{ vendor.name }}

                  <div v-for="product in cartItems" :key="product" class="mt-4">
                    <ul
                      v-if="product?.seller_details?.id == vendor?.id"
                      class="flex flex-row"
                    >
                      <!-- <li > -->

                      <div class="flex-shrink-0">
                        <img
                          v-if="product.image"
                          :src="product.image"
                          :alt="product.imageAlt"
                          class="w-20 rounded-md object-scale-down"
                        />
                      </div>

                      <!-- <div class="ml-6"> -->
                      <div class="flex flex-1 flex-col">
                        <div class="min-w-0 flex-1">
                          <h4 class="text-sm">
                            {{
                              product.name ||
                              product.productDetails.product_name
                            }}
                          </h4>
                          <div v-if="product?.order">
                            <div
                              v-for="(answer, question) in product.order
                                .form_response"
                              :key="question"
                            >
                              <p class="mt-1 text-sm text-gray-500">
                                {{ question }} : {{ answer }}
                              </p>
                            </div>
                            <div
                              v-for="(answer, question) in product.order
                                .variants"
                              :key="question"
                            >
                              <p class="mt-1 text-sm text-gray-500">
                                {{ question }} : {{ answer }}
                              </p>
                            </div>
                            <div
                              v-for="(answer, question) in product.order
                                .options"
                              :key="question"
                            >
                              <p class="mt-1 text-sm text-gray-500">
                                {{ question }} : {{ answer }}
                              </p>
                            </div>
                          </div>
                          <div v-else>
                            <p class="mt-1 text-sm text-gray-500">
                              Quantity: {{ product.qty }}
                            </p>
                            <p
                              class="mt-1 text-sm text-gray-500"
                              v-if="product.productType == 'request'"
                            >
                              {{ product.productDetails.company_name }}
                            </p>
                            <p
                              class="mt-1 text-sm text-gray-500"
                              v-else-if="product.sku"
                            >
                              SKU : {{ product.sku.title }}
                            </p>
                            <p class="mt-1 text-sm text-red-500" v-else>
                              MUST SELECT A SKU
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        class="flex w-10 flex-1 flex-col items-end justify-between pt-2"
                      >
                        <p
                          v-if="product.myPrice"
                          class="text-sm font-medium text-gray-900"
                        >
                          {{ product.myPrice }}
                        </p>
                        <p
                          class="ml-4 text-sm font-medium text-gray-900"
                          v-else
                        >
                          {{
                            product.priceType == "contracted"
                              ? `Price is contracted price from ${vendor?.name}`
                              : "You will receive a quote for this product"
                          }}
                        </p>
                      </div>

                      <!-- </div> -->

                      <!-- </li> -->
                    </ul>
                  </div>
                </li>
              </ul>
              <!--
              <dl class="space-y-6 border-t border-gray-200 px-4 py-6 sm:px-6">
                <div class="flex items-center justify-between">
                  <dt class="text-sm">Subtotal</dt>
                  <dd class="text-sm font-medium text-gray-900">$64.00</dd>
                </div>
                <div class="flex items-center justify-between">
                  <dt class="text-sm">Shipping</dt>
                  <dd class="text-sm font-medium text-gray-900">$5.00</dd>
                </div>
                <div class="flex items-center justify-between">
                  <dt class="text-sm">Taxes</dt>
                  <dd class="text-sm font-medium text-gray-900">$5.52</dd>
                </div>
                <div
                  class="flex items-center justify-between border-t border-gray-200 pt-6"
                >
                  <dt class="text-base font-medium">Total</dt>
                  <dd class="text-base font-medium text-gray-900">$75.52</dd>
                </div>
              </dl>
            --></div>
          </div>
          <!-- Order summary -->
          <div class="mt-10 lg:mt-0">
            <h2 class="text-lg font-medium text-gray-900">Order summary</h2>

            <div
              class="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm"
            >
              <h3 class="sr-only">Items in your cart</h3>
              <!-- <table class="min-w-full divide-y divide-gray-200">
                <tr>
                  <td class="pl-4">Item Subtotal ({{ cartItems?.length }}):</td>
                  <td class="pr-4 text-right">$64.00</td>
                </tr>
                <tr>
                  <td class="pl-4">Estimated Total:</td>
                  <td class="pr-4 text-right">$75.52</td>
                </tr>
              </table> -->
              <ul role="list" class="divide-y divide-gray-200">
                <li
                  v-for="order in orders"
                  :key="order.vendor.id"
                  class="flex flex-col px-4 py-6 sm:px-6"
                >
                  <div v-if="order.vendor.id == -1">
                    Requested Items
                    <select-search
                      :wideItem="true"
                      :showTitle="false"
                      inputTitle="Who would you like to request these items from?"
                      placeholder="Select a seller"
                      :selectOptions="
                        existingInputAgreementSellers.map((v) => {
                          if (v.id != -1) return { label: v.name, value: v.id };
                        })
                      "
                      v-model="requestedVendor"
                      class="mb-4"
                    />
                    <base-button
                      buttonText="Assign to Seller"
                      :marginLeft="false"
                      @buttonClick="makeRequestOrder(requestedVendor)"
                    />
                  </div>
                  <div v-else>
                    {{ order.vendor.name }}

                    <div class="px-4 text-gray-600">
                      <div
                        class="mt-4 flex text-sm font-medium text-gray-700"
                        v-if="!order.vendor.doesDelivery"
                      >
                        <x-circle-icon class="-ml-1 mr-2 h-5 w-5" /> Shipping
                        Not Available
                      </div>
                      <radio-input
                        inputTitle=""
                        :wideItem="true"
                        :required="false"
                        :displayOnly="false"
                        v-model="order.deliveryChoice"
                        :selectOptions="getDeliveryOptions(order)"
                        @on-blur="
                          order.deliveryChoice == 'Pick Up'
                            ? (deliveryForm.shipping_company = 'Pick Up')
                            : null
                        "
                      />
                      <select-search
                        v-if="
                          order?.vendor &&
                          pickupAffiliateLocations(order.vendor.id).length >
                            0 &&
                          order.deliveryChoice == 'Pick Up'
                        "
                        :wideItem="true"
                        :showTitle="false"
                        inputTitle=""
                        placeholder="Select a location"
                        :selectOptions="
                          pickupAffiliateLocations(order.vendor.id)
                        "
                        v-model="order.deliveryForm.shipping_name"
                        class="mb-4"
                      />
                      <!-- <span class="text-lg">Saved Addresses</span> -->
                      <div
                        v-if="
                          priorOrders.length > 0 &&
                          order.deliveryChoice == 'Delivery'
                        "
                        class="flex"
                      >
                        <select-search
                          :wideItem="true"
                          :showTitle="false"
                          inputTitle=""
                          placeholder="Select an address"
                          :selectOptions="priorOrderOptions"
                          v-model="selectedOrderId"
                          @onBlur="fillOrder(order, selectedOrderId, $event)"
                          @update:model-value="
                            fillOrder(order, selectedOrderId)
                          "
                          class="mb-4"
                        />
                        <button
                          @click="editDeliveryForm(order)"
                          class="text-sm text-indigo-600 hover:text-indigo-500"
                          type="button"
                        >
                          <pencil-icon class="-mt-2 ml-2 h-5 w-5" />
                        </button>
                      </div>
                      <div v-else>
                        <div
                          v-if="
                            order.deliveryChoice == 'Delivery' &&
                            priorOrders.length == 0
                          "
                          class="mt-2 text-sm text-gray-500"
                        >
                          Click here to add your delivery address
                          <button
                            @click="editDeliveryForm(order)"
                            class="text-sm text-indigo-600 hover:text-indigo-500"
                            type="button"
                          >
                            <pencil-icon class="-mt-2 ml-2 h-5 w-5" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <!--
              <dl class="space-y-6 border-t border-gray-200 px-4 py-6 sm:px-6">
                <div class="flex items-center justify-between">
                  <dt class="text-sm">Subtotal</dt>
                  <dd class="text-sm font-medium text-gray-900">$64.00</dd>
                </div>
                <div class="flex items-center justify-between">
                  <dt class="text-sm">Shipping</dt>
                  <dd class="text-sm font-medium text-gray-900">$5.00</dd>
                </div>
                <div class="flex items-center justify-between">
                  <dt class="text-sm">Taxes</dt>
                  <dd class="text-sm font-medium text-gray-900">$5.52</dd>
                </div>
                <div
                  class="flex items-center justify-between border-t border-gray-200 pt-6"
                >
                  <dt class="text-base font-medium">Total</dt>
                  <dd class="text-base font-medium text-gray-900">$75.52</dd>
                </div>
              </dl>
            -->

              <div class="border-t border-gray-200 px-4 py-6 sm:px-6">
                <button
                  type="submit"
                  class="w-full rounded-md border border-transparent px-4 py-3 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                  :class="
                    checkingOut || !isFirstParty
                      ? 'bg-gray-400'
                      : 'bg-twilight-700 hover:bg-twilight-800'
                  "
                  :disabled="checkingOut || !isFirstParty"
                >
                  {{ checkOutLabel }}
                </button>
              </div>
              <div
                v-if="!isFirstParty"
                class="w-full text-center text-xs text-red-500"
              >
                You must be a member of this account to place an order.
              </div>
              <div class="m-2 text-justify text-xs">
                <p v-if="checkOutLabel == 'Confirm order'">
                  By clicking confirm order you agree to purchase any items in
                  your cart that are priced, or you have an existing agreement
                  for.
                </p>
                <p class="mt-2 text-justify">
                  If any item is marked that you will receive a quote, you are
                  not committed to purchase that item at this time, and will be
                  contacted by the seller to confirm the price and availability,
                  and will have the ability to approve the purchase based on the
                  provided quote.
                </p>
              </div>
              <div class="mb-2 mt-2 text-center text-sm text-gray-500">
                <p>
                  or
                  <router-link
                    :to="{ name: 'grower-portal-discover' }"
                    class="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Continue Shopping
                    <span aria-hidden="true"> &rarr;</span>
                  </router-link>
                </p>
              </div>
            </div>
          </div>
        </form>
      </div>
    </main>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import SlotModal from "@/components/modals/SlotModal.vue";
import EmailInput from "@/components/form/EmailInput.vue";
import PhoneInput2 from "@/components/form/PhoneInput2.vue";
import SelectDropdown from "@/components/form/SelectDropdown.vue";
import TextArea from "@/components/form/TextArea.vue";
import TextInput from "@/components/form/TextInput.vue";
import SelectSearch from "@/components/form/SelectSearch.vue";
import DateInput from "@/components/form/DateInput.vue";
import RadioInput from "@/components/form/RadioInput.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import { calculatePrice, formatCurrency } from "./priceCalcs.js";
import { XCircleIcon, PencilIcon } from "@heroicons/vue/outline";
import _ from "lodash";

// import {
//   RadioGroup,
//   RadioGroupDescription,
//   RadioGroupLabel,
//   RadioGroupOption,
// } from "@headlessui/vue";
// import { CheckCircleIcon } from "@heroicons/vue/solid";

export default {
  components: {
    BaseButton,
    EmailInput,
    PhoneInput2,
    TextInput,
    TextArea,
    SelectDropdown,
    SelectSearch,
    DateInput,
    RadioInput,
    XCircleIcon,
    PencilIcon,
    SlotModal,
  },
  data() {
    const twoWeeks = 1000 * 60 * 60 * 24 * 14;
    const futureDate = new Date(new Date().getTime() + twoWeeks)
      .toISOString()
      .split("T")[0];
    return {
      toast: useToast(),
      orders: [],
      requestedVendor: null,
      // delivery form for slot modal
      shippingModalIsActive: false,
      selectedOrder: null,
      deliveryForm: {
        email: "",
        phone: "",
        shipping_name: "",
        shipping_company: "Pick Up",
        shipping_address_1: "",
        shipping_address_2: "",
        shipping_city: "",
        shipping_state: "",
        shipping_zipcode: "",
        shipping_country: "United States",
        shipping_delivery_memo: "",
        preferred_payment_method: "invoice",
      },

      requested_delivery_date: futureDate,
      selectedOrderId: null,
      checkingOut: false,
    };
  },
  computed: {
    checkOutLabel() {
      let orderType = "quoted";
      // console.log("cartItems", this.cartItems);
      for (let item of this.cartItems) {
        // console.log("item", item);
        if (item.myPrice) {
          orderType = "contracted";
          break;
        }
      }
      let label = "Confirm order";
      if (orderType == "quoted") {
        label = "Request quote";
      }
      return label;
    },
    currentUser() {
      let user = this.$store.getters.getCurrentUser;
      user.firstName = user?.name.substring(0, user?.name?.indexOf(" "));
      user.lastName = user?.name.substring(user?.name.indexOf(" ") + 1);

      return user;
    },
    currentOrg() {
      return this.$store.getters.getCurrentOrg;
    },
    vendors() {
      let vendorList = [];
      for (let item of this.cartItems) {
        // console.log("cart item item in vendor", item);
        if (!item.seller_details) continue;
        // if (item.seller_details.id == -1) continue;
        // }
        // let vendorList = this.cartItems?.map((item) => {

        // return {
        //   name: "",
        //   id: -1,
        //   doesDelivery: false,
        // };
        let vendor = item.seller_details;
        vendor.doesDelivery = false;
        if (item.seller_details?.allows_shipping) {
          vendor.doesDelivery = true;
        }
        vendorList.push(vendor);
      }
      // console.log("vendorList", vendorList);
      if (!vendorList || vendorList[0] == undefined) return [];
      let vendors = _.uniqBy(vendorList, (vendor) => vendor["id"]);
      return vendors;
    },
    priorOrders() {
      return this.$store.getters.getGrowerInputOrders;
    },
    priorOrderOptions() {
      let options = [];
      for (let order of this.priorOrders) {
        let option = {
          value: order.id,
          label:
            order.shipping_name +
            " - " +
            order.shipping_address_1 +
            ", " +
            order.shipping_city,
        };
        if (options.find((opt) => opt.label == option.label)) continue;
        if (order.shipping_company == "Pick Up") continue; // don't show pick up orders as delivery options
        options.push(option);
      }
      // this.priorOrders.map((order) => {
      //   let option = {
      //     value: order.id,
      //     label: order.shipping_address_1 + ", " + order.shipping_city,
      //   };
      //   options.push(option);
      // });
      // options = [...new Set(options)];
      return options;
    },
    // locations() {
    //   return this.$store.getters.getLocations;
    // },
    // pickupLocations() {
    //   let locations = [];
    //   for (let location of this.locations) {
    //     locations.push({
    //       value: location.name,
    //       label: location.name,
    //     });
    //   }
    //   return locations;
    // },
    cartItems() {
      let cartItems = this.$store.state.shoppingCart["cart_items"];
      if (!cartItems) return [];
      cartItems = cartItems?.map((item) => {
        let listing = null;
        if (item.productType === "service") {
          listing = this.$store.getters.getGrowerProductById(item.productId);
          listing.productType = "service";
          if (listing) {
            let agreements = this.$store.getters.getActiveAgreementsByProductId(
              listing.id,
            );
            if (agreements.length > 0) {
              listing.priceType = "contracted";
            } else {
              listing.priceType = "quoted";
            }
          }
        } else if (item.productType === "request") {
          listing = {};
          listing.productType = "request";
          listing.priceType = item?.priceType;
          listing.title = item.product_name;
          listing.image_url = null;
          if (this.existingInputAgreementSellers?.length == 1) {
            // default this request to the only seller that has an agreement
            listing.seller_details = {
              name: item.company_name,
              id: this.existingInputAgreementSellers[0].id,
            };
          } else {
            listing.seller_details = { name: item.company_name, id: -1 };
          }
        } else {
          listing = {};
          let inputListing = this.$store.getters.getGrowerInputListingById(
            item.productId,
          );
          listing.productType = "product";
          listing.priceType = item?.priceType;
          listing.title = inputListing?.title;
          listing.image_url = inputListing?.image_url;
          listing.seller_details = inputListing?.seller_details;
        }
        item.productType = listing.productType;
        item.priceType = listing?.priceType;
        item.name = listing?.title;
        item.image = listing?.image_url;
        item.imageAlt = listing?.title + " image";
        item.seller_details = listing?.seller_details;
        let previousPrice = calculatePrice(item.sku, this.$store);
        if (previousPrice) {
          item.myPrice = formatCurrency(previousPrice * item.qty);
        }
        return item;
      });
      return cartItems;
    },
    existingInputAgreementSellers() {
      let sellerIds = [];
      let agreements = this.$store.getters.getGrowerInputAgreements;
      // map through agreements and extract the seller ids from agreement.seller_details.id
      agreements.map((agreement) => {
        if (
          !sellerIds.some((seller) => seller.id === agreement.seller_details.id)
        ) {
          sellerIds.push({
            name: agreement.seller_details.name,
            id: agreement.seller_details.id,
            doesDelivery: agreement.seller_details.allows_shipping,
          });
        }
        // if (!sellerIds.includes(agreement.seller_details.id)) {
        //   sellerIds.push(agreement.seller_details.id);
        // }
      });
      return sellerIds;
    },
    sellersIdsFromCart() {
      let sellerIds = [];
      this.cartItems.map((item) => {
        if (!item.seller_details) {
          return;
        }
        if (!sellerIds.includes(item.seller_details.id)) {
          sellerIds.push(item.seller_details.id);
        }
      });
      return sellerIds;
    },
    isFirstParty() {
      return this.$store.getters.currentOrgIsFirstParty;
    },
  },
  methods: {
    makeRequestOrder(vendorId) {
      // console.log("makeRequestOrder", vendorId);
      let vendor = this.existingInputAgreementSellers.find(
        (vendor) => vendor.id == vendorId,
      );
      if (!this.orders.some((order) => order.vendor.id == vendorId)) {
        // create a new order for the vendor
        this.orders.push({
          vendor: vendor,
          deliveryChoice: "Pick Up",
          deliveryForm: {
            email: "",
            phone: "",
            shipping_name: "",
            shipping_company: "Pick Up",
            shipping_address_1: "",
            shipping_address_2: "",
            shipping_city: "",
            shipping_state: "",
            shipping_zipcode: "",
            shipping_country: "United States",
            shipping_delivery_memo: "",
            preferred_payment_method: "invoice",
          },
        });
      }
      for (let item of this.cartItems) {
        // then add each requested item in the cart to the requested vendor
        if (item.seller_details.id == -1) {
          item.seller_details.id = vendor.id;
          item.seller_details.name = vendor.name;
          item.seller_details.doesDelivery = vendor.doesDelivery;
        }
      }
    },
    getDeliveryOptions(order) {
      let deliveryOptions = [];
      if (order.vendor.doesDelivery) {
        deliveryOptions.push({ label: "Delivery", value: "Delivery" });
      }
      if (this.pickupAffiliateLocations(order.vendor.id).length > 0) {
        deliveryOptions.push({ label: "Pick Up", value: "Pick Up" });
      } else {
        order.deliveryChoice = "Delivery";
      }
      // console.log("deliveryOptions", deliveryOptions);
      return deliveryOptions;
    },
    pickupAffiliateLocations(vendorId) {
      let locations =
        this.$store.getters.getGrowerInputLocationBySellerId(vendorId);
      // console.log("locations", locations, vendorId);
      if (!locations) return [];
      let locationOptions = locations.map((location) => {
        return {
          value: location.name,
          label: location.name,
        };
      });
      return locationOptions;
    },
    editDeliveryForm(order) {
      this.selectedOrder = order;
      this.deliveryForm = order.deliveryForm;
      this.shippingModalIsActive = true;
    },
    saveDeliveryForm() {
      this.selectedOrder.deliveryForm = this.deliveryForm;
      this.shippingModalIsActive = false;
    },
    fillOrder(newOrder, priorOrderId) {
      let order = this.priorOrders.find((order) => order.id == priorOrderId);
      if (!order) return;
      newOrder.deliveryForm = {
        email: order.contact_email,
        phone: order.contact_phone.toString(),
        shipping_name: order.shipping_name,
        shipping_company: order.shipping_company,
        shipping_address_1: order.shipping_address_1,
        shipping_address_2: order.shipping_address_2,
        shipping_city: order.shipping_city,
        shipping_state: order.shipping_state,
        shipping_zipcode: order.shipping_zipcode,
        shipping_country: "United States",
        shipping_delivery_memo: order.shipping_delivery_memo,
        preferred_payment_method: order.preferred_payment_method,
        requested_delivery_date: order.requested_delivery_date,
      };
    },
    getImage(imageUrl) {
      if (!imageUrl) return require("@/assets/no_logo_default.jpeg");
      return require(`@/assets/${imageUrl}`);
    },
    loadCart() {
      this.products.map((product) => {
        this.$store.dispatch("addCartItem", product);
      });
    },
    removeProduct(product) {
      this.$store.dispatch("deleteCartItem", product);
    },
    async getAgreementId(sellerId) {
      if (
        !this.existingInputAgreementSellers.some(
          (seller) => seller.id == sellerId,
        )
      ) {
        // 1. prompt the user to submit the required information to create an agreement
        // 2. either via modal or user input
        // 3. if they agree and submit the required information, then create the agreement
        // 4. otherwise, cancel the checkout process
        // hold on implementing the above steps until we have a better idea of what the user flow will be
        let agreementId = await this.$store.dispatch(
          "createGrowerInputAgreement",
          { seller: sellerId },
        );
        return agreementId;
      } else {
        let agreementId =
          await this.$store.getters.getGrowerInputAgreementBySellerId(sellerId);

        return agreementId;
      }
    },
    async loadOrders() {
      this.orders = [];
      for (let sellerId of this.sellersIdsFromCart) {
        // if (sellerId == -1) continue;
        // determine if the status should be "buyer_submitted" if a quote is needed,
        // or "buyer_ordered" if all items are priced
        let vendor = this.vendors.find((vendor) => vendor.id == sellerId);
        this.orders.push({
          vendor: vendor,
          deliveryChoice: "Pick Up",
          deliveryForm: {
            email: "",
            phone: "",
            shipping_name: "",
            shipping_company: "Pick Up",
            shipping_address_1: "",
            shipping_address_2: "",
            shipping_city: "",
            shipping_state: "",
            shipping_zipcode: "",
            shipping_country: "United States",
            shipping_delivery_memo: "",
            preferred_payment_method: "invoice",
          },
        });
        let lineItems = this.cartItems.filter(
          (item) => item.seller_details?.id == sellerId,
        );
        for (let lineItem of lineItems) {
          if (lineItem.myPrice == null) {
            break;
          }
        }
      }
    },
    async checkOut() {
      // for each seller ID from cart, check if the seller ID is in the list of seller IDs with existing agreements
      // if not, create an agreement via the api and save the agreement id locally
      // then, via API create an order using the agreement id
      // for each cart item of the starting seller ID, add a line item to the order via API
      // repeat the process for each seller id in the cart
      // finally, clear the cart, create a toast saying orders submitted, and redirect to the home page
      // ---
      // iterate through cartItems
      // if any cart items do not have a sku, throw a toast error and return
      this.checkingOut = true;
      if (this.cartItems.some((item) => !item.sku)) {
        this.toast.error("There are items in your cart without a SKU.");
        return;
      }
      const placedOrders = {};
      for (let order of this.orders) {
        let sellerId = order.vendor.id;
        if (sellerId == -1) continue;
        let agreement = await this.getAgreementId(sellerId);
        let status = "buyer_submitted";
        // determine if the status should be "buyer_submitted" if a quote is needed,
        // or "buyer_ordered" if all items are priced
        let priced = true; // if one isn't, set to false
        let lineItems = this.cartItems.filter(
          (item) => item.seller_details.id == sellerId,
        );
        for (let lineItem of lineItems) {
          if (lineItem.myPrice == null) {
            priced = false;
            break;
          }
        }

        if (priced) {
          status = "buyer_ordered";
        }

        let placedOrder = await this.$store.dispatch("createGrowerInputOrder", {
          status: status,
          input_agreement: agreement.id,
          shipping_name: order.deliveryForm.shipping_name,
          shipping_company: order.deliveryForm.shipping_company,
          shipping_address_1: order.deliveryForm.shipping_address_1,
          shipping_address_2: order.deliveryForm.shipping_address_2,
          shipping_city: order.deliveryForm.shipping_city,
          shipping_state: order.deliveryForm.shipping_state,
          shipping_zipcode: order.deliveryForm.shipping_zipcode,
          shipping_delivery_memo: order.deliveryForm.shipping_delivery_memo,
          shipping_country: order.deliveryForm.shipping_country,
          contact_email: order.deliveryForm.email,
          contact_phone: order.deliveryForm.phone,
          preferred_payment_method: order.deliveryForm.preferred_payment_method,
          requested_delivery_date: order.requested_delivery_date,
        });
        placedOrders[sellerId] = placedOrder;

        for (let lineItem of lineItems) {
          let orderItem = {};
          if (lineItem.productType == "request") {
            orderItem = {
              quantity: lineItem.qty,
              input_request: lineItem.sku.id,
              package_request: lineItem.package,
            };
          } else {
            orderItem = {
              quantity: lineItem.qty,
              input_unit: lineItem.sku.id,
            };
          }
          await this.$store.dispatch("addGrowerInputOrderLineItem", {
            order: placedOrder.id,
            lineItem: orderItem,
          });
        }
      }
      // let requestItems = this.cartItems.filter(
      //   (item) => item.productType == "request",
      // );
      // if (requestItems.length > 0) {
      //   // for (let sellerId of this.existingInputAgreementSellers) {
      //   let sellerId = this.requestedVendor;
      //   let agreement = await this.getAgreementId(sellerId);
      //   let order = placedOrders[sellerId];
      //   if (!order)
      //     order = await this.$store.dispatch("createGrowerInputOrder", {
      //       status: "buyer_submitted",
      //       input_agreement: agreement.id,
      //       shipping_name: order.deliveryForm.shipping_name,
      //       shipping_company: order.deliveryForm.shipping_company,
      //       shipping_address_1: order.deliveryForm.shipping_address_1,
      //       shipping_address_2: order.deliveryForm.shipping_address_2,
      //       shipping_city: order.deliveryForm.shipping_city,
      //       shipping_state: order.deliveryForm.shipping_state,
      //       shipping_zipcode: order.deliveryForm.shipping_zipcode,
      //       shipping_delivery_memo: order.deliveryForm.shipping_delivery_memo,
      //       shipping_country: order.deliveryForm.shipping_country,
      //       contact_email: order.deliveryForm.email,
      //       contact_phone: order.deliveryForm.phone,
      //       preferred_payment_method:
      //         order.deliveryForm.preferred_payment_method,
      //       requested_delivery_date: order.requested_delivery_date,
      //     });
      //   for (let lineItem of requestItems) {
      //     await this.$store.dispatch("addGrowerInputOrderLineItem", {
      //       order: order.id,
      //       lineItem: {
      //         quantity: lineItem.qty,
      //         input_request: lineItem.sku.id,
      //         package_request: lineItem.package,
      //       },
      //     });
      //   }
      // }
      // }
      if (!this.$store.getters.getApiError) {
        this.toast.success("Orders submitted");
      }
      this.$store.dispatch("clearCartItems");
      this.$store.dispatch("getGrowerInputOrders");
      this.checkingOut = false;
      this.$router.push({ name: "grower-portal-discover" });
    },
  },
  watch: {
    cartItems: {
      handler: function (newVal) {
        if (newVal.length > 0) {
          this.loadOrders();
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
