import client from "@/api/parcel/api.js";
import _ from "lodash";

let unblock;
let blockUntilLoaded;
function setPromise() {
  blockUntilLoaded = new Promise((resolve) => {
    unblock = resolve;
  });
}
setPromise();

export default {
  state() {
    return {
      orgs: [],
      public_affiliate_orgs: [],
      org_profile: {},
    };
  },
  mutations: {
    SET_ORGS(state, orgData) {
      state.orgs = orgData;
    },
    SET_ORG_PROFILE(state, orgData) {
      state.org_profile = orgData;
    },
    ADD_ORG(state, orgObject) {
      state.orgs.push(orgObject);
    },
    UPDATE_ORG(state, orgObject) {
      state.orgs = state.orgs.filter(function (obj) {
        return obj.id !== orgObject.id;
      });
      state.orgs.push(orgObject);
    },
    RESET_ORGS(state) {
      state.orgs = [];
      state.org_profile = {};
    },
    RESET_ORGS_PROMISE() {
      setPromise();
    },
    SET_PUBLIC_AFFILIATE_ORGS(state, orgData) {
      state.public_affiliate_orgs = orgData;
    },
    RESET_PUBLIC_AFFILIATE_ORGS(state) {
      state.public_affiliate_orgs = [];
    },
  },
  actions: {
    async getOrgs({ commit }) {
      const data = await client.orgs.read();
      if (data) {
        commit("SET_ORGS", data.orgs);
        unblock();
      }
    },
    async createOrg({ commit }, org) {
      const data = await client.orgs.create(null, org);
      if (data) {
        // console.log(data);
        commit("ADD_ORG", org);
        commit("RESET_USER_PROMISE");
        this.dispatch("getLoginStatus");
        await this.dispatch("getOrgs");
      }
    },
    async updateOrg({ commit }, org) {
      const data = await client.orgs.update(null, org);
      if (data) {
        commit("UPDATE_ORG", data);
        this.dispatch("getOrgs");
      }
    },
    async uploadOrgLogo(_, orgLogo) {
      // console.log(orgLogo);

      const data = await client.orgs.createLogo(null, orgLogo);
      if (data) {
        return data;
        // await this.dispatch("getOrgProfile");
      }
    },
    // Org Profile
    async getOrgProfile({ commit }) {
      const data = await client.orgs.readProfile();
      if (data) {
        commit("SET_ORG_PROFILE", data);
      }
    },
    async createOrgProfile({ commit }, orgProfile) {
      const data = await client.orgs.createProfile(null, orgProfile);
      if (data) {
        // console.log(data);
        commit("SET_ORG_PROFILE", orgProfile);
        await this.dispatch("getOrgProfile");
      }
    },
    async uploadOrgProfileLogo(_, orgProfileLogo) {
      // console.log(orgProfileLogo);

      const data = await client.orgs.createProfileLogo(null, orgProfileLogo);
      if (data) {
        return data;
        // await this.dispatch("getOrgProfile");
      }
    },
    async uploadOrgProfileBanner(_, orgProfileBanner) {
      const data = await client.orgs.createProfileBanner(
        null,
        orgProfileBanner,
      );
      if (data) {
        return data;
        // await this.dispatch("getOrgProfile");
      }
    },
    async uploadOrgProfileResourceImage(_, orgProfileResourceImageObject) {
      let image = orgProfileResourceImageObject.image;
      let index = orgProfileResourceImageObject.index;
      // console.log("ImageObject", orgProfileResourceImageObject, index, image);
      //console.log("index", imageIndex);

      const data = await client.orgs.createResourceImage(index, image);
      if (data) {
        return data;
        // await this.dispatch("getOrgProfile");
      }
    },
    async deleteOrgProfileResourceImage(_, { index }) {
      await client.orgs.deleteResourceImage(index);
    },
    async getCurrentOrgBlocking({ getters }) {
      await blockUntilLoaded;
      return getters.getCurrentOrg;
    },
    async createGrowerOrgAsAffiliate({ rootGetters }, org) {
      const data = await client.orgs.createGrowerOrgAsAffiliate(null, org);
      if (data) {
        if (rootGetters.currentOrgIsFirstParty) this.dispatch("getSharedOrgs");
        this.dispatch("getMemberOrgs");
        return data;
      }
    },
    async getPublicAffiliateOrgs({ commit }) {
      const data = await client.orgs.readPublicAffiliateDetails();
      if (data) {
        commit("SET_PUBLIC_AFFILIATE_ORGS", data.affiliates);
        unblock();
      }
    },
  },
  getters: {
    getCurrentOrg: (state) => {
      return state.orgs.find((org) => org.self === true);
    },
    getCurrentOrgType: (state) => {
      let currentOrg = state.orgs.find((org) => org.self === true);
      if (currentOrg)
        if (currentOrg.purchases_flag) {
          return "buyer";
        } else if (currentOrg.revenue_flag) {
          return "vendor";
        } else {
          return "grower";
        }
      else return "";
    },
    getOrgById: (state) => (orgId) => {
      return state.orgs.find((org) => org.id === parseInt(orgId));
    },
    getAllPublicAffiliateOrgs: (state) => {
      return state.public_affiliate_orgs;
    },
    getDerivedOrgs:
      (state) =>
      ({
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let expandedOrgs = state.orgs;
        let filteredOrgs = _.isEmpty(filter)
          ? expandedOrgs
          : _.filter(expandedOrgs, filter);
        let matchedOrgs = _.isEmpty(match)
          ? filteredOrgs
          : _.filter(filteredOrgs, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortColumns = Object.keys(sort);
        let sortOrders = Object.values(sort);
        //console.log("sort in index",sort,sortColumns, sortOrders);
        let sortedOrgs = _.isEmpty(sort)
          ? matchedOrgs
          : _.orderBy(matchedOrgs, sortColumns, sortOrders);
        let paginatedOrgs =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedOrgs,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedOrgs;
        return paginatedOrgs;
      },
  },
};
