<template>
  <modal :show="modalIsActive" title="Importing Spreadsheet">
    <div v-html="modalDescription" class="prose"></div>
    <div class="pt-8" v-if="modalIsActive">
      <div class="pl-32"><loading-spinner /></div>
    </div>
  </modal>
  <!-- <BaseButton @buttonClick="exportRecordsTemplate" buttonText="Edit in Excel" /> -->
  <TableToolbarButton
    @buttonClick="exportRecordsTemplate"
    buttonText="Edit Records in Excel"
  />
  <!-- <input type="file" @change="handleFileInput" /> -->
  <file-select
    class="h-full"
    buttonText="Upload Changes"
    accept="xlsx"
    @input="handleFileInput"
    :showSelectedFile="false"
    button-type="table-toolbar"
  />
</template>

<script>
// import BaseButton from "@/components/buttons/BaseButton.vue";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import FileSelect from "@/components/form/FileSelect.vue";
const RecordTypeDefinitions = require("@/layouts/grower-portal/records/RecordTypes.json");
let cropList = require("@/assets/croplist.js");
import { useToast } from "vue-toastification";
import Modal from "@/components/modals/PopupModal.vue";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import { recordResult } from "@/components/composables/scriptUtils.js";
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";

export default {
  components: {
    // BaseButton,
    TableToolbarButton,
    FileSelect,
    Modal,
    LoadingSpinner,
  },
  props: {
    orderIdOverride: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      toast: useToast(),
      maxFieldYear: null,
      modalIsActive: false,
      modalDescription: "",
    };
  },
  methods: {
    // Helper function to format a given year or date string to the end of that year (YYYY-12-31)
    formatHarvestRangeEnd(input) {
      let year = parseInt(input, 10);
      if (isNaN(year)) {
        const date = new Date(input);
        if (!isNaN(date)) {
          year = date.getFullYear();
        } else {
          return input; // return input if it cannot be parsed
        }
      }
      return new Date(year, 11, 31).toISOString().split("T")[0];
    },

    // -------------------------
    // Public Methods
    // -------------------------
    getMixProductAttributes(category) {
      const recordType = RecordTypeDefinitions.find(
        (type) => type.category === category,
      );
      if (!recordType) return [];
      const mixProductsDefinition = recordType.data.payload.find(
        (item) => item.value === "mix_products",
      );
      return mixProductsDefinition?.payload || [];
    },
    async exportRecordsTemplate() {
      const workbook = new ExcelJS.Workbook({ useStyles: true });
      const maxSheetRows = 200; // Adjust as needed

      // Create sheets using helper functions
      this._createValidationsSheet(workbook, this.cropOptions);
      this._createInstructionsSheet(workbook);
      this._createFieldsWorksheet(
        workbook,
        this.cropsForExport,
        this.cropOptions,
        maxSheetRows,
      );
      this._createCategoryWorksheets(
        workbook,
        this.validation_records,
        this.cropsForExport,
        maxSheetRows,
      );

      // Generate the Excel file and trigger download
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "RecordsTemplate.xlsx");
    },
    async importRecordsTemplate(file) {
      console.log("Importing records template...");
      this.modalIsActive = true;
      this.modalDescription = "Uploading spreadsheet...";
      try {
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(file);
        this.modalDescription = "File uploaded...";

        // Process Fields sheet first
        await this._processFieldsSheet(workbook);
        // Refresh store data to ensure new fields and crops are available before processing records
        // We need the data in the store so that the AllocationRecords can computed the updates to crops that are passed into this component
        await this.$store.dispatch("getFields");
        await this.$store.dispatch("getCrops");

        this.modalDescription = "Fields processed. Beginning record import...";

        // Process all record sheets
        await this._processRecordSheets(workbook);

        this.modalIsActive = false;
        this.toast.success("Import successful.");
        await this.$store.dispatch("getFields");
        await this.$store.dispatch("getCrops");
        await this.$store.dispatch("getRecords");
        await this.$store.dispatch("getGrowerCropOrders");
        await this.$store.dispatch("getGrowerAllocations");
      } catch (error) {
        this.modalIsActive = false;
        this.toast.error(error.message);
        return;
      }
    },
    handleFileInput(event) {
      console.log(event);
      // Using file-select component that passes the file directly.
      const file = event;
      if (file) {
        this.importRecordsTemplate(file);
      }
    },

    // -------------------------
    // Helper Functions for Export
    // -------------------------
    _createValidationsSheet(workbook, cropOptions) {
      const validationsWorksheet = workbook.addWorksheet("validations", {
        state: "veryHidden",
      });
      validationsWorksheet.getCell("A1").value = "Crop Options";
      cropOptions.forEach((option, index) => {
        validationsWorksheet.getCell(`A${index + 2}`).value = option;
      });
    },
    _createInstructionsSheet(workbook) {
      const instructionsWorksheet = workbook.addWorksheet("Instructions");
      instructionsWorksheet.mergeCells("A1:E1");
      instructionsWorksheet.getCell("A1").value =
        "How to Fill Out This Records Template";
      instructionsWorksheet.getCell("A1").font = { bold: true, size: 16 };
      instructionsWorksheet.getCell("A1").alignment = { horizontal: "center" };

      const instructions = [
        "Please do not rename, reorder, or remove any worksheets. Doing so may prevent the import from functioning properly.",
        "The 'Fields' sheet holds your field data (Field, Farm, Crop Year, Crop Type, Acres). Make sure these values are accurate to start. The other sheets rely on these.",
        "Each other sheet corresponds to a specific record category (e.g., Fertilizer Application). Fill in data below row 1 (headers).",
        "Do not enter data for Crop Type, Acres, and Farm columns in record category sheets; instead use the drop downs for Field and Crop Year which will then compute the values.",
        "Some columns are hidden because they are used internally. Do not unhide or remove them.",
        "If a column has a dropdown, use the provided list. Enter dates in YYYY-MM-DD format where indicated.",
        "If a column is gray, it is a computed value and should not be edited.",
        "If a column is green, it should be filled in for every record.",
      ];

      instructions.forEach((text, index) => {
        const rowNumber = index + 2;
        instructionsWorksheet.mergeCells(`A${rowNumber}:E${rowNumber}`);
        instructionsWorksheet.getCell(`A${rowNumber}`).value = text;
        instructionsWorksheet.getCell(`A${rowNumber}`).alignment = {
          wrapText: true,
        };
      });
      instructionsWorksheet.columns = [
        { key: "A", width: 100 },
        { key: "B", width: 0 },
        { key: "C", width: 0 },
        { key: "D", width: 0 },
        { key: "E", width: 0 },
      ];
      instructionsWorksheet.protect("password", {
        selectLockedCells: false,
        selectUnlockedCells: true,
      });
    },
    _createFieldsWorksheet(
      workbook,
      cropsForExport,
      cropOptions,
      maxSheetRows,
    ) {
      const fieldsWorksheet = workbook.addWorksheet("Fields");
      const fieldsData = cropsForExport.map((crop) => ({
        "Crop ID": crop.crop_id,
        "Crop Type": crop.crop_type,
        Acres: crop.acres,
        Farm: crop.farm,
        Field: crop.field,
        "Crop Year": crop.crop_year,
      }));

      fieldsWorksheet.columns = [
        { header: "Crop ID", key: "Crop ID", width: 20 },
        { header: "Crop Type", key: "Crop Type", width: 20 },
        { header: "Acres", key: "Acres", width: 20 },
        { header: "Farm", key: "Farm", width: 20 },
        { header: "Field", key: "Field", width: 20 },
        { header: "Crop Year", key: "Crop Year", width: 20 },
        { header: "LookupKey", key: "LookupKey", width: 30, hidden: true },
      ];

      fieldsWorksheet.addRows(fieldsData);

      for (let rowIndex = 2; rowIndex <= maxSheetRows; rowIndex++) {
        fieldsWorksheet.getCell(`G${rowIndex}`).value = {
          formula: `E${rowIndex}&"||"&F${rowIndex}`,
        };
      }

      const cropIdColIndex =
        fieldsWorksheet.columns.findIndex((col) => col.header === "Crop ID") +
        1;
      if (cropIdColIndex > 0) {
        fieldsWorksheet.getColumn(cropIdColIndex).hidden = true;
      }
      const cropTypeColumnLetter =
        fieldsWorksheet.getColumn("Crop Type").letter;
      const maxRow = cropOptions.length + 1; // 1 header + options count
      fieldsWorksheet.dataValidations.add(
        `${cropTypeColumnLetter}2:${cropTypeColumnLetter}${maxSheetRows}`,
        {
          type: "list",
          allowBlank: true,
          formulae: [`validations!$A$2:$A$${maxRow}`],
          showErrorMessage: true,
          errorTitle: "Invalid Crop Type",
          error: "Please select a valid Crop Type from the list.",
        },
      );
    },
    _createCategoryWorksheets(
      workbook,
      validationRecords,
      cropsForExport,
      maxSheetRows,
    ) {
      validationRecords.forEach((record) => {
        const recordTypeDefinition = RecordTypeDefinitions.find(
          (type) => type.category === record.category,
        );

        const requiredAttributes = [
          "Crop ID",
          "Crop Type",
          "Acres",
          "Farm",
          "Field",
          "Crop Year",
          "Record ID",
        ];

        let mixProductAttributes = [];
        if (
          record.category === "Fertilizer Application" ||
          record.category === "Input Application" ||
          record.category === "Planting"
        ) {
          mixProductAttributes = this.getMixProductAttributes(record.category);
          requiredAttributes.push(
            ...mixProductAttributes.map((attr) => attr.label),
          );
        }

        const otherPayloadAttributes = recordTypeDefinition
          ? recordTypeDefinition.data.payload
              .filter((attr) => attr.value !== "mix_products")
              .map((attr) => attr.label)
          : [];
        requiredAttributes.push(...otherPayloadAttributes);

        const worksheet = workbook.addWorksheet(record.category);
        worksheet.columns = requiredAttributes.map((attr) => ({
          header: attr,
          key: attr,
          width: 20,
          hidden: attr === "Crop ID" || attr === "Record ID",
        }));

        // bold header row
        worksheet.getRow(1).font = { bold: true };

        cropsForExport.forEach((crop) => {
          const cropRecords = crop.records.filter(
            (cropRecord) => cropRecord.category === record.category,
          );

          if (!cropRecords || cropRecords.length === 0) {
            const emptyRow = Object.fromEntries(
              requiredAttributes.map((attr) => [attr, ""]),
            );
            emptyRow["Record ID"] = "";
            emptyRow["Field"] = crop.field;
            emptyRow["Crop Year"] = crop.crop_year;
            worksheet.addRow(emptyRow);
          } else {
            cropRecords.forEach((cropRecord) => {
              const baseRow = {
                "Crop ID": "",
                "Crop Type": "",
                Acres: "",
                Farm: "",
                "Record ID": cropRecord.id || "",
                Field: crop.field,
                "Crop Year": crop.crop_year,
              };

              otherPayloadAttributes.forEach((label) => {
                const attrDefinition = recordTypeDefinition.data.payload.find(
                  (a) => a.label === label,
                );
                baseRow[label] = attrDefinition
                  ? cropRecord.payload?.[attrDefinition.value] || ""
                  : "";
              });

              const mixProducts = cropRecord.payload?.mix_products;
              if (!Array.isArray(mixProducts) || mixProducts.length === 0) {
                const singleRow = { ...baseRow };
                mixProductAttributes.forEach((attr) => {
                  singleRow[attr.label] = "";
                });
                worksheet.addRow(singleRow);
              } else {
                mixProducts.forEach((productObj) => {
                  const rowWithProduct = { ...baseRow };
                  mixProductAttributes.forEach((attr) => {
                    rowWithProduct[attr.label] = productObj?.[attr.value] || "";
                  });
                  worksheet.addRow(rowWithProduct);
                });
              }
            });
          }
        });

        if (recordTypeDefinition) {
          recordTypeDefinition.data.payload.forEach((attr) => {
            if (attr.value === "mix_products") {
              console.log(
                "Processing mix products...",
                recordTypeDefinition.data.payload,
              );
              let payload = recordTypeDefinition.data.payload.find(
                (item) => item.value === "mix_products",
              )?.payload;
              payload.forEach((mixAttr) => {
                this.addDataValidation(
                  worksheet,
                  requiredAttributes,
                  maxSheetRows,
                  mixAttr,
                );
              });
            } else {
              this.addDataValidation(
                worksheet,
                requiredAttributes,
                maxSheetRows,
                attr,
              );
            }
          });
        }

        const fieldColumnLetter = worksheet.getColumn("Field").letter;
        worksheet.dataValidations.add(
          `${fieldColumnLetter}2:${fieldColumnLetter}${maxSheetRows}`,
          {
            type: "list",
            allowBlank: true,
            formulae: [`Fields!$E$2:$E$${maxSheetRows}`],
            showErrorMessage: true,
            errorTitle: "Invalid Input",
            error: "Please select a valid Field.",
          },
        );

        const cropYearColumnLetter = worksheet.getColumn("Crop Year").letter;
        worksheet.dataValidations.add(
          `${cropYearColumnLetter}2:${cropYearColumnLetter}${maxSheetRows}`,
          {
            type: "list",
            allowBlank: true,
            formulae: [`Fields!$F$2:$F$${maxSheetRows}`],
            showErrorMessage: true,
            errorTitle: "Invalid Input",
            error: "Please select a valid Crop Year.",
          },
        );

        this._applyMatchFormulas(worksheet, maxSheetRows);
        const requiredFill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "C6EFCE" }, // light green color
        };
        const requiredFillLight = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "E8EFD9" }, // light green color
        };
        const standardFill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "F0F0F0" }, // light gray color
        };
        const standardFillLight = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFFFF" }, // white color
        };
        const borderStyle = {
          top: { style: "thin", color: { argb: "ECECEC" } },
          left: { style: "thin", color: { argb: "ECECEC" } },
          bottom: { style: "thin", color: { argb: "ECECEC" } },
          right: { style: "thin", color: { argb: "ECECEC" } },
        };
        const borderStyleDark = {
          top: { style: "thin", color: { argb: "636363" } },
          left: { style: "thin", color: { argb: "ECECEC" } },
          bottom: { style: "thin", color: { argb: "636363" } },
          right: { style: "thin", color: { argb: "ECECEC" } },
        };
        // alternate row colors
        for (let columnIndex = 0; columnIndex < 4; columnIndex++) {
          const column = worksheet.columns[columnIndex];
          for (let rowIndex = 1; rowIndex <= maxSheetRows; rowIndex++) {
            worksheet.getCell(`${column.letter}${rowIndex}`).border =
              borderStyleDark;
          }
        }
        for (
          let columnIndex = 4;
          columnIndex < worksheet.columns.length;
          columnIndex++
        ) {
          const column = worksheet.columns[columnIndex];
          for (let rowIndex = 1; rowIndex <= maxSheetRows; rowIndex++) {
            if (rowIndex % 2 === 0) {
              worksheet.getCell(`${column.letter}${rowIndex}`).fill =
                standardFillLight;
            } else {
              worksheet.getCell(`${column.letter}${rowIndex}`).fill =
                standardFill;
            }
            worksheet.getCell(`${column.letter}${rowIndex}`).border =
              borderStyle;
          }
        }
        // Process payload attributes (non-mix)
        if (recordTypeDefinition) {
          const payloadAttrs = recordTypeDefinition.data.payload.filter(
            (attr) => attr.value !== "mix_products" && attr.required,
          );
          payloadAttrs.forEach((attr) => {
            // Find the column whose header matches this attribute’s label
            const column = worksheet.columns.find(
              (col) => col.header === attr.label,
            );
            if (column) {
              for (let rowIndex = 1; rowIndex <= maxSheetRows; rowIndex++) {
                if (rowIndex % 2 === 0) {
                  worksheet.getCell(`${column.letter}${rowIndex}`).fill =
                    requiredFillLight;
                } else {
                  worksheet.getCell(`${column.letter}${rowIndex}`).fill =
                    requiredFill;
                }
              }
            }
          });
        }

        // Process mix product attributes (if any) that are required
        if (mixProductAttributes && mixProductAttributes.length) {
          mixProductAttributes
            .filter((attr) => attr.required)
            .forEach((attr) => {
              const column = worksheet.columns.find(
                (col) => col.header === attr.label,
              );
              if (column) {
                for (let rowIndex = 1; rowIndex <= maxSheetRows; rowIndex++) {
                  if (rowIndex % 2 === 0) {
                    worksheet.getCell(`${column.letter}${rowIndex}`).fill =
                      requiredFillLight;
                  } else {
                    worksheet.getCell(`${column.letter}${rowIndex}`).fill =
                      requiredFill;
                  }
                  worksheet.getCell(`${column.letter}${rowIndex}`).border =
                    borderStyle;
                }
              }
            });
        }

        // Mark attributes that are required from the contract premium.
        // let validation_record = this.premiums[0].validation_records.filter(
        //   (column) => column.category === record.category,
        // );
        let validation_record = record;
        if (validation_record && validation_record.attributes.all == false) {
          validation_record.attributes.payload.forEach((key) => {
            if (
              key == "mix_products" &&
              mixProductAttributes &&
              mixProductAttributes.length
            ) {
              mixProductAttributes.forEach((attr) => {
                const column = worksheet.columns.find(
                  (col) => col.header === attr.label,
                );
                if (column) {
                  for (let rowIndex = 1; rowIndex <= maxSheetRows; rowIndex++) {
                    if (rowIndex % 2 === 0) {
                      worksheet.getCell(`${column.letter}${rowIndex}`).fill =
                        requiredFillLight;
                    } else {
                      worksheet.getCell(`${column.letter}${rowIndex}`).fill =
                        requiredFill;
                    }
                  }
                }
              });
            } else {
              let label = recordTypeDefinition.data.payload.find(
                (attr) => attr.value === key,
              ).label;
              if (label) {
                const column = worksheet.columns.find(
                  (col) => col.header === label,
                );
                if (column) {
                  for (let rowIndex = 1; rowIndex <= maxSheetRows; rowIndex++) {
                    if (rowIndex % 2 === 0) {
                      worksheet.getCell(`${column.letter}${rowIndex}`).fill =
                        requiredFillLight;
                    } else {
                      worksheet.getCell(`${column.letter}${rowIndex}`).fill =
                        requiredFill;
                    }
                  }
                }
              }
            }
          });
        }
        worksheet.columns.forEach((col) => {
          if (col.letter >= "E")
            col.protection = {
              locked: false,
              lockText: false,
            };
        });
        worksheet.getRow(1).protection = {
          locked: true,
          lockText: true,
        };
        worksheet.protect("password", {
          selectLockedCells: false,
          selectUnlockedCells: true,
        });
      });
    },
    _applyMatchFormulas(worksheet, maxSheetRows) {
      const startRow = 2;
      const matchFieldLetter = worksheet.getColumn("Field").letter;
      const matchCropYearLetter = worksheet.getColumn("Crop Year").letter;
      const lightFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "A9A9A9" },
      };
      for (let rowIndex = startRow; rowIndex <= maxSheetRows; rowIndex++) {
        worksheet.getCell(`A${rowIndex}`).value = {
          formula: `IF(AND($${matchFieldLetter}${rowIndex}<>"" , $${matchCropYearLetter}${rowIndex}<>""), INDEX(Fields!$A:$A, MATCH($${matchFieldLetter}${rowIndex}&"||"&$${matchCropYearLetter}${rowIndex}, Fields!$G:$G, 0)), "" )`,
        };
        worksheet.getCell(`A${rowIndex}`).fill = lightFill;
        worksheet.getCell(`B${rowIndex}`).value = {
          formula: `IF(AND($${matchFieldLetter}${rowIndex}<>"" , $${matchCropYearLetter}${rowIndex}<>""), INDEX(Fields!$B:$B, MATCH($${matchFieldLetter}${rowIndex}&"||"&$${matchCropYearLetter}${rowIndex}, Fields!$G:$G, 0)), "" )`,
        };
        worksheet.getCell(`B${rowIndex}`).fill = lightFill;
        worksheet.getCell(`C${rowIndex}`).value = {
          formula: `IF(AND($${matchFieldLetter}${rowIndex}<>"" , $${matchCropYearLetter}${rowIndex}<>""), INDEX(Fields!$C:$C, MATCH($${matchFieldLetter}${rowIndex}&"||"&$${matchCropYearLetter}${rowIndex}, Fields!$G:$G, 0)), "" )`,
        };
        worksheet.getCell(`C${rowIndex}`).fill = lightFill;
        worksheet.getCell(`D${rowIndex}`).value = {
          formula: `IF(AND($${matchFieldLetter}${rowIndex}<>"" , $${matchCropYearLetter}${rowIndex}<>""), INDEX(Fields!$D:$D, MATCH($${matchFieldLetter}${rowIndex}&"||"&$${matchCropYearLetter}${rowIndex}, Fields!$G:$G, 0)), "" )`,
        };
        worksheet.getCell(`D${rowIndex}`).fill = lightFill;
      }
    },

    // -------------------------
    // Helper Functions for Import
    // -------------------------
    async _processFieldsSheet(workbook) {
      const fieldsSheet = workbook.getWorksheet("Fields");
      if (!fieldsSheet) {
        console.error("No 'Fields' worksheet found.");
        return;
      }
      const columns = fieldsSheet.getRow(1).values.slice(1);

      // Build an array of row records from the Fields sheet.
      const fieldRows = [];
      fieldsSheet.eachRow((row, rowIndex) => {
        if (rowIndex === 1) return;
        const record = {};
        row.values.slice(1).forEach((cell, index) => {
          record[columns[index]] = cell || null;
        });
        const cropType = record["Crop Type"];
        const acres = record["Acres"];
        const farm = record["Farm"];
        const field = record["Field"];
        const cropYear = record["Crop Year"];
        const allRequiredEmpty =
          !cropType && !acres && !farm && !field && !cropYear;
        if (!allRequiredEmpty) {
          fieldRows.push(record);
        }
      });

      const allYears = fieldRows
        .map((r) => parseInt(r["Crop Year"], 10))
        .filter((val) => !isNaN(val));
      this.maxFieldYear = allYears.length > 0 ? Math.max(...allYears) : null;

      // Partition rows into those that already have a Crop ID and those that do not.
      const existingCropRows = fieldRows.filter((row) => row["Crop ID"]);
      const newCropRows = fieldRows.filter((row) => !row["Crop ID"]);

      // === Process Existing Crops ===
      for (const row of existingCropRows) {
        const cropId = row["Crop ID"];
        const existingCrop = this.crops.find((c) => c.id == cropId);
        if (existingCrop) {
          if (
            existingCrop.crop_type !== row["Crop Type"] ||
            existingCrop.harvest_year !== row["Crop Year"]
          ) {
            const updateData = {
              ...existingCrop,
              crop_type: row["Crop Type"],
              harvest_range_end: this.formatHarvestRangeEnd(row["Crop Year"]),
            };

            console.log(`Updating crop ${cropId} with data:`, updateData);
            await this.$store.dispatch("updateCrop", updateData);
            if (
              this.orderId &&
              this.maxFieldYear &&
              parseInt(row["Crop Year"], 10) === this.maxFieldYear
            ) {
              const allocationToSubmit = {
                crop_order: Number(this.orderId),
                crop: cropId,
                acres: row["Acres"] || 0,
              };
              await this.$store.dispatch(
                "createGrowerAllocation",
                allocationToSubmit,
              );
            }
          }
        } else {
          console.warn(
            `Crop with ID ${cropId} not found in store. Skipping update.`,
          );
        }
      }

      // === Process New Crops (Rows Without a Crop ID) ===
      const newCropGroups = {};
      newCropRows.forEach((row) => {
        const farm = row["Farm"];
        const field = row["Field"];
        const key = `${farm}::${field}`;
        if (!newCropGroups[key]) {
          newCropGroups[key] = [];
        }
        newCropGroups[key].push(row);
      });

      // Process each group (i.e. each unique field)
      for (const key in newCropGroups) {
        const groupRows = newCropGroups[key];

        // Sort rows by crop year in descending order so that the most recent crop is first.
        groupRows.sort(
          (a, b) => parseInt(b["Crop Year"], 10) - parseInt(a["Crop Year"], 10),
        );
        const mostRecentRow = groupRows[0];

        const farmName = mostRecentRow["Farm"];
        const fieldName = mostRecentRow["Field"];
        const cropYear = mostRecentRow["Crop Year"];
        const cropType = mostRecentRow["Crop Type"];
        const acres = mostRecentRow["Acres"];

        let matchedField = this.$store.getters.getFieldByName(
          fieldName,
          farmName,
        );
        let createdNewField = false;

        if (matchedField) {
          console.log(
            `Field "${fieldName}" found. Updating acres to ${acres}.`,
          );
          if (parseInt(cropYear, 10) === this.maxFieldYear) {
            await this.$store.dispatch("updateField", {
              ...matchedField,
              geometry: matchedField.geometry,
              properties: {
                ...matchedField.properties,
                acres: acres,
              },
            });
          }
        } else {
          console.log(
            `Field "${fieldName}" not found. Creating new field with initial crop.`,
          );
          const newFieldData = {
            geometry: null,
            properties: {
              name: fieldName,
              farm: farmName,
              acres: acres,
              current_crop: {
                active: true,
                harvest_range_end: this.formatHarvestRangeEnd(cropYear),
                crop_type: cropType,
              },
            },
          };
          matchedField = await this.$store.dispatch(
            "createField",
            newFieldData,
          );
          createdNewField = true;
        }

        // Allocate the most recent crop if it is the current crop (i.e., cropYear equals maxFieldYear)
        if (
          this.orderId &&
          this.maxFieldYear &&
          parseInt(cropYear, 10) === this.maxFieldYear
        ) {
          if (createdNewField) {
            // Use the current_crop from the newly created field
            const cropId = matchedField?.properties?.current_crop?.id;
            if (cropId) {
              const allocationToSubmit = {
                crop_order: Number(this.orderId),
                crop: cropId,
                acres: acres || 0,
              };
              await this.$store.dispatch(
                "createGrowerAllocation",
                allocationToSubmit,
              );
            }
          } else {
            // Create the most recent crop and allocate it
            const cropData = {
              fieldId: matchedField.id,
              crop: {
                active: true,
                crop_type: cropType,
                harvest_range_end: this.formatHarvestRangeEnd(cropYear),
                details: {},
              },
            };
            const mostRecentCrop = await this.$store.dispatch(
              "createCrop",
              cropData,
            );
            const allocationToSubmit = {
              crop_order: Number(this.orderId),
              crop: mostRecentCrop.id,
              acres: acres || 0,
            };
            await this.$store.dispatch(
              "createGrowerAllocation",
              allocationToSubmit,
            );
          }
        }

        // Process remaining rows (skip the most recent row)
        const remainingRows = groupRows.slice(1);
        for (const row of remainingRows) {
          const cropData = {
            fieldId: matchedField.id,
            active: false,
            crop_type: row["Crop Type"],
            harvest_range_end: this.formatHarvestRangeEnd(row["Crop Year"]),
            details: {},
          };
          await this.$store.dispatch("createCrop", cropData);
        }
      }
    },
    async addDataValidation(worksheet, requiredAttributes, maxSheetRows, attr) {
      const columnIndex = requiredAttributes.indexOf(attr.label) + 1;
      if (columnIndex <= 0) {
        console.warn(`Column not found for attribute: ${attr.label}`);
        return;
      }
      const column = worksheet.getColumn(columnIndex);
      const columnLetter = column.letter;
      const range = `${columnLetter}2:${columnLetter}${maxSheetRows}`;

      if (Array.isArray(attr.selectOptions)) {
        const options = attr.selectOptions.map((opt) =>
          typeof opt === "object" ? opt.value || opt : opt,
        );
        if (options.length > 0) {
          worksheet.dataValidations.add(range, {
            type: "list",
            allowBlank: true,
            formulae: [`"${options.join(",")}"`],
            showErrorMessage: true,
            errorTitle: "Invalid Input",
            error: "Please select a value from the dropdown.",
          });
        }
      }

      if (attr.type === "boolean") {
        worksheet.dataValidations.add(range, {
          type: "list",
          allowBlank: true,
          formulae: ['"TRUE,FALSE"'],
          showErrorMessage: true,
          errorTitle: "Invalid Input",
          error: "Please select TRUE or FALSE.",
        });
      }

      if (attr.type === "date") {
        worksheet.dataValidations.add(range, {
          type: "date",
          allowBlank: true,
          operator: "greaterThan",
          formulae: [new Date(2000, 0, 1).toISOString().split("T")[0]],
          showErrorMessage: true,
          errorTitle: "Invalid Date",
          error: "Please enter a valid date in YYYY-MM-DD format.",
        });
      }

      if (attr.type === "number") {
        worksheet.dataValidations.add(range, {
          type: "decimal",
          allowBlank: true,
          operator: "greaterThanOrEqual",
          formulae: [0],
          showErrorMessage: true,
          errorTitle: "Invalid Number",
          error: "Please enter a valid number.",
        });
      }
    },
    async _processRecordSheets(workbook) {
      for (const sheet of workbook.worksheets) {
        const sheetName = sheet.name;
        if (
          sheetName === "Fields" ||
          sheetName === "Instructions" ||
          sheetName === "validations"
        ) {
          continue;
        }

        const columns = sheet.getRow(1).values.slice(1);
        const records = [];
        sheet.eachRow((row, rowIndex) => {
          if (rowIndex === 1) return;
          const record = {};
          row.values.slice(1).forEach((cell, index) => {
            let actualValue = "";
            if (cell && typeof cell === "object") {
              if (cell instanceof Date) {
                // Format date as YYYY-MM-DD
                actualValue = cell.toISOString().split("T")[0];
              } else if (cell.formula) {
                actualValue = cell.result || null;
              } else if (cell.richText) {
                actualValue =
                  cell.richText.map((rt) => rt.text).join("") || null;
              } else {
                actualValue = cell.text || cell.value || null;
              }
            } else {
              actualValue = cell || null;
            }
            record[columns[index]] = actualValue;
          });

          records.push(record);
        });

        if (
          sheetName === "Fertilizer Application" ||
          sheetName === "Input Application" ||
          sheetName === "Planting"
        ) {
          await this._handleFertilizerAndInputRecords(sheetName, records);
        } else {
          await this._handleGenericRecords(sheetName, records);
        }
      }
    },
    async _handleFertilizerAndInputRecords(sheetName, records) {
      const groupedRecords = records.reduce((groups, record) => {
        if (!record["Application Date"]) {
          // console.log(`Skipping row due to missing Application Date:`, record);
          return groups;
        }

        let cropId = record["Crop ID"];
        if (!cropId) {
          const matchedField = this.$store.getters.getFieldByName(
            record["Field"],
            record["Farm"],
          );
          if (!matchedField) {
            console.log(
              `Skipping row due to missing Crop ID and no matching field found (Field: ${record["Field"]}, Farm: ${record["Farm"]})`,
            );
            return groups;
          }
          let matchedCrop = this.crops.find(
            (c) =>
              c.field_data?.id === matchedField.id &&
              c.harvest_year == record["Crop Year"],
          );
          if (!matchedCrop) {
            // Search through the store's crops for a matching crop
            // using matchedField, record["Crop Type"], and comparing
            // crop.harvest_range_end === this.formatHarvestRangeEnd(record["Crop Year"])
            const alternativeCrop = this.$store.getters
              .getCropsByFieldId(matchedField.id)
              .find(
                (crop) =>
                  crop.crop_type === record["Crop Type"] &&
                  crop.harvest_range_end ===
                    this.formatHarvestRangeEnd(record["Crop Year"]),
              );
            if (alternativeCrop) matchedCrop = alternativeCrop;
          }
          if (!matchedCrop) {
            console.log(
              `Skipping row due to missing Crop ID and no matching crop found (Field: ${record["Field"]}, Farm: ${record["Farm"]}, Crop Year: ${record["Crop Year"]})`,
            );
            return groups;
          } else {
            cropId = matchedCrop.id;
          }
        }

        const key = `${cropId}-${record["Application Date"]}-${record["Application Method"]}`;
        if (!groups[key]) groups[key] = [];
        groups[key].push(record);
        return groups;
      }, {});

      this.modalDescription = "Saving records...";
      for (const [key, groupedRows] of Object.entries(groupedRecords)) {
        const [cropId, applicationDate] = key.split("-");

        const mixProducts = groupedRows.map((row) => {
          const mixProduct = {};
          const mixProductAttributes = this.getMixProductAttributes(sheetName);
          mixProductAttributes.forEach((attr) => {
            mixProduct[attr.value] = row[attr.label] || "";
          });
          return mixProduct;
        });

        const existingRecord = groupedRows.find((row) => row["Record ID"]);
        const recordId = existingRecord ? existingRecord["Record ID"] : null;

        const payload = {
          application_date: applicationDate,
          mix_products: mixProducts,
        };

        const recordTypeDefinition = RecordTypeDefinitions.find(
          (type) => type.category === sheetName,
        );
        if (recordTypeDefinition) {
          recordTypeDefinition.data.payload
            .filter((attr) => attr.value !== "mix_products")
            .forEach((attr) => {
              const attrValue = groupedRows[0][attr.label];
              if (attrValue !== undefined) payload[attr.value] = attrValue;
            });
        }

        const recordData = {
          category: sheetName,
          crop: cropId,
          result: null,
          payload,
          completed_at: null,
        };
        recordData.result = this.recordResult(recordTypeDefinition, recordData);
        if (recordId) {
          console.log(`Updating record ${recordId} for crop ${cropId}`);
          await this.$store.dispatch("updateRecord", {
            recordId,
            record: recordData,
          });
        } else {
          console.log(`Creating new record for crop ${cropId}`);
          await this.$store.dispatch("createRecord", recordData);
        }
      }
    },
    async _handleGenericRecords(sheetName, records) {
      for (const record of records) {
        const payload = {};
        const recordTypeDefinition = RecordTypeDefinitions.find(
          (type) => type.category === sheetName,
        );

        if (recordTypeDefinition) {
          recordTypeDefinition.data.payload.forEach((attr) => {
            if (record[attr.label] !== undefined) {
              payload[attr.value] = record[attr.label];
            }
          });
        }

        const hasPayloadData = Object.values(payload).some(
          (value) => value !== "" && value !== undefined && value !== null,
        );
        if (!record["Record ID"] && !hasPayloadData) {
          console.log(
            `Skipping record for crop ${record["Crop ID"]}: Empty payload and no Record ID.`,
          );
          continue;
        }

        let cropId = record["Crop ID"];
        if (!cropId) {
          const matchedField = this.$store.getters.getFieldByName(
            record["Field"],
            record["Farm"],
          );
          if (!matchedField) {
            console.log(
              `Skipping row: no matching field found (Field: ${record["Field"]}, Farm: ${record["Farm"]})`,
            );
            continue;
          }
          let matchedCrop = this.crops.find(
            (c) =>
              c.field_data?.id === matchedField.id &&
              c.harvest_year == record["Crop Year"],
          );
          if (!matchedCrop) {
            // Try to search for a matching crop using matchedField,
            // comparing formatted record["Crop Type"] and matching the harvest_range_end
            const alternativeCrop = this.$store.getters
              .getCropsByFieldId(matchedField.id)
              .find(
                (crop) =>
                  crop.crop_type === record["Crop Type"] &&
                  crop.harvest_range_end ===
                    this.formatHarvestRangeEnd(record["Crop Year"]),
              );
            if (alternativeCrop) matchedCrop = alternativeCrop;
          }
          if (!matchedCrop) {
            console.log(
              `Skipping row due to missing Crop ID and no matching crop found (Field: ${record["Field"]}, Farm: ${record["Farm"]}, Crop Year: ${record["Crop Year"]})`,
            );
            continue;
          } else {
            cropId = matchedCrop.id;
          }
        }
        const recordData = {
          category: sheetName,
          crop: cropId,
          result: null,
          payload,
          completed_at: null,
        };
        recordData.result = this.recordResult(recordTypeDefinition, recordData);

        if (record["Record ID"]) {
          console.log(
            `Updating record ${record["Record ID"]} for crop ${cropId}`,
          );
          await this.$store.dispatch("updateRecord", {
            recordId: record["Record ID"],
            record: recordData,
          });
        } else {
          console.log(`Creating new record for crop ${cropId}`);
          await this.$store.dispatch("createRecord", recordData);
        }
      }
    },
    recordResult,
  },
  computed: {
    dataIsLoaded() {
      if (
        this.orderId &&
        this.$store.getters.getGrowerCropOrderById(this.orderId) &&
        this.$store.getters.getGrowerAllocationsByOrderId(this.orderId)
      ) {
        return true;
      } else {
        return false;
      }
    },
    orderId() {
      if (this.orderIdOverride) {
        return this.orderIdOverride;
      } else if (this.$route.params.orderId) {
        return this.$route.params.orderId;
      } else {
        return null;
      }
    },
    currentOrder() {
      return this.$store.getters.getGrowerCropOrderById(this.orderId);
    },
    currentOffer() {
      return this.$store.getters.getGrowerMarketOfferById(
        this.currentOrder?.offer,
      );
    },
    allocations() {
      if (!this.dataIsLoaded) {
        return null;
      }
      // use this.orderId to fetch the allocations
      // for each allocation, attach the crop_data and field_data
      let allocations = this.$store.getters.getGrowerAllocationsByOrderId(
        this.orderId,
      );
      if (!this.currentOffer?.premiums) {
        return allocations;
      }
      return allocations.map((allo) => {
        let alloFieldId = this.$store.getters.getAnyCropById(allo.crop).field;
        return {
          ...allo,
          fieldId: alloFieldId,
          // field_data: this.$store.getters.getFieldById(alloFieldId),
        };
      });
    },
    crops() {
      if (!this.dataIsLoaded || !this.allocations) {
        return [];
      }
      let fieldIds = [];
      // loop through allocations, use the getFieldIdByCropId getter to get the field id, and push it to fieldIds
      for (let allo of this.allocations) {
        fieldIds.push(allo.fieldId);
      }
      // loop through fieldIds, use the getCropsByFieldId getter to get the crops
      // the arguments for the getter are the field id, the current year - this.currentOffer.additional_prior_year_records, and the current year
      let crops = [];
      for (let fieldId of fieldIds) {
        // get the crops for the fieldId, give each crop field_data via the getFieldByCropId getter, and push them to the crops array as a single array
        for (let crop of this.$store.getters.getCropsByFieldId(fieldId)) {
          crops.push({
            ...crop,
            harvest_year: new Date(crop.harvest_range_end).getFullYear(),
            field_data: this.$store.getters.getFieldById(fieldId),
            records: this.$store.getters.getRecordsByCrop(crop.id),
            relatedAllocationId: this.allocations.find(
              (allo) => allo?.fieldId == crop?.field,
            )?.id,
          });
        }
      }
      return crops;
    },
    premiums() {
      if (this.currentOrder.premium == null) {
        return this.currentOffer?.premiums;
      } else {
        return this.currentOffer?.premiums.filter(
          (premium) => premium.id == this.currentOrder.premium,
        );
      }
    },
    cropOptions() {
      let options = [];
      for (let crop of cropList) {
        options.push(crop.name);
      }
      return options;
    },
    validation_records() {
      if (!this.dataIsLoaded) {
        return [];
      }
      let records = [];
      for (let premium of this.premiums) {
        for (let record of premium.validation_records) {
          records.push({ ...record, premium_id: premium.id });
        }
      }
      return records;
    },
    cropsForExport() {
      if (!this.dataIsLoaded) {
        return [];
      }
      return this.crops.map((crop) => {
        return {
          crop_id: crop.id,
          farm: crop?.field_data?.properties?.farm,
          field: crop?.field_data?.properties?.name,
          acres: crop?.field_data?.properties?.acres,
          crop_type: crop.crop_type,
          crop_year: crop.harvest_year,
          records: crop.records,
        };
      });
    },
    // fields() {
    //   return this.$store.getters.getAllFields;
    // },
  },
  watch: {
    // watch properties if needed
  },
};
</script>
