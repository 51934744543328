<template>
  <div class="bg-grey-lighter flex min-h-screen flex-col">
    <error-notification />
    <div
      class="container mx-auto flex max-w-3xl flex-1 flex-col items-center justify-center px-2"
    >
      <img class="h-12 w-auto" :src="affiliate?.logo_url" alt="Parcel Logo" />
      <div class="w-full rounded bg-white px-6 py-8 text-black shadow-md">
        <h1 class="mb-8 text-center text-3xl">
          Joining <span class="underline">{{ affiliate?.name }}</span> on Parcel
        </h1>
        <p class="pb-6 text-center">
          Set up your grower account by filling out the information below.
        </p>
        <text-input
          inputTitle="First and Last Name"
          v-model="fullname"
          placeholder="Your Name"
          required
        />
        <p class="px-2">&nbsp;</p>
        <text-input
          v-if="currentUser.email"
          inputTitle="Email"
          :display-only="true"
          :display-only-value="currentUser.email"
          required
          help-text="This is the email address you will log in with."
        />
        <text-input
          v-else-if="!currentUser.email && currentUser.phone"
          inputTitle="Phone"
          :display-only="true"
          :display-only-value="currentUser.phone"
          required
          help-text="This is the phone number you will log in with."
        />
        <p class="px-2">&nbsp;</p>
        <text-input
          inputTitle="Farm / Business Name"
          v-model="company"
          placeholder="Farm Name"
          required
        />
        <p class="px-2">&nbsp;</p>
        <email-input
          inputTitle="Farm / Business Email"
          v-model="email"
          placeholder="example@email.com"
          @valid="emailValidate"
          required
          help-text="This is the email address we will use to contact your business for any notifications."
        />
        <p class="px-2">&nbsp;</p>
        <button
          @click="createOrg"
          :disabled="!submitIsCorrect"
          class="hover:bg-green-dark my-1 w-full rounded py-3 text-center text-white focus:outline-none"
          :class="submitIsCorrect ? 'bg-green-400' : 'bg-gray-400'"
        >
          {{
            referrer === "GRP"
              ? "Next Step: Add Partners"
              : "Create Organization"
          }}
        </button>
        <div class="text-grey-dark mt-4 text-center text-sm">
          By signing up, you agree to the
          <a
            class="border-grey-dark text-grey-dark border-b no-underline"
            href="https://parcel.ag/tos"
            target="_blank"
          >
            Terms of Service
          </a>
          and
          <a
            class="border-grey-dark text-grey-dark border-b no-underline"
            href="https://parcel.ag/tos/privacy"
            target="_blank"
          >
            Privacy Policy
          </a>
        </div>
      </div>
      <div class="text-grey-dark mt-6">
        Logged in to the wrong account?
        <button
          class="border-blue border-b text-twilight-800 no-underline"
          @click="logout"
        >
          Log out</button
        >.
      </div>
      <div class="text-grey-dark mt-6">
        <a
          :href="homeURL"
          class="mt-6 self-center pt-4 text-sm lowercase text-twilight-600"
        >
          Return to homepage
        </a>
      </div>
    </div>
    <modal
      :show="showLoading"
      title="Getting things ready, setting up your account..."
    >
      <div class="pt-8">
        <div class="pl-32">
          <loading-spinner />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import EmailInput from "@/components/form/EmailInput.vue";
import TextInput from "@/components/form/TextInput.vue";
import ErrorNotification from "@/components/notifications/ErrorNotification.vue";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import Modal from "@/components/modals/PopupModal.vue";
import api from "@/api/parcel/api";

export default {
  components: {
    EmailInput,
    TextInput,
    ErrorNotification,
    LoadingSpinner,
    Modal,
  },
  data() {
    let referrer = this.$store.state.signupReferrer.referrer;
    let homeURL = "https://parcel.ag";
    if (process.env.VUE_APP_ENV === "development") {
      homeURL = "https://dev.parcel.ag";
    }
    if (process.env.VUE_APP_ENV === "sandbox") {
      homeURL = "https://sandbox.parcel.ag";
    }
    return {
      referrer,
      // accountType is no longer needed for multiple options
      company: "",
      fullname: "",
      email: "",
      emailValid: true,
      // Remove phone related properties
      creatingOrg: false,
      homeURL,
      errorArray: [],
      // Default organization type is grower
      orgType: "grower",
      affiliates: [],
    };
  },
  computed: {
    affiliate() {
      if (this.affiliates.length === 0) {
        return null;
      } else if (this.$route.params.slug) {
        return this.affiliates.find(
          (affiliate) => affiliate.slug === this.$route.params.slug,
        );
      } else {
        return null;
      }
    },
    submitIsCorrect() {
      return (
        this.company.length > 0 &&
        this.fullname.length > 0 &&
        this.emailValid &&
        this.email.length > 0
      );
    },
    showLoading() {
      return this.creatingOrg;
    },
    currentUser() {
      return this.$store.getters.getCurrentUser;
    },
    adminUser() {
      let user = this.$store.getters.getCurrentUser;
      user.name = this.fullname;
      user.admin = true;
      user.company = this.company;
      return user;
    },
  },
  methods: {
    emailValidate(valid) {
      this.emailValid = valid;
    },
    async logout() {
      await this.$store.dispatch("logout");
      this.$router.push({ path: "/login" });
    },
    async createOrg() {
      if (!this.submitIsCorrect) {
        return;
      }
      this.creatingOrg = true;
      let loginRedirectName = "";
      let feature_flags = [
        "gp-recs",
        "gp-chat",
        "gp-buy",
        "gp-hauling",
        "gp-sell",
        "gp-help",
        "gp-carbon",
      ];
      // REMOVE THIS LATER WHEN MARKETPLACES ARE ENABLED
      if (this.referrer === "GRP") {
        // Remove the gp-buy flag for GRP referrers
        feature_flags = feature_flags.filter((item) => item !== "gp-buy");
        loginRedirectName = "assign-partners";
      } else {
        loginRedirectName = "grower-portal-home";
      }
      let application_flag = "operations";
      let newOrg = {
        name: this.company,
        contact_email: this.email,
        feature_flags,
        application_flag,
        referrer: this.$route.params.slug,
      };
      // Growers always get these flags
      newOrg.assets_flag = true;
      newOrg.operations_flag = true;
      await this.$store.dispatch("createOrg", newOrg);
      this.$store.dispatch("unsetReferrer");
      await this.$store.dispatch("updateProfile", this.adminUser);
      if (this.$route.query.token) {
        await api.auth.respondInvite(this.$route.query.token, {
          accepted: true,
        });
      }
      await this.$store.dispatch("preloadAppData");
      const { redirect, params } = this.$route.query;
      if (redirect) {
        this.$router.push({
          name: redirect,
          params: params ? JSON.parse(params) : {},
        });
      } else {
        this.$router.push({ name: loginRedirectName });
      }
    },
  },
  watch: {
    currentUser: {
      handler(newVal) {
        if (newVal && newVal.email) {
          this.email = newVal.email;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    let response = await api.orgs.readPublicAffiliateDetails();
    this.affiliates = response.affiliates;
  },
};
</script>
