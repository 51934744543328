import client from "@/api/parcel/api.js";

export default {
  state() {
    return {
      invites: [],
    };
  },
  mutations: {
    SET_INVITES(state, data) {
      state.invites = data;
    },
    RESET_INVITES(state) {
      state.invites = [];
    },
    ADD_INVITE(state, invite) {
      state.invites.push(invite);
    },
    REMOVE_INVITE(state, invite) {
      state.invites = state.invites.filter((a) => a.id !== invite);
    },
  },
  actions: {
    async getInvites({ commit }) {
      const data = await client.invites.read();
      if (data) {
        commit("SET_INVITES", data.invites);
      }
    },
    async createInvite({ commit }, invite) {
      const data = await client.invites.create(null, invite);
      if (data) {
        commit("ADD_INVITE", invite);
        return data;
      }
    },
    async deleteInvite({ commit }, invite) {
      const data = await client.invites.delete(invite.id);
      if (data) {
        commit("REMOVE_INVITE", invite);
        return data;
      }
    },
  },
  getters: {
    getInviteById: (state) => (inviteId) => {
      return state.invites.find((invite) => invite.id === inviteId);
    },
    getAllInvites: (state) => {
      return state.invites;
    },
  },
};
