<template>
  <page-card headingTitle="Invite Growers">
    <template #buttonArea>
      <base-button
        buttonText="Back"
        :redButton="true"
        @buttonClick="goToParentPage"
      />
    </template>
    <template #cardDetails>
      <div
        class="mt-2 flex justify-between text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
      >
        Single Grower Invite
        <base-button
          buttonText="Send Invite"
          :marginLeft="false"
          :disabled="!inviteName || !inviteEmail"
          @buttonClick="sendSingleInvite"
        />
      </div>
      <text-input
        inputTitle="Name"
        :wideItem="true"
        :required="true"
        v-model="inviteName"
      />
      <text-input
        inputTitle="Email Address"
        :wideItem="true"
        :required="true"
        v-model="inviteEmail"
      />
      <div
        class="mt-2 flex justify-between text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
      >
        Bulk Grower Invite
      </div>
      <!-- Pass growerAttributes to CsvImport -->
      <grower-invite-csv-import @submitInvites="sendInvites" />
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import TextInput from "@/components/form/TextInput.vue";
import GrowerInviteCsvImport from "@/layouts/buyer-portal/bp-affiliate/growers/GrowerInviteCsvImport.vue";
import { useToast } from "vue-toastification";

export default {
  components: {
    PageCard,
    BaseButton,
    TextInput,
    GrowerInviteCsvImport,
  },
  data() {
    return {
      toast: useToast(),
      inviteName: "",
      inviteEmail: "",
    };
  },
  methods: {
    async sendInvites(e) {
      this.toast.info("Sending bulk invites...");
      try {
        console.log("Sending bulk invites for:", e);
        // Here you could integrate your API call or further processing.
        for (let invite of e) {
          await this.$store.dispatch("createInvite", {
            invite_name: invite.name,
            invite_email: invite.email,
          });
        }
        this.toast.success("Bulk invites sent successfully!");
      } catch (error) {
        this.toast.error("Error sending bulk invites.");
      }
    },
    async sendSingleInvite() {
      await this.$store.dispatch("createInvite", {
        invite_name: this.inviteName,
        invite_email: this.inviteEmail,
      });
      this.toast.success("Invite sent successfully!");
      this.inviteEmail = "";
      this.inviteName = "";
    },
    goToParentPage() {
      this.$router.go(-1);
    },
  },
};
</script>
