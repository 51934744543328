import SettingsAll from "@/pages/vendor-portal/settings/SettingsAll";
import SettingsSingleUser from "@/pages/vendor-portal/settings/SettingsSingleUser";
import SettingsSingleExternalOrg from "@/pages/vendor-portal/settings/SettingsSingleExternalOrg";
import SharedOrgs from "@/pages/auth/SharedOrgs.vue";
import VendorPortalHelp from "@/pages/vendor-portal/VendorPortalHelp.vue";
// App Revenue
import RevenueTerritoriesAll from "@/pages/vendor-portal/territories/RevenueTerritoriesAll";
import RevenueTerritoriesForm from "@/pages/vendor-portal/territories/RevenueTerritoriesForm";

//Vendor
// App Core
import VendorPortalFrame from "@/pages/vendor-portal/VendorPortalFrame";
import VendorPortalHome from "@/pages/vendor-portal/VendorPortalHome";
// import VendorPortalSubscription from "@/pages/vendor-portal/VendorPortalSubscription";
import VendorCompanyListing from "@/pages/vendor-portal/listings/VendorCompanyListing.vue";
import RevenueListingsAll from "@/pages/vendor-portal/listings/RevenueListingsAll";
import HaulingReservationsAll from "@/pages/vendor-portal/hauling/HaulingReservationsAll";
// import VendorAddLob from "@/pages/vendor-portal/listings/VendorAddLob.vue";
import HaulingDashboard from "@/pages/vendor-portal/hauling/HaulingDashboard";

// Operations
import AgronomyDashboard from "@/pages/vendor-portal/agronomy/AgronomyDashboard.vue";

import VendorActivities from "@/pages/vendor-portal/operations/VendorActivities.vue";
import VendorNewActivity from "@/pages/vendor-portal/operations/VendorNewActivity.vue";
import VendorEditActivity from "@/pages/vendor-portal/operations/VendorEditActivity.vue";
import ChatThread from "@/pages/vendor-portal/support/ChatThread.vue";

import CustomersTable from "@/pages/vendor-portal/customers/CustomersTable.vue";
import CustomerForm from "@/pages/vendor-portal/customers/CustomerForm.vue";

import VendorInvoices from "@/pages/vendor-portal/invoices/VendorInvoices.vue";
import VendorInvoiceForm from "@/pages/vendor-portal/invoices/VendorInvoiceForm.vue";
import VendorInvoicesOnboarding from "@/pages/vendor-portal/invoices/VendorInvoicesOnboarding.vue";

import VendorProductsDashboard from "@/pages/vendor-portal/products/VendorProductsDashboard.vue";
import VendorProductsAll from "@/pages/vendor-portal/products/VendorProductsAll.vue";
import ImportProducts from "@/pages/vendor-portal/products/ImportProducts.vue";
import NewProduct from "@/pages/vendor-portal/products/NewProduct.vue";
import EditProduct from "@/pages/vendor-portal/products/EditProduct.vue";
import VendorQuotesAll from "@/pages/vendor-portal/quotes/VendorQuotesAll.vue";
import VendorQuoteEdit from "@/pages/vendor-portal/quotes/VendorQuoteEdit.vue";
import VendorOrdersAll from "@/pages/vendor-portal/orders/VendorOrdersAll.vue";
import VendorOrderEdit from "@/pages/vendor-portal/orders/VendorOrderEdit.vue";
import VendorInputOrderEdit from "@/pages/vendor-portal/orders/VendorInputOrderEdit.vue";
import AllRecs from "@/pages/vendor-portal/recommendations/AllRecs.vue";
import NewRec from "@/pages/vendor-portal/recommendations/NewRec.vue";
import ViewRec from "@/pages/vendor-portal/recommendations/ViewRec.vue";
import ViewOpp from "@/pages/vendor-portal/recommendations/ViewOpp.vue";

import Carbon from "@/pages/vendor-portal/carbon/CarbonScenarios.vue";
import NewCarbonScenario from "@/pages/vendor-portal/carbon/NewCarbonScenario.vue";
import EditCarbonScenario from "@/pages/vendor-portal/carbon/EditCarbonScenario.vue";
import ViewCarbonSharedScenario from "@/pages/vendor-portal/carbon/ViewCarbonSharedScenario.vue";

export default [
  // Vendor pages
  {
    path: "/vendor",
    component: VendorPortalFrame,
    name: "vendor",
    redirect: "/vendor/home",
    meta: {
      requiresAuth: true,
      requiredOrgFlag: "revenue_flag",
      //storeGetActions: ["getVendorPortalDataOnAppStart"],
    },
    children: [
      {
        path: "home",
        component: VendorPortalHome,
        name: "vendor-portal-home",
        //redirect: "/vendor/revenue/territories",
        meta: {
          auth: true,
          title: "Vendor Portal | Parcel",
          displayTitle: "Home",
        },
      },
      {
        path: "customers",
        component: CustomersTable,
        name: "vendor-customers",
        meta: {
          auth: true,
          title: "Customers | Parcel",
          displayTitle: "Customers",
        },
      },
      {
        path: "customers/new",
        component: CustomerForm,
        name: "vendor-customer-new",
        meta: {
          auth: true,
          title: "New Customer | Parcel",
          displayTitle: "New Customer",
        },
      },
      {
        path: "customers/:customerId",
        component: CustomerForm,
        name: "vendor-customer-manage",
        meta: {
          auth: true,
          title: "Manage Customer | Parcel",
          displayTitle: "Manage Customer",
        },
      },
      {
        path: "settings",
        component: SettingsAll,
        name: "vendor-settings",
        meta: {
          auth: true,
          title: "Settings | Parcel",
          displayTitle: "Settings",
        },
      },
      {
        path: "settings/user",
        component: SettingsSingleUser,
        name: "vendor-settings-new-user",
        meta: {
          auth: true,
          admin: true,
          title: "Add User | Parcel",
          displayTitle: "Add User",
        },
      },
      {
        path: "settings/user/:userId",
        component: SettingsSingleUser,
        name: "vendor-single-user",
        meta: {
          auth: true,
          admin: true,
          title: "View User | Parcel",
          displayTitle: "View User",
        },
      },
      {
        path: "settings/external-org",
        component: SettingsSingleExternalOrg,
        name: "vendor-settings-new-external-org",
        meta: {
          auth: true,
          title: "Manage External Orgs | Parcel",
          displayTitle: "Manage External Orgs",
        },
      },
      {
        path: "switch-org",
        component: SharedOrgs,
        name: "vendor-switch-org",
        meta: {
          auth: true,
          title: "Switch Org | Parcel",
        },
      },
      {
        path: "carbon",
        name: "vendor-carbon",
        component: Carbon,
        meta: {
          auth: true,
          title: "Carbon | Parcel",
          displayTitle: "Carbon",
          storeGetActions: ["getScenarios", "getSharedScenarios"],
        },
      },
      {
        path: "carbon/scenario/new",
        name: "vendor-carbon-new-scenario",
        component: NewCarbonScenario,
        meta: {
          auth: true,
          title: "New Scenario | Parcel",
          displayTitle: "New Scenario",
        },
      },
      {
        path: "carbon/scenario/:scenarioId",
        name: "vendor-carbon-single-scenario",
        component: EditCarbonScenario,
        meta: {
          auth: true,
          title: "Edit Scenario | Parcel",
          displayTitle: "Carbon Intensity",
        },
      },
      {
        path: "carbon/shared/:sharedScenarioId",
        name: "vendor-carbon-single-shared-scenario",
        component: ViewCarbonSharedScenario,
        meta: {
          auth: true,
          title: "View Shared Scenario | Parcel",
          displayTitle: "Carbon Intensity",
        },
      },
      // {
      //   path: "subscription",
      //   component: VendorPortalSubscription,
      //   name: "vendor-subscription",
      //   meta: {
      //     auth: true,
      //     title: "Subscription | Parcel",
      //     displayTitle: "Subscription",
      //   },
      // },
      // {
      //   path: "map",
      //   name: "vendor-map",
      //   component: GrowerMap,
      //   meta: {
      //     auth: true,
      //     title: "Map | Parcel",
      //     displayTitle: "Map",
      //   },
      // },
      // {
      //   path: "equipment",
      //   name: "vendor-equipment",
      //   component: Equipment,
      //   meta: {
      //     auth: true,
      //     title: "My Equipment | Parcel",
      //     displayTitle: "My Equipment",
      //   },
      // },
      {
        path: "company-listing",
        name: "vendor-company-listing",
        component: VendorCompanyListing,
        meta: {
          auth: true,
          title: "Company Listing | Parcel",
          displayTitle: "Company Listing",
        },
      },
      {
        path: "invoices",
        name: "vendor-invoices",
        component: VendorInvoices,
        meta: {
          auth: true,
          title: "Invoices | Parcel",
          displayTitle: "Invoices",
          storeGetActions: ["getVendorInvoices"],
        },
      },
      {
        path: "invoices/onboarding",
        name: "invoices-onboarding",
        component: VendorInvoicesOnboarding,
        meta: {
          auth: true,
          title: "Invoices | Parcel",
          displayTitle: "Invoices",
        },
      },
      {
        path: "invoices/new",
        name: "vendor-invoices-new",
        component: VendorInvoiceForm,
        meta: {
          auth: true,
          title: "Invoices | Parcel",
          displayTitle: "New Invoice",
        },
      },
      {
        path: "invoices/:invoiceId",
        name: "vendor-invoices-edit",
        component: VendorInvoiceForm,
        meta: {
          auth: true,
          title: "Invoices | Parcel",
          displayTitle: "Edit Invoice",
        },
      },
      {
        path: "vendor-activities",
        name: "vendor-activities",
        component: VendorActivities,
        meta: {
          auth: true,
          title: "Activities | Parcel",
          displayTitle: "Work Orders",
          storeGetActions: [
            "getSharedTasks",
            "getSharedRecords",
            "getSharedFields",
            "getSharedEquipment",
          ],
        },
      },
      {
        path: "vendor-activity/new",
        name: "vendor-new-activity",
        component: VendorNewActivity,
        props: true,
        meta: {
          auth: true,
          title: "Add Task | Parcel",
          displayTitle: "Add Task",
        },
      },
      {
        path: "vendor-activity/:activityId",
        name: "vendor-single-activity",
        component: VendorEditActivity,
        meta: {
          auth: true,
          title: "Edit Task | Parcel",
          displayTitle: "View Task",
        },
      },
      {
        path: "revenue/agronomy/",
        name: "vendor-agronomy",
        component: AgronomyDashboard,
        meta: {
          auth: true,
          title: "Agronomic Consulting | Parcel",
          displayTitle: "Agronomy Dashboard",
        },
      },
      {
        path: "chat",
        name: "vendor-chat",
        component: ChatThread,
        meta: {
          auth: true,
          title: "Discussions | Parcel",
          displayTitle: "Chat",
          storeGetActions: ["getThreads", "getMessages"],
        },
      },
      {
        path: "chat/:threadId",
        name: "vendor-chat-thread",
        component: ChatThread,
        meta: {
          auth: true,
          title: "Discussions | Parcel",
          displayTitle: "Chat",
        },
      },
      {
        path: "revenue/listings",
        name: "vendor-listings",
        component: RevenueListingsAll,
        meta: {
          auth: true,
          title: "Listings | Parcel",
          displayTitle: "View Listings",
          storeGetActions: ["getGrowerListings"],
        },
      },
      {
        path: "revenue/hauling/",
        name: "vendor-hauling",
        component: HaulingDashboard,
        meta: {
          auth: true,
          title: "Hauling | Parcel",
          displayTitle: "Hauling Dashboard",
          storeGetActions: ["getVendorOrders"],
        },
      },
      {
        path: "revenue/hauling/reservations",
        name: "vendor-hauling-reservations",
        component: HaulingReservationsAll,
        meta: {
          auth: true,
          title: "Reservations | Parcel",
          displayTitle: "View Reservations",
          storeGetActions: [
            "getCustomers",
            "getSharedFields",
            "getGrowerLocations",
            "getVendorOrders",
          ],
          requiredFeatureFlag: "vp-hauling",
        },
      },
      {
        path: "revenue/territories",
        name: "vendor-revenue-territories",
        component: RevenueTerritoriesAll,
        meta: {
          auth: true,
          title: "Territories | Parcel",
          displayTitle: "View Territories",
        },
      },
      {
        path: "revenue/territories/new",
        name: "vendor-revenue-territories-new",
        component: RevenueTerritoriesForm,
        meta: {
          auth: true,
          title: "Territories | Parcel",
          displayTitle: "New Territory",
        },
      },
      {
        path: "revenue/territories/:id",
        name: "vendor-revenue-territories-edit",
        component: RevenueTerritoriesForm,
        meta: {
          auth: true,
          title: "Territories | Parcel",
          displayTitle: "Edit Territory",
        },
      },
      // {
      //   path: "add",
      //   component: VendorAddLob,
      //   name: "vendor-addlob",
      //   meta: {
      //     auth: true,
      //     title: "Lines of Business | Parcel",
      //     displayTitle: "Add Lines of Business",
      //     admin: true,
      //   },
      // },
      {
        path: "help",
        component: VendorPortalHelp,
        name: "vendor-portal-help",
        meta: {
          auth: true,
          title: "Help | Parcel",
          displayTitle: "Help",
        },
      },
      {
        path: "products",
        component: VendorProductsDashboard,
        name: "vendor-products-dashboard",
        redirect: { name: "vendor-products-all" },
        meta: {
          auth: true,
          title: "Products Dashboard | Parcel",
          displayTitle: "Products",
          storeGetActions: ["getVendorProducts"],
        },
      },
      {
        path: "products/all",
        component: VendorProductsAll,
        name: "vendor-products-all",
        meta: {
          auth: true,
          title: "Products | Parcel",
          displayTitle: "Products",
        },
      },
      {
        path: "products/new",
        name: "vendor-new-product",
        component: NewProduct,
        meta: {
          auth: true,
          title: "New Product | Parcel",
          displayTitle: "New Product",
        },
      },
      {
        path: "products/import",
        name: "vendor-import-products",
        component: ImportProducts,
        meta: {
          auth: true,
          title: "Import Products | Parcel",
          displayTitle: "Import Products",
        },
      },
      // {
      //   path: "products/:productId",
      //   name: "vendor-single-product",
      //   component: EditProduct,
      //   props: true,
      //   meta: {
      //     auth: true,
      //     title: "Edit Product | Parcel",
      //     displayTitle: "Edit Product",
      //   },
      // },
      {
        path: "products/:productId/category/:productCategory",
        name: "vendor-single-product",
        component: EditProduct,
        props: true,
        meta: {
          auth: true,
          title: "Edit Product | Parcel",
          displayTitle: "Edit Product",
        },
      },
      {
        path: "quotes",
        component: VendorQuotesAll,
        name: "vendor-quotes-all",
        meta: {
          auth: true,
          title: "Quotes | Parcel",
          displayTitle: "Quotes",
          storeGetActions: ["getVendorQuotes", "getVendorInputOrders"],
        },
      },
      {
        path: "quotes/:quoteId",
        component: VendorQuoteEdit,
        name: "vendor-single-quote",
        meta: {
          auth: true,
          title: "Quotes | Parcel",
          displayTitle: "Quotes",
        },
      },
      // Needs an update to accomodate to be built orders
      {
        path: "orders",
        component: VendorOrdersAll,
        name: "vendor-orders-all",
        meta: {
          auth: true,
          title: "Orders | Parcel",
          displayTitle: "Orders",
          storeGetActions: [
            "getVendorOrders",
            "getVendorInputOrders",
            "getVendorInvoices",
          ],
        },
      },
      {
        path: "orders/:orderId/category/product",
        component: VendorOrderEdit,
        name: "vendor-single-order",
        meta: {
          auth: true,
          title: "Orders | Parcel",
          displayTitle: "Orders",
        },
      },
      {
        path: "orders/:orderId/category/input",
        component: VendorInputOrderEdit,
        name: "vendor-single-input-order",
        meta: {
          auth: true,
          title: "Orders | Parcel",
          displayTitle: "Orders",
          storeGetActions: ["getVendorInputOrders"],
        },
      },
      {
        path: "recommendations",
        name: "vendor-recommendations",
        component: AllRecs,
        meta: {
          auth: true,
          title: "Recommendations | Parcel",
          displayTitle: "Recommendations",
          storeGetActions: [
            "getVendorRecommendations",
            "getVendorOpportunities",
          ],
        },
      },
      {
        path: "recommendation/order/:oppId",
        name: "vendor-single-opportunity",
        component: ViewOpp,
        meta: {
          auth: true,
          title: "View Recommendation | Parcel",
          displayTitle: "View Recommendation",
        },
      },
      {
        path: "recommendation/task/:recId",
        name: "vendor-single-recommendation",
        component: ViewRec,
        meta: {
          auth: true,
          title: "View Recommendation | Parcel",
          displayTitle: "View Recommendation",
        },
      },
      {
        path: "recommendation/new",
        name: "vendor-new-recommendation",
        component: NewRec,
        meta: {
          auth: true,
          title: "New Recommendation | Parcel",
          displayTitle: "New Recommendation",
        },
      },
    ],
  },
];
