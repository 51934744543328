import UserLogin from "@/pages/auth/UserLogin.vue";
import UserInviteLogin from "@/pages/auth/UserInviteLogin.vue";
import CatchInvite from "@/pages/auth/CatchInvite.vue";
import SignUp from "@/pages/auth/SignUp.vue";
import CreateOrg from "@/pages/auth/CreateOrg.vue";
import AssignPartners from "@/pages/auth/AssignPartners.vue";
import UserLogout from "@/pages/auth/UserLogout.vue";
// Start unnessary
import testForm from "@/components/form/testForm.vue";
import GrowerPortalFrame from "@/pages/grower-portal/GrowerPortalFrame";
import ErrorPage from "@/pages/auth/Error.vue";
import SignUpBranded from "@/pages/auth/SignUpBranded.vue";
import CreateOrgBranded from "@/pages/auth/CreateOrgBranded.vue";
import NoNavSignUp from "@/pages/auth/NoNavSignUp.vue";
import NoNavCreateOrg from "@/pages/auth/NoNavCreateOrg.vue";
// End unnessary

export default [
  {
    path: "/",
    name: "landing-page",
    meta: {
      forwardToAppIfLoggedIn: true,
      requiresAuth: true,
      title: "Parcel",
    },
  },
  {
    path: "/login",
    component: UserLogin,
    name: "login",
    meta: {
      forwardToAppIfLoggedIn: true,
      title: "Parcel",
    },
  },
  {
    path: "/login/invite/:token",
    component: UserInviteLogin,
    name: "login-invite",
    meta: {
      forwardToAppIfLoggedIn: true,
      title: "Parcel",
    },
  },
  {
    path: "/invite/:token",
    component: CatchInvite,
    name: "catch-invite",
    meta: {
      title: "Parcel",
    },
  },
  {
    path: "/signup",
    component: SignUp,
    name: "signup",
    meta: {
      forwardToAppIfLoggedIn: true,
      title: "Sign Up | Parcel",
    },
  },
  {
    path: "/signup/:slug",
    component: SignUpBranded,
    name: "signup-branded",
    meta: {
      forwardToAppIfLoggedIn: true,
      title: "Parcel",
    },
  },
  {
    path: "/create-org",
    component: CreateOrg,
    name: "create-org",
    meta: {
      requiresAuth: true,
      title: "Create Org | Parcel",
    },
  },
  {
    path: "/create-org/:slug",
    component: CreateOrgBranded,
    name: "create-org-branded",
    meta: {
      requiresAuth: true,
      title: "Create Org | Parcel",
    },
  },
  {
    path: "/nonav-signup",
    component: NoNavSignUp,
    name: "nonav-signup",
    meta: {
      forwardToAppIfLoggedIn: true,
      title: "Create Account | Parcel",
    },
  },
  {
    path: "/nonav-create-org",
    component: NoNavCreateOrg,
    name: "nonav-create-org",
    meta: {
      requiresAuth: true,
      title: "Create Org | Parcel",
    },
  },
  {
    path: "/assign-partners",
    component: AssignPartners,
    name: "assign-partners",
    meta: {
      requiresAuth: true,
      title: "Assign Partners | Parcel",
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: UserLogout,
    meta: {
      requiresAuth: true,
      title: "Parcel",
    },
  },
  {
    path: "/error",
    component: ErrorPage,
    name: "error",
    meta: {
      title: "Oops!",
    },
  },
  {
    path: "/test",
    component: GrowerPortalFrame,
    children: [
      {
        path: "form",
        component: testForm,
        name: "testform",
        meta: {},
      },
    ],
  },
];
