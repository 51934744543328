import client from "@/api/parcel/api.js";

export default {
  state() {
    return { locations: [] };
  },
  mutations: {
    SET_GROWER_INPUT_LOCATIONS(state, data) {
      state.locations = data;
    },
    RESET_GROWER_INPUT_LOCATIONS(state) {
      state.locations = [];
    },
  },
  actions: {
    async getGrowerInputLocations({ commit }) {
      const data = await client.grower.input_locations.read();
      if (data) {
        commit("SET_GROWER_INPUT_LOCATIONS", data.input_locations);
      }
    },
  },
  getters: {
    getGrowerInputLocations: (state) => {
      return state.locations;
    },
    getGrowerInputLocationBySellerId: (state) => (sellerId) => {
      return state.locations.filter((a) => a.affiliate === sellerId);
    },
  },
};
