import { createWebHistory, createRouter } from "vue-router";

import store from "./store/index.js";

import baseRoutes from "./routes/baseRoutes";
import growerPortalRoutes from "./routes/growerPortalRoutes";
import buyerPortalRoutes from "./routes/buyerPortalRoutes";
import vendorPortalRoutes from "./routes/vendorPortalRoutes";

const DEBUG = false;

const routes = baseRoutes.concat(
  growerPortalRoutes,
  buyerPortalRoutes,
  vendorPortalRoutes,
);

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from) => {
  if (DEBUG) console.log(`REDIRECT FROM: ${from.path} TO: ${to.path}`);
});

// ensure store has been loaded from browser storage
router.beforeEach(async () => {
  if (DEBUG) console.log("enter guard 1");
  await store.restored;
  await store.dispatch("getUserDataBlocking");
  if (DEBUG) console.log("exit guard 1");
});

// check for logged-in status
router.beforeEach((to) => {
  if (DEBUG) console.log("enter guard 2");
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.getUserData) {
      if (DEBUG) console.log("finish guard stack at 2; go to login");
      return `/login?redirect=${to.name}&params=${JSON.stringify(to.params)}`;
    }
  }
  if (DEBUG) console.log("exit guard 2");
});

// check for existing org
router.beforeEach((to, from) => {
  if (DEBUG) console.log("enter guard 3");
  if (store.getters.getUserData) {
    const { org, shared_org } = store.getters.getUserData;
    // Only redirect if the user has no org/shared_org and isn't already going to a create-org page
    if (
      !org &&
      !shared_org &&
      to.name !== "create-org" &&
      to.name !== "create-org-branded" &&
      to.name !== "nonav-create-org"
    ) {
      if (DEBUG)
        console.log(
          "finish guard stack at 3; go to create-org or create-org-branded",
        );
      // If coming from a branded signup page with a slug, route to create-org-branded and preserve the slug.
      if (from && from.params && from.params.slug) {
        return {
          name: "create-org-branded",
          params: { slug: from.params.slug },
        };
      }
      // Otherwise, route to the standard create-org page.
      return { name: "create-org" };
    } else if ((org || shared_org) && to.name === "create-org") {
      if (DEBUG) console.log("finish guard stack at 3");
      return "/";
    }
  }
  if (DEBUG) console.log("exit guard 3");
});

// determine target portal
router.beforeEach(async (to) => {
  if (DEBUG) console.log("enter guard 4");
  if (to.matched.some((record) => record.meta.forwardToAppIfLoggedIn)) {
    if (store.getters.getUserData) {
      let myOrg = await store.dispatch("getCurrentOrgBlocking");
      if (myOrg.purchases_flag) {
        if (DEBUG) console.log("finish guard stack at 4; go to buyer");
        return "/buyer";
      } else if (myOrg.revenue_flag) {
        if (DEBUG) console.log("finish guard stack at 4; go to vendor");
        return "/vendor";
      } else {
        if (DEBUG) console.log("finish guard stack at 4; go to app");
        return "/app";
      }
    }
  }
  if (DEBUG) console.log("exit guard 4");
});

// handle admin-only pages
router.beforeEach((to) => {
  if (DEBUG) console.log("enter guard 5");
  if (to.matched.some((record) => record.meta.admin)) {
    if (store.getters.getUserAdminStatus === false) {
      if (DEBUG) console.log("finish guard stack at 5");
      return "/";
    }
  }
  if (DEBUG) console.log("exit guard 5");
});

// handle required org flags
router.beforeEach(async (to) => {
  if (DEBUG) console.log("enter guard 6");
  if (to.meta.requiredOrgFlag) {
    const myOrg = await store.dispatch("getCurrentOrgBlocking");
    if (!myOrg[to.meta.requiredOrgFlag]) {
      if (DEBUG) console.log("finish guard stack at 6");
      return "/";
    }
  }
  if (DEBUG) console.log("exit guard 6");
});

// handle required feature flags
router.beforeEach(async (to) => {
  if (DEBUG) console.log("enter guard 7");
  if (to.meta.requiredFeatureFlag) {
    const myOrg = await store.dispatch("getCurrentOrgBlocking");
    if (!myOrg.feature_flags.includes(to.meta.requiredFeatureFlag)) {
      if (DEBUG) console.log("finish guard stack at 7");
      return "/";
    }
  }
  if (DEBUG) console.log("exit guard 7");
});

// handle store data fetching for menu changes to get latest data
router.beforeResolve((to) => {
  if (to.meta.storeGetActions) {
    for (let getAction of to.meta.storeGetActions) {
      store.dispatch(getAction);
    }
  }
});

// set page title
router.afterEach((to) => {
  document.title = to.meta.title || "Parcel.ag";
});

export default router;
