<template>
  <!-- <div class="sm:col-span-6">
    <utc-date-input
      :wideItem="true"
      inputTitle="Completion Date"
      :required="true"
      :displayOnly="displayOnly"
      :display-only-value="record.completed_at"
      v-model="record.completed_at"
    />
  </div> -->
  <component
    :is="recordDefinition.data.result.component"
    :inputTitle="recordDefinition.data.result.label"
    :full-width="true"
    :required="true"
    :displayOnly="displayOnly"
    :display-only-value="record.result"
    v-model="record.result"
    :selectOptions="selectOptions(recordDefinition.data.result.selectOptions)"
    v-if="
      recordDefinition?.data?.result &&
      recordDefinition?.data?.result?.component != 'ComputedString'
    "
  />
  <template v-for="payload in recordDefinition?.data?.payload" :key="payload">
    <template v-if="payload.component == 'ComboBox'">
      <combo-box
        :inputTitle="payload.label"
        :wideItem="payload.wideItem"
        :displayOnly="displayOnly"
        :selectOptions="getOptions(payload.value)"
        v-model="record.payload[payload.value]"
        @on-blur="record.payload[payload.value] = $event"
      />
    </template>
    <!-- Repeated section -->
    <template v-else-if="payload.component == 'RepeatedSection'">
      <div class="sm:col-span-6" v-if="!displayOnly">
        {{ payload.label }}
        <base-button
          @buttonClick="this.record = insertRow(this.record, payload)"
          buttonText="+ Add"
          class="ml-2"
        />
      </div>
      <template
        v-for="(element, elNdx) in record.payload[payload.value]"
        :key="element"
      >
        <template v-for="subpayload in payload.payload" :key="subpayload">
          <component
            :is="subpayload.component"
            :inputTitle="subpayload.label"
            :wideItem="subpayload.wideItem"
            :narrowItem="subpayload.narrowItem"
            :required="subpayload.required ? true : false"
            :displayOnly="displayOnly"
            :displayOnlyValue="
              record.payload[payload.value][elNdx][subpayload.value]
            "
            v-model="record.payload[payload.value][elNdx][subpayload.value]"
            :selectOptions="subpayload?.selectOptions"
            @onBlur="
              handleComponentChange(
                subpayload.value,
                record.payload[payload.value][elNdx][subpayload.value],
                elNdx,
              )
            "
          />
        </template>
      </template>
    </template>
    <!-- end Repeated section-->
    <component
      v-else
      :is="payload.component"
      :inputTitle="payload.label"
      :wideItem="payload.wideItem"
      :narrowItem="payload.narrowItem"
      :displayOnly="displayOnly"
      :displayOnlyValue="record.payload[payload.value]"
      v-model="record.payload[payload.value]"
      :selectOptions="selectOptions(payload?.selectOptions)"
      :placeholder="!displayOnly ? payload.placeholder : ''"
      :required="payload.required ? true : false"
      @blur="
        handleComponentChange(
          payload.value, // attribute
          record.payload[payload.value], // value
          -1, // index, -1 if not a repeated section
        )
      "
    />
  </template>
  <file-select
    class="h-full"
    :buttonText="recordDefinition.data.attachment.label"
    accept="*"
    @input="uploadAttachment"
    v-if="recordDefinition?.data?.attachment && !displayOnly"
  />
  <div v-if="displayOnly && hasAttachment">
    <a :href="record.attachment_url" :download="record.attachment">
      <BaseButton buttonText="Download Attachment" />
    </a>
  </div>
</template>
<script>
import PageCard from "@/components/cards/PageCard.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";
import DateInput from "@/components/form/DateInput.vue";
import ComboBox from "@/components/form/ComboBox.vue";
import TextArea from "@/components/form/TextArea.vue";
import ImageDisplay from "@/components/form/ImageDisplay.vue";
import TextInput from "@/components/form/TextInput.vue";
import TimeInput from "@/components/form/TimeInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import UtcDateInput from "@/components/form/UtcDateInput.vue";
import DateTimeInput from "@/components/form/DateTimeInput.vue";
import FileSelect from "@/components/form/FileSelect.vue";
import SelectSearch from "@/components/form/SelectSearch.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import { getNPKEquivalents } from "@/components/composables/convertUnits.js";
import { recordResult } from "@/components/composables/scriptUtils.js";
let cropList = require("@/assets/croplist.js");

const RecordTypes = require("@/layouts/grower-portal/records/RecordTypes.json");
export default {
  components: {
    PageCard,
    CheckboxInput,
    DateInput,
    ComboBox,
    TextArea,
    ImageDisplay,
    TextInput,
    TimeInput,
    NumberInput,
    UtcDateInput,
    DateTimeInput,
    SubmitButton,
    BaseButton,
    SelectSearch,
    FileSelect,
  },
  props: {
    recordType: {
      type: String,
      default: "",
    },
    recordProp: {
      type: Object,
      default: () => ({}),
    },
    displayOnly: {
      type: Boolean,
      default: false,
    },
    isPlanned: {
      type: Boolean,
      default: false,
    },
    requiredAttributes: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update:record"],
  data() {
    return {
      record: null,
      recordDefinition: null,
      wideItem: true,
      cropList: cropList,
    };
  },
  computed: {
    optionList() {
      // console.log("optionList", this.recordDefinition);
      let records = this.$store.state.records.records.filter(
        (record) => record.category === this.recordType,
      );

      let options = Array.from(
        this.recordDefinition.data.payload.map((attribute) => {
          // console.log("attribute", attribute);
          let priorOptions = Array.from(
            records.map((record) => record.payload[attribute.value]),
          );
          // console.log("priorOptions", priorOptions);
          let newOptions = new Set(priorOptions);
          newOptions = Array.from(newOptions);
          newOptions = newOptions.filter((e) => e != undefined);
          // console.log("Options", priorOptions, newOptions);
          // console.log("attribute", attribute, newOptions, {
          // [attribute.value]: newOptions,
          // });
          return {
            name: attribute.value,
            options: newOptions,
          };
        }),
      );

      // console.log("options", options);
      return options;
    },
    hasAttachment() {
      if (this.record.attachment) {
        return true;
      } else {
        return false;
      }
    },
    result() {
      return recordResult(this.recordDefinition, this.record);
    },
  },
  methods: {
    getOptions(attribute) {
      return this.optionList.find((option) => option.name === attribute)
        .options;
    },
    selectOptions(options) {
      // console.log("selectOptions", options);
      if (options == "{{croplist}}") {
        let crops = [];
        for (let crop of this.cropList) {
          crops.push(crop.name);
        }
        return crops;
      } else if (options) {
        return options;
      } else {
        return [];
      }
    },
    uploadAttachment(file) {
      this.record.attachment = file;
    },
    handleComponentChange(attribute, currentValue, index) {
      switch (this.recordType) {
        case "Fertilizer Application":
          if (attribute === "product") {
            let npk = getNPKEquivalents(currentValue);
            if (index >= 0) {
              this.record.payload["mix_products"][index]["nitrogen"] =
                npk.nitrogen;
              this.record.payload["mix_products"][index]["phosphorus"] =
                npk.phosphorus;
              this.record.payload["mix_products"][index]["potassium"] =
                npk.potassium;
            } else {
              this.record.payload["nitrogen"] = npk.nitrogen;
              this.record.payload["phosphorus"] = npk.phosphorus;
              this.record.payload["potassium"] = npk.potassium;
            }
          }
          break;
      }
    },
    insertRow(record, payload) {
      // console.log("insertRow", payload);
      if (!record.payload[payload.value]) {
        record.payload[payload.value] = [];
      }
      record.payload[payload.value].push({});
      return record;
    },
    resetRecord(newRecord) {
      this.record = { ...newRecord }; // Reset the internal record
    },
    initializeRepeatedSection(record, recordDefinition) {
      let repeatedSection = false;
      let payload = null;
      for (let attribute of recordDefinition.data.payload) {
        if (attribute.component == "RepeatedSection") {
          repeatedSection = true;
          payload = attribute;
        }
      }
      // console.log("repeatedSection", repeatedSection, payload);
      if (repeatedSection && !record.payload[payload.value]) {
        record = this.insertRow(record, payload);
      }
      return record;
    },
  },
  watch: {
    record: {
      handler(newVal) {
        this.$emit("update:record", newVal);
      },
      deep: true,
    },
    result: {
      handler(newVal) {
        this.record.result = newVal;
      },
    },
    recordType: {
      handler() {
        // console.log("watch recordType", newVal);
        let recordDefinition = RecordTypes.find(
          (recordType) => recordType.category === this.recordType,
        );
        if (this.requiredAttributes.length > 0) {
          let required_payload = recordDefinition.data.payload.filter(
            (attribute) => this.requiredAttributes.includes(attribute.value),
          );
          required_payload.map((attribute) => (attribute.required = true));
          if (this.requiredAttributes.includes("mix_products")) {
            let mix_products = recordDefinition.data.payload.find(
              (attribute) => attribute.value === "mix_products",
            );
            mix_products?.payload.map(
              (attribute) => (attribute.required = true),
            );
            console.log("mix_products", mix_products);
          }
        }
        if (this.isPlanned) {
          for (let attribute of recordDefinition.data.payload) {
            if (attribute.planned == false) {
              recordDefinition.data.payload =
                recordDefinition.data.payload.filter(
                  (attr) => attr != attribute,
                );
            }
          }
        }
        let record = this.recordProp;
        if (this.recordDefinition) {
          for (let attribute of this.recordDefinition.data.payload) {
            if (attribute.component == "DateInput") {
              // format date time into date
              record.payload[attribute.value] = record.payload[
                attribute.value
              ]?.substring(0, 10);
            }
          }
        }
        if (!record.payload) {
          record.payload = {};
        }
        record = this.initializeRepeatedSection(record, recordDefinition);
        this.recordDefinition = recordDefinition;
        this.record = record;
      },
      immediate: true,
    },
    // recordType: {
    //   handler(newVal) {
    //     this.recordDefinition = RecordTypes.find(
    //       (recordType) => recordType.category === newVal,
    //     );
    //     this.record = {
    //       category: newVal,
    //       result: "",
    //       payload: {},
    //     };
    //   },
    // },
  },
  mounted() {},
};
</script>
