<template>
  <Modal
    :show="showPreviewModal"
    title="Product Preview"
    dialogPanelClass="max-w-full"
  >
    <div class="flex w-full justify-end">
      <button @click="showPreviewModal = false">
        <XIcon class="h-6 w-6" aria-hidden="true" />
      </button>
    </div>
    <!-- <complete-product-listing
      :currentProduct="currentProduct"
      :vendorPerspective="true"
    /> -->
    <div class="inline-flex w-full justify-center pt-4">
      <base-button
        buttonText="close"
        :marginLeft="true"
        @buttonClick="showPreviewModal = false"
      />
    </div>
  </Modal>
  <delete-modal
    :open="deleteModalIsActive"
    modalTitle="Delete Product"
    :modalDescription="modalDescription"
    @clickCancel="cancelDelete"
    @clickConfirmDelete="deleteVendorProduct"
  />
  <page-card :headingTitle="'Product Listing'">
    <template #buttonArea>
      <base-button
        buttonText="Delete"
        :redButton="true"
        @buttonClick="initializeDeleteModal"
      />
      <submit-button
        buttonText="Save"
        :marginLeft="true"
        :formId="editProductFormId"
      />
      <!-- <base-button
        buttonText="Preview"
        :redButton="false"
        @buttonClick="showPreviewModal = true"
      /> -->
      <base-button
        v-if="currentProduct?.active === true"
        buttonText="Delist"
        :redButton="true"
        @buttonClick="delistVendorProduct"
      />
      <base-button
        v-else
        buttonText="Publish"
        :marginLeft="true"
        @buttonClick="publishVendorProduct"
      />
    </template>
    <template #cardDetails>
      <vendor-product-form
        :productId="parseInt($route.params.productId)"
        :productCategory="$route.params?.productCategory"
        :formId="editProductFormId"
        ref="vendorProductForm"
      />
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";
// import CompleteProductListing from "@/layouts/common/listing/CompleteProductListing.vue";
import VendorProductForm from "@/layouts/vendor-portal/products/VendorProductForm.vue";
import Modal from "@/components/modals/PopupModal.vue";
import DeleteModal from "@/components/modals/DeleteModal.vue";
import { useToast } from "vue-toastification";

import { XIcon } from "@heroicons/vue/solid";

export default {
  props: {
    productId: {
      type: [Number, String],
      required: true,
    },
    productCategory: {
      type: String,
      required: false,
    },
  },
  components: {
    PageCard,
    BaseButton,
    SubmitButton,
    // CompleteProductListing,
    VendorProductForm,
    Modal,
    DeleteModal,
    XIcon,
  },
  data() {
    // console.log("route: ", this.$route);
    const toast = useToast();
    return {
      deleteModalIsActive: false,
      editProductFormId: "editProduct",
      errorArray: new Array(),
      showPreviewModal: false,
      toast,
    };
  },
  computed: {
    modalDescription() {
      let description = "Are you sure you want to delete this product?";
      return description;
    },
    currentProduct() {
      let product = {};
      switch (this.productCategory) {
        case "Input":
          return this.$store.getters.getVendorInputListingById(
            this.$route.params.productId,
          );
        default:
          product = this.$store.getters.getVendorProductById(
            this.$route.params.productId,
          );
      }
      return product;
    },
  },
  methods: {
    // GOOD BENEATH HERE
    // Publish Controls
    async publishVendorProduct() {
      await this.$refs.vendorProductForm.updateVendorProduct(true);
      if (!this.$store.getters.getApiError) {
        this.toast.success("Product Published!", {
          timeout: 1500,
          hideProgressBar: true,
        });
      }
    },
    async delistVendorProduct() {
      await this.$refs.vendorProductForm.updateVendorProduct(false);
      if (!this.$store.getters.getApiError) {
        this.toast.info("Product De-Listed!", {
          timeout: 1500,
          hideProgressBar: true,
        });
      }
    },

    // Modal Methods
    initializeDeleteModal() {
      this.deleteModalIsActive = true;
    },
    cancelDelete() {
      this.deleteModalIsActive = false;
    },
    async deleteVendorProduct() {
      this.deleteModalIsActive = false;
      // console.log("DELETE");
      if (this.currentProduct.category == "Input") {
        await this.$store.dispatch(
          "deleteVendorInputListing",
          this.currentProduct.id,
        );
      } else {
        await this.$store.dispatch("deleteVendorProduct", {
          id: this.$route.params.productId,
        });
      }
      if (!this.$store.getters.getApiError) {
        this.$router.push({ name: "vendor-products-all" });
      }
    },
  },
};
</script>
