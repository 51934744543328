<template>
  <div class="flex min-h-screen bg-white">
    <div
      class="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:px-20 xl:px-24"
    >
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div>
          <img :class="logo_class" :src="logo" alt="Parcel Logo" />
          <!-- <img
            class="h-12 w-auto"
            src="../../assets/parcel-logo-name.svg"
            alt="Parcel Logo"
          /> -->
          <h2
            class="mt-6 text-3xl font-extrabold text-gray-900"
            v-if="!userSignup"
          >
            Login to your account
          </h2>
          <h2 class="mt-6 text-3xl font-extrabold text-gray-900" v-else>
            Sign up for an account
          </h2>
        </div>
        <div class="mt-8">
          <div class="mt-6">
            <form
              id="login-form"
              action="#"
              method="POST"
              class="space-y-6"
              v-on:submit.prevent
            >
              <phone-input
                v-if="!userSignup"
                v-model="userPhone"
                inputTitle="Mobile Phone"
                @valid="phoneValidate"
              />
              <select-search
                v-else
                :wideItem="true"
                inputTitle="Partner Organization"
                :help-text="`If you'd like to connect with an existing Parcel partner select said organization here`"
                v-model="selectedAffiliate"
                :expand-to-content="false"
                :selectOptions="affiliateSelectOptions"
                @on-blur="switchReferrer"
                :placeholder="'Independent'"
              />
              <div class="relative mt-6">
                <div class="absolute inset-0 flex items-center">
                  <div class="w-full border-t border-gray-300" />
                </div>
                <div class="relative flex justify-center text-sm">
                  <span v-if="!userSignup" class="bg-white px-2 text-gray-500">
                    Or
                  </span>
                </div>
              </div>
              <email-input
                v-model="userEmail"
                inputTitle="Email"
                @valid="emailValidate"
              />
              <div>
                <submit-button
                  :button-text="buttonText"
                  :disabled="submitDisabled"
                  :errorArray="errorArray"
                  formId="login-form"
                  @submitButtonClick="validateLoginForm"
                />
              </div>
            </form>
            <modal
              :show="showLoading"
              title="Getting things ready, loading up your files..."
            >
              <div class="pt-8">
                <div class="pl-32"><loading-spinner /></div>
              </div>
            </modal>
          </div>
        </div>
      </div>
      <router-link
        v-if="!userSignup"
        to="/signup"
        class="mt-6 self-center text-sm lowercase text-twilight-600"
        >Sign Up
      </router-link>
      <router-link
        v-else
        to="/login"
        class="mt-6 self-center text-sm lowercase text-twilight-600"
        >Log In
      </router-link>
      <a
        :href="homeURL"
        class="mt-6 self-center pt-4 text-sm lowercase text-twilight-600"
        >Return to homepage
      </a>
    </div>
  </div>
</template>

<script>
// import { SDKError, RPCError, ExtensionError } from "magic-sdk";
//import * as yup from "yup";
import PhoneInput from "@/components/form/PhoneInput.vue";
import EmailInput from "@/components/form/EmailInput.vue";
import SubmitButton from "@/components/buttons/SubmitButton";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import Modal from "@/components/modals/PopupModal.vue";
import { exceptionHandler } from "@/api/parcel/api";
import SelectSearch from "@/components/form/SelectSearch.vue";
import api from "@/api/parcel/api";

export default {
  components: {
    PhoneInput,
    EmailInput,
    SubmitButton,
    LoadingSpinner,
    Modal,
    SelectSearch,
  },
  props: {
    userSignup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let homeURL = "https://parcel.ag";
    if (process.env.VUE_APP_ENV === "development") {
      homeURL = "https://dev.parcel.ag";
    }
    if (process.env.VUE_APP_ENV === "sandbox") {
      homeURL = "https://sandbox.parcel.ag";
    }
    return {
      userEmail: "",
      userPhone: "",
      userPhoneIsValid: true,
      userEmailIsValid: true,
      submitIsCorrect: true,
      buttonText: !this.userSignup ? "Login" : "Sign up",
      errorArray: [],
      loginRedirectName: "grower-portal-home",
      loggingIn: false,
      homeURL: homeURL,
      affiliates: [],
      selectedAffiliate: null,
      // homeURL: `https://${
      //   process.env.VUE_APP_ENV === "development" ? "dev." : ""
      // }parcel.ag`,
    };
  },
  computed: {
    affiliateSelectOptions() {
      let options = [];
      this.affiliates.forEach((affiliate) => {
        options.push({
          value: affiliate.slug,
          label: affiliate.name,
        });
      });
      return options;
    },
    logo() {
      let logo = require("../../assets/parcel-logo-name.svg");
      if (this.$route.query.referrer) {
        let sponsor = this.$store.getters.getSponsorById(
          this.$route.query.referrer,
        );
        // console.log("referrer", this.$route.query.referrer, sponsor);
        if (sponsor) {
          logo = sponsor.login_logo_url;
        }
      }
      return logo;
    },
    logo_class() {
      let logo_class = "h-12 w-auto";
      if (this.$route.query.referrer) {
        let sponsor = this.$store.getters.getSponsorById(
          this.$route.query.referrer,
        );
        // console.log("referrer", this.$route.query.referrer, sponsor);
        if (sponsor) {
          logo_class = sponsor.login_logo_class;
        }
      }
      return logo_class;
    },
    cleanUserPhone() {
      return this.userPhone.split(" ").join("");
    },
    submitDisabled() {
      if (this.loggingIn) return true;
      return false;
    },
    showLoading() {
      if (this.loggingIn) return true;
      return false;
    },
    currentUser() {
      return this.$store.getters.getCurrentUser;
    },
  },
  methods: {
    async loginMagicEmail() {
      try {
        await this.$store.dispatch("loginViaEmail", { email: this.userEmail });
        this.finalizeLogin();
      } catch (err) {
        if (exceptionHandler) {
          exceptionHandler(err);
        }
        this.loggingIn = false;
        // console.log("error magic login email", err);
      }
    },
    async loginMagicPhone() {
      try {
        await this.$store.dispatch("loginViaPhone", this.cleanUserPhone);
        this.finalizeLogin();
      } catch (err) {
        if (exceptionHandler) {
          exceptionHandler(err);
        }
        this.loggingIn = false;
        // console.log("error magic login phone", err);
      }
    },
    async finalizeLogin() {
      const { redirect, params } = this.$route.query;
      if (redirect) {
        this.$router.push({
          name: redirect,
          params: params ? JSON.parse(params) : {},
        });
      } else {
        this.$router.push({ name: "landing-page" });
      }
    },

    validateLoginForm() {
      this.errorArray = [];
      if (this.userPhone != "" && this.userEmail != "") {
        // Ensure only one of the phone or email fields is filled
        this.submitIsCorrect = false;
        this.errorArray.push({
          id: "MultipleLoginMethodsEntered",
          errorDescription: "Leave either the phone or email field empty",
        });
        return;
      } else if (!this.userPhoneIsValid) {
        this.submitIsCorrect = false;
        this.errorArray.push({
          id: "InvalidPhoneNumber",
          errorDescription: "Please enter a valid phone number",
        });
        return;
      } else if (!this.userEmailIsValid) {
        this.submitIsCorrect = false;
        this.errorArray.push({
          id: "InvalidEmailAddress",
          errorDescription: "Please enter a valid email address",
        });
        return;
      }
      // Catch an empty state and tell the user to use either phone or email
      else if (this.userPhone == "" && this.userEmail == "") {
        this.submitIsCorrect = false;
        this.loginIsValid = false;
        this.errorArray.push({
          id: "NoLoginInfoEntered",
          errorDescription: "Login using either your phone or email",
        });
        return;
      }
      // Log in using the user's phone number
      else if (this.userPhone != "" && this.userPhoneIsValid) {
        this.loggingIn = true;
        this.loginMagicPhone();
      }
      // Log in using the user's email
      else if (this.userEmail != "" && this.userEmailIsValid) {
        this.loggingIn = true;
        this.loginMagicEmail();
      }
      // Catch unforseen permutation
      else {
        //this.submitIsCorrect = false;
        this.errorArray.push({
          id: "UncaughtError",
          errorDescription: "Something went wrong",
        });
      }
    },
    phoneValidate(valid) {
      this.userPhoneIsValid = valid;
    },
    emailValidate(valid) {
      this.userEmailIsValid = valid;
    },
    switchReferrer(e) {
      if (e == null) {
        // route to the default signup page
        this.$router.push({ name: "signup" });
      } else {
        // route to the signup page
        this.$router.push({
          name: "signup-branded",
          params: { slug: e },
        });
      }
    },
  },
  async mounted() {
    let response = await api.orgs.readPublicAffiliateDetails();
    this.affiliates = response.affiliates;
  },
};
</script>
