<template>
  <form @submit.prevent="submitForm" id="recordForm">
    <page-card :headingTitle="!print ? 'Record' : ''">
      <template #buttonArea>
        <submit-button
          buttonText="Save"
          :marginLeft="false"
          formId="recordForm"
          v-if="!displayOnly"
        />
        <base-button
          buttonText="Cancel"
          :marginLeft="true"
          v-if="!displayOnly"
          @buttonClick="$emit('cancelRecord')"
        />
        <base-button
          v-else-if="!print"
          buttonText="Close"
          @buttonClick="$emit('closeRecord')"
        />
      </template>
      <template #cardDetails>
        <div class="flex sm:col-span-6" v-if="selectField">
          <select-search
            :wideItem="true"
            inputTitle="Field"
            inputHtmlType="text"
            v-model="selectedField"
            :selectOptions="fieldOptions"
            class="ml-1 resize-none whitespace-pre-wrap sm:col-span-3"
            classProp="resize-none"
            :required="true"
          />
          <select-search
            :wideItem="true"
            inputTitle="Crop"
            inputHtmlType="text"
            v-model="record.crop"
            :selectOptions="fieldCropOptions"
            class="ml-1 resize-none whitespace-pre-wrap sm:col-span-3"
            classProp="resize-none"
            :no-sort="true"
            :required="true"
          />
        </div>
        <record-form-layout
          @update:record="record = $event"
          :recordProp="recordProp"
          :displayOnly="displayOnly"
          :recordType="recordType"
          :required-attributes="requiredAttributes"
        />
      </template>
    </page-card>
  </form>
</template>
<script>
import PageCard from "@/components/cards/PageCard.vue";
import RecordFormLayout from "@/layouts/grower-portal/records/RecordFormLayout.vue";
// import CheckboxInput from "@/components/form/CheckboxInput.vue";
// import ComboBox from "@/components/form/ComboBox.vue";
// import TextArea from "@/components/form/TextArea.vue";
// import ImageDisplay from "@/components/form/ImageDisplay.vue";
// import TextInput from "@/components/form/TextInput.vue";
// import TimeInput from "@/components/form/TimeInput.vue";
// import NumberInput from "@/components/form/NumberInput.vue";
// import DateInput from "@/components/form/DateInput.vue";
// import FileSelect from "@/components/form/FileSelect.vue";
import SelectSearch from "@/components/form/SelectSearch.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import { postgresDate } from "@/components/composables/dateUtils.js";
const RecordTypes = require("@/layouts/grower-portal/records/RecordTypes.json");
export default {
  components: {
    PageCard,
    RecordFormLayout,
    SubmitButton,
    BaseButton,
    // CheckboxInput,
    // ComboBox,
    // TextArea,
    // ImageDisplay,
    // TextInput,
    // TimeInput,
    // NumberInput,
    // DateInput,

    SelectSearch,
    // FileSelect,
  },
  props: {
    recordType: {
      type: String,
      default: "",
    },
    recordProp: {
      type: Object,
      default: () => ({}),
    },
    displayOnly: {
      type: Boolean,
      default: false,
    },
    print: {
      type: Boolean,
      default: false,
    },
    selectField: {
      type: Boolean,
      default: false,
    },
    fieldOptions: {
      type: Array,
      default: () => [],
    },
    cropOptions: {
      type: Array,
      default: () => [],
    },
    requiredAttributes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    let recordDefinition = RecordTypes.find(
      (recordType) => recordType.category === this.recordType,
    );
    let record = this.recordProp;
    if (!record.payload) {
      record.payload = {};
    }
    return {
      recordDefinition: recordDefinition,
      record: record,
      wideItem: true,
      selectedField: null,
    };
  },
  computed: {
    optionList() {
      // console.log("optionList", this.recordDefinition);
      let records = this.$store.state.records.records.filter(
        (record) => record.category === this.recordType,
      );

      let options = Array.from(
        this.recordDefinition.data.payload.map((attribute) => {
          // console.log("attribute", attribute);
          let priorOptions = Array.from(
            records.map((record) => record.payload[attribute.value]),
          );
          // console.log("priorOptions", priorOptions);
          let newOptions = new Set(priorOptions);
          newOptions = Array.from(newOptions);
          newOptions = newOptions.filter((e) => e != undefined);
          // console.log("Options", priorOptions, newOptions);
          // console.log("attribute", attribute, newOptions, {
          // [attribute.value]: newOptions,
          // });
          return {
            name: attribute.value,
            options: newOptions,
          };
        }),
      );

      // console.log("options", options);
      return options;
    },
    fieldCropOptions() {
      if (!this.selectedField) {
        return [];
      }
      let cropOptions = this.cropOptions.filter(
        (crop) => crop.field === this.selectedField,
      );
      return cropOptions;
    },
    hasAttachment() {
      if (this.record.attachment) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    submitForm() {
      // console.log("submitted form", this.recordDefinition);
      // set completed_at if it is in payload
      if (this.record?.payload?.completed_at) {
        this.record.completed_at = postgresDate(
          this.record.payload.completed_at,
        );
      }
      // set completed_by if it is not in payload
      if (!this.record?.payload?.completed_by) {
        this.record.completed_by = this.$store.getters.getCurrentUser.id;
      }
      // console.log("saved record", this.record);
      this.$emit("saveRecord", this.record);
    },
    getOptions(attribute) {
      // console.log("getOptions", attribute, this.optionList[attribute]);
      return this.optionList.find((option) => option.name === attribute)
        .options;
    },
    uploadAttachment(file) {
      this.record.attachment = file;
    },
    // async downloadAttachment() {
    //   let result = null;
    //   if (this.recordDefinition.data.attachment && this.displayOnly) {
    //     result = await this.$store.dispatch("getRecordAttachment", this.record);
    //   }

    //   console.log("attachment loaded", result);
    //   const blob = result;
    //   const a = document.createElement("a");
    //   a.href = URL.createObjectURL(blob, { type: result.type });
    //   a.download = this.record.attachment;
    //   document.body.appendChild(a);
    //   a.click();
    //   document.body.removeChild(a);
    // },
  },
  mounted() {},
};
</script>
