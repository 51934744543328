<template>
  <div class="flex min-h-screen bg-white">
    <div
      class="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:px-20 xl:px-24"
    >
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div>
          <img
            class="h-12 w-auto"
            src="../../assets/parcel-logo-name.svg"
            alt="Parcel Logo"
          />
          <h2 class="mt-6 text-3xl font-extrabold text-gray-900">
            Sign up for an account
          </h2>
        </div>
        <div class="mt-8">
          <div class="mt-6">
            <form
              id="login-form"
              action="#"
              method="POST"
              class="space-y-6"
              v-on:submit.prevent
            >
              <email-input
                v-model="userEmail"
                inputTitle="Email"
                @valid="emailValidate"
              />
              <div>
                <submit-button
                  :button-text="buttonText"
                  :disabled="submitDisabled"
                  :errorArray="errorArray"
                  formId="login-form"
                  @submitButtonClick="validateLoginForm"
                />
              </div>
            </form>
            <modal
              :show="showLoading"
              title="Getting things ready, loading up your files..."
            >
              <div class="pt-8">
                <div class="pl-32"><loading-spinner /></div>
              </div>
            </modal>
          </div>
        </div>
      </div>
      <a
        :href="homeURL"
        class="mt-6 self-center pt-4 text-sm lowercase text-twilight-600"
        >Return to homepage
      </a>
    </div>
  </div>
</template>

<script>
// import { SDKError, RPCError, ExtensionError } from "magic-sdk";
//import * as yup from "yup";
import EmailInput from "@/components/form/EmailInput.vue";
import SubmitButton from "@/components/buttons/SubmitButton";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import Modal from "@/components/modals/PopupModal.vue";
import { exceptionHandler } from "@/api/parcel/api";

export default {
  components: {
    EmailInput,
    SubmitButton,
    LoadingSpinner,
    Modal,
  },
  props: {
    userSignup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let homeURL = "https://parcel.ag";
    if (process.env.VUE_APP_ENV === "development") {
      homeURL = "https://dev.parcel.ag";
    }
    if (process.env.VUE_APP_ENV === "sandbox") {
      homeURL = "https://sandbox.parcel.ag";
    }
    return {
      userEmail: "",
      userPhone: "",
      userPhoneIsValid: true,
      userEmailIsValid: true,
      submitIsCorrect: true,
      buttonText: "Sign up",
      errorArray: [],
      loginRedirectName: "grower-portal-home",
      loggingIn: false,
      homeURL: homeURL,
      // homeURL: `https://${
      //   process.env.VUE_APP_ENV === "development" ? "dev." : ""
      // }parcel.ag`,
    };
  },
  computed: {
    cleanUserPhone() {
      return this.userPhone.split(" ").join("");
    },
    submitDisabled() {
      if (this.loggingIn) return true;
      return false;
    },
    showLoading() {
      if (this.loggingIn) return true;
      return false;
    },
    currentUser() {
      return this.$store.getters.getCurrentUser;
    },
  },
  methods: {
    async loginMagicEmail() {
      try {
        await this.$store.dispatch("loginViaEmail", { email: this.userEmail });
        this.finalizeLogin();
      } catch (err) {
        if (exceptionHandler) {
          exceptionHandler(err);
        }
        this.loggingIn = false;
        // console.log("error magic login email", err);
      }
    },
    async loginMagicPhone() {
      try {
        await this.$store.dispatch("loginViaPhone", this.cleanUserPhone);
        this.finalizeLogin();
      } catch (err) {
        if (exceptionHandler) {
          exceptionHandler(err);
        }
        this.loggingIn = false;
        // console.log("error magic login phone", err);
      }
    },
    async finalizeLogin() {
      // const { redirect, params } = this.$route.query;
      // if (redirect) {
      //   this.$router.push({
      //     name: redirect,
      //     params: params ? JSON.parse(params) : {},
      //   });
      // } else {
      //   this.$router.push({ name: "landing-page" });
      // }
      this.$router.push({ name: "nonav-create-org" });
    },

    validateLoginForm() {
      this.errorArray = [];
      if (this.userPhone != "" && this.userEmail != "") {
        // Ensure only one of the phone or email fields is filled
        this.submitIsCorrect = false;
        this.errorArray.push({
          id: "MultipleLoginMethodsEntered",
          errorDescription: "Leave either the phone or email field empty",
        });
        return;
      } else if (!this.userPhoneIsValid) {
        this.submitIsCorrect = false;
        this.errorArray.push({
          id: "InvalidPhoneNumber",
          errorDescription: "Please enter a valid phone number",
        });
        return;
      } else if (!this.userEmailIsValid) {
        this.submitIsCorrect = false;
        this.errorArray.push({
          id: "InvalidEmailAddress",
          errorDescription: "Please enter a valid email address",
        });
        return;
      }
      // Catch an empty state and tell the user to use either phone or email
      else if (this.userPhone == "" && this.userEmail == "") {
        this.submitIsCorrect = false;
        this.loginIsValid = false;
        this.errorArray.push({
          id: "NoLoginInfoEntered",
          errorDescription: "Login using either your phone or email",
        });
        return;
      }
      // Log in using the user's phone number
      else if (this.userPhone != "" && this.userPhoneIsValid) {
        this.loggingIn = true;
        this.loginMagicPhone();
      }
      // Log in using the user's email
      else if (this.userEmail != "" && this.userEmailIsValid) {
        this.loggingIn = true;
        this.loginMagicEmail();
      }
      // Catch unforseen permutation
      else {
        //this.submitIsCorrect = false;
        this.errorArray.push({
          id: "UncaughtError",
          errorDescription: "Something went wrong",
        });
      }
    },
    phoneValidate(valid) {
      this.userPhoneIsValid = valid;
    },
    emailValidate(valid) {
      this.userEmailIsValid = valid;
    },
  },
};
</script>
