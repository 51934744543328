<template>
  <div>
    <modal
      :show="showRecordModal"
      dialogPanelClass="max-w-screen-lg"
      title="Add Record"
      @close-modal="showRecordModal = false"
    >
      <!-- <span class="text-xl font-bold uppercase">Field</span> -->
      <record-form
        @saveRecord="saveRecordAction"
        @closeRecord="showRecordModal = false"
        @cancelRecord="popRecord"
        :recordProp="activeRecord"
        :displayOnly="displayOnly"
        :recordType="activeRecord.category"
        :cropOptions="cropList"
        :fieldOptions="fieldList"
        :selectField="selectField"
        :required-attributes="validationRecordAttributes(activeRecord.category)"
      />
    </modal>
    <table-card>
      <template #header>
        <table-head
          :columns="[
            { name: 'farm', label: 'Farm', filtered: true, sorted: true },
            { name: 'field', label: 'Field', filtered: true, sorted: true },
            { name: 'crop_type', label: 'Crop', filtered: true, sorted: true },
            {
              name: 'crop_year',
              label: 'Crop Year',
              filtered: true,
              sorted: true,
            },
            { name: 'category', label: 'Type' },
            // { name: 'result', label: 'Result', filtered: false },
            { name: 'actions', label: '', filtered: false, sorted: false },
            { name: 'actions', label: '', filtered: false, sorted: false },
          ]"
          :collection="derivedRecords"
          @update:derived-collection="tableDerivedRecords = $event"
          :defaultSort="defaultSort"
          :toolbar="1"
        >
          <template #toolbarButtons>
            <div class="flex">
              <premium-records-export-import :order-id-override="orderId" />
              <div>
                <dropdown-button
                  buttonText="Add"
                  :actions="recordTypes"
                  class="flex w-full justify-end"
                  @action="addNewRecord"
                />
              </div>
            </div>
          </template>
        </table-head>
      </template>

      <tbody>
        <tr v-for="record in tableDerivedRecords" :key="record.id">
          <table-data td-label="farm">
            {{ record?.farm }}
          </table-data>
          <table-data td-label="field">
            {{ record?.field }}
          </table-data>
          <table-data td-label="Crop">
            {{ record?.crop_type }}
          </table-data>
          <table-data td-label="Crop Year">
            {{ record?.crop_year }}
          </table-data>
          <table-data td-label="Type">
            {{ record?.category }}
          </table-data>
          <!-- <table-data td-label="Result">
            <text-area
              v-model="record.result"
              :displayOnly="record.id != null"
              :display-only-value="record.result"
              dataTestid="record-result"
            />
          </table-data> -->
          <table-data class="text-center">
            <button
              @click="showRecord(record.id)"
              :disabled="false"
              v-if="record.category != 'Note'"
            >
              View
            </button>
          </table-data>
          <table-data class="text-center">
            <button @click="deleteRecord(record.id)" :disabled="false">
              Delete
            </button>
          </table-data>
        </tr>
      </tbody>
    </table-card>
  </div>
</template>

<script>
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";
// import SelectSearch from "@/components/form/SelectSearch.vue";
import PremiumRecordsExportImport from "@/layouts/grower-portal/sell/orders/PremiumRecordsExportImport.vue";
// import TextArea from "@/components/form/TextArea.vue";
import DropdownButton from "@/components/buttons/DropdownButton.vue";
import RecordForm from "@/layouts/grower-portal/records/RecordForm.vue";
import Modal from "@/components/modals/PopupModal.vue";

// import { postgresDate } from "@/components/composables/dateUtils.js";

const RecordTypeDefintions = require("@/layouts/grower-portal/records/RecordTypes.json");
export default {
  components: {
    TableCard,
    TableHead,
    TableData,
    // TextArea,
    // SelectSearch,
    DropdownButton,
    RecordForm,
    Modal,
    PremiumRecordsExportImport,
  },
  props: {
    currentOffer: { type: Object, required: true },
    currentOrder: { type: Object, required: true },
  },
  data() {
    return {
      nextRecordId: 0,
      activeRecord: null,
      showRecordModal: false,
      displayOnly: false,
      tableDerivedRecords: [],
      defaultSort: { crop_year: "desc", farm: "asc", field: "asc" },
      currentCropId: null,
      currentFieldId: null,
      currentEquipmentId: null,
      currentTaskId: null,
      selectField: false,
      saveRecordAction: null,
    };
  },
  computed: {
    dataIsLoaded() {
      if (
        this.orderId &&
        this.$store.getters.getGrowerCropOrderById(this.orderId) &&
        this.$store.getters.getGrowerAllocationsByOrderId(this.orderId) &&
        this.$store.getters.getGrowerDeliveriesByOrderId(this.orderId)
      ) {
        return true;
      } else {
        return false;
      }
    },
    orderId() {
      return this.currentOrder.id;
    },
    fieldList() {
      let fields = this.crops.map((crop) => {
        return {
          label: crop?.field_data?.properties?.name,
          value: crop?.field_data?.id,
        };
      });
      fields = fields.filter(
        (field, index, self) =>
          index === self.findIndex((t) => t.value === field.value),
      );
      return fields;
    },
    cropList() {
      let crops = this.crops;
      let cropYearOptions = crops.map((crop) => {
        let label = crop.crop_type;
        if (crop.harvest_range_end)
          label += " (" + crop.harvest_range_end?.substring(0, 4) + ")";
        return {
          label: label,
          value: crop.id,
          year: crop.harvest_range_end?.substring(0, 4),
          field: crop?.field_data?.id,
        };
      });
      cropYearOptions = cropYearOptions.sort((a, b) =>
        a.year > b.year ? 1 : -1,
      );
      return cropYearOptions;
    },
    validationRecordTypes() {
      let premium = this.currentOffer?.premiums.find(
        (premium) => premium.id == this.currentOrder.premium,
      );
      // console.log("premium", premium, this.currentOrder, this.currentOffer);
      if (premium?.validation_records) {
        return premium.validation_records.map((record) => {
          return record.category;
        });
      } else {
        return [];
      }
    },

    recordTypes() {
      let recordTypes = RecordTypeDefintions.filter(
        (recordType) => !recordType.readonly,
      );
      if (this.validationRecordTypes.length > 0) {
        recordTypes = recordTypes.filter((recordType) =>
          this.validationRecordTypes.includes(recordType.category),
        );
      }
      // console.log("recordTypes", recordTypes);
      recordTypes = recordTypes.map((recordType) => {
        return {
          label: recordType.category,
          event: recordType.category,
        };
      });
      return recordTypes;
    },
    allocations() {
      if (!this.dataIsLoaded) {
        return null;
      }
      // use this.orderId to fetch the allocations
      // for each allocation, attach the crop_data and field_data
      let allocations = this.$store.getters.getGrowerAllocationsByOrderId(
        this.orderId,
      );
      if (!this.currentOffer?.premiums) {
        return allocations;
      }
      return allocations.map((allo) => {
        let alloFieldId = this.$store.getters.getAnyCropById(allo.crop)?.field;
        return {
          ...allo,
          field_data: this.$store.getters.getFieldById(alloFieldId),
        };
      });
    },
    derivedRecords() {
      let derivedRecords = [];
      for (let crop of this.crops) {
        let cropRecords = this.$store.getters.getRecordsByCrop(crop.id);
        let field = this.$store.getters.getFieldById(crop.field);
        for (let record of cropRecords) {
          if (!this.validationRecordTypes.includes(record.category)) {
            continue;
          }
          record.farm = field?.properties?.farm;
          record.field = field?.properties?.name;
          record.crop_type = crop?.crop_type;
          record.crop_year = crop?.harvest_range_end?.substring(0, 4);

          derivedRecords.push(record);
        }
      }
      return derivedRecords;
    },
    crops() {
      if (!this.dataIsLoaded || !this.allocations || !this.currentOffer) {
        return null;
      }
      let fieldIds = [];
      // loop through allocations, use the getFieldIdByCropId getter to get the field id, and push it to fieldIds
      for (let allo of this.allocations) {
        fieldIds.push(this.$store.getters.getFieldIdByCropId(allo.crop));
      }
      // loop through fieldIds, use the getCropsByFieldId getter to get the crops
      // the arguments for the getter are the field id, the current year - this.currentOffer.additional_prior_year_records, and the current year
      let crops = [];
      for (let fieldId of fieldIds) {
        // get the crops for the fieldId, give each crop field_data via the getFieldByCropId getter, and push them to the crops array as a single array
        for (let crop of this.$store.getters.getCropsByFieldId(fieldId)) {
          crops.push({
            ...crop,
            harvest_year: new Date(crop.harvest_range_end).getFullYear(),
            field_data: this.$store.getters.getFieldById(fieldId),
            // records: this.$store.getters.getRecordsByCrop(crop.id),
            relatedAllocationId: this.allocations.find(
              (allo) => allo?.field_data?.id == crop?.field,
            )?.id,
          });
        }
      }
      return crops;
    },
  },
  methods: {
    updateAcres(value) {
      // console.log("updating acres", value);
      let allocation = this.$store.getters.getWorkingAllocationByCropId(
        value.id,
      );
      allocation.acres = value.acres;
      this.$store.dispatch("updateWorkingAllocation", allocation);
    },
    showRecord(id) {
      this.activeRecord = this.derivedRecords.find(
        (record) => record.id === id,
      );
      // console.log("show record", id, this.activeRecord);
      this.selectField = false;
      this.displayOnly = false;
      this.saveRecordAction = this.updateRecord;
      this.showRecordModal = true;
    },
    popRecord() {
      // this.newRecords.pop();
      this.showRecordModal = false;
    },
    addNewRecord(category) {
      // console.log("add new record", e);
      this.activeRecord = {
        result: " ",
        completed_at: new Date(),
        completed_by: this.$store.getters.getCurrentUser.id,
        category: category,
        user: { name: this.$store.getters.getCurrentUser.name },
      };
      this.displayOnly = false;
      this.selectField = true;
      this.saveRecordAction = this.createRecord;
      this.showRecordModal = true;
    },
    deleteRecord(id) {
      // Find the record by its actual id and dispatch delete action
      const recordToDelete = this.derivedRecords.find(
        (record) => record.id === id,
      );
      if (recordToDelete) {
        this.$store.dispatch("deleteRecord", recordToDelete);
      }
    },
    async createRecord(record) {
      // console.log("saving data from modal", record);
      // Optionally update the record in local derivedRecords here if needed
      this.showRecordModal = false;
      record = await this.$store.dispatch("createRecord", record);
      if (record?.attachment != null) {
        await this.$store.dispatch("uploadRecordAttachment", {
          record: record,
          attachment: record.attachment,
        });
      }
    },
    updateRecord(record) {
      if (record.crop) {
        record.equipment = null;
        record.task = null;
        record.field = null;
      }
      this.showRecordModal = false;
      this.$store.dispatch("updateRecord", { recordId: record.id, record });
    },
    validationRecordAttributes(category) {
      let premium = this.currentOffer?.premiums.find(
        (premium) => premium.id == this.currentOrder.premium,
      );
      let validation_records = premium?.validation_records.find(
        (record) => record.category == category,
      );
      // console.log("validationRecordAttributes", premium, validation_records);
      if (validation_records?.attributes?.all === false) {
        // console.log("attributes", validation_records.attributes.payload);
        if (!validation_records.attributes.payload) {
          return [];
        }
        return validation_records.attributes.payload;
      } else {
        return [];
      }
    },
  },
};
</script>

<style></style>
